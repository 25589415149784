


export const FLEXICO_TEMPLATE_KEYS = {
    label: 'label',
    app_id: 'app_id',
    approval_days: 'approval_days',
    approval_required: 'approval_required',
    created_by: 'created_by',
    created_time_stamp: 'created_time_stamp',
    description: 'description',
    status: 'status',
    file_types: 'file_types',
    flex_id: 'flex_id',
    group_id: 'group_id',
    last_updated_by: 'last_updated_by',
    last_updated_time_stamp: 'last_updated_time_stamp',
    next_step: 'next_step',
    step_function_name: 'step_function_name',
    template_name: 'template_name',
    sox_approved: 'sox_approved',
    sox_evidence: 'sox_evidence',
    sox_compliance_checks_passed: 'sox_compliance_checks_passed',
    approvers: "approvers",
    uploaders: "uploaders",
    parameters: "parameters",
    critical_data: 'critical_data',
    trigger_type: 'trigger_type',
    upload_mandatory: 'upload_mandatory',
    APPROVER_KEYS: {
        alias: 'alias',
        column_index: 'column_index',
        type: 'type'
    },
    UPLOADER_KEYS: {
        alias: 'alias',
        type: 'type'
    },
    PARAMETER_KEYS: {
        field_id: 'field_id',
        field_type: 'field_type',
        field_values: 'field_values',
        label: 'label',
        source_database: 'source_database'
    },


}

export const FLEXICO_DROPDOWN_METADATA_KEYS = {
    FILE_TYPES: 'Flexico process file types',
    TRIGGER_TYPES: 'Flexico trigger types'
}

export const APPROVER_TYPES = {
    APPROVER: "APPROVER",
    APPROVER_LDAP: "APPROVER_LDAP"
}

export const UPLOADER_TYPES = {
    UPLOADER: "UPLOADER",
    UPLOADER_LDAP: "UPLOADER_LDAP"
}

export const FLEXICO_TRANSACTION_STATUS = {
    FAILED: "File Upload Failed",
    TRIGGER_REQUEST: "Job trigger requested",
    APPROVAL_PENDING: "File Approval Pending",
    APPROVED: "File Approval Complete",
    JOB_INITIATED: "File Upload job Initiated",
    UPLOADED: "File Uploaded",
    APPROVAL_CANCELLED: "File Approval Cancelled",
    APPROVAL_DENIED: "File Approval Denied",
}

export const FRISCO_TRACK_CURRENT_STATUS = {
    RELOAD_INPUT_FILE_APPROVAL_DENIED: "File Approval Denied Reload Input",
    RELOAD_INPUT_ADMIN_APPROVAL_DENIED: "Admin Approval Denied Reload Input",
    POST_WB: "Reload input file post WB",
    UPLOAD_INPUT_FILE: "Load input data",
    ALLOW_POST_WB_INPUT_FILE: "Allow post WB input file"
}

export const FRISCO_TRACK_OVERALL_STATUS = {
    INCOMPLETE: "INCOMPLETE"
}

export const FLEXICO_TEMPLATE_STATUS = {
    CREATING_TEMPLATE: 'Creating template',
    TEMPLATE_CREATED: 'Template created',
    TEMPLATE_CREATION_FAILED: 'Template creation failed',
    UPDATE_IN_PROGESS: 'Template update in progress',
    TEMPLATE_UPDATED: 'Template updated'
}

export const FLEXICO_POSITIVE_STATUS = [FLEXICO_TEMPLATE_STATUS.TEMPLATE_CREATED, FLEXICO_TEMPLATE_STATUS.TEMPLATE_UPDATED]
export const FLEXICO_NEGATIVE_STATUS = [FLEXICO_TEMPLATE_STATUS.TEMPLATE_CREATION_FAILED]

export const FLEXICO_NEGATIVE_STATUS_FOR_UPLOAD = []
