import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    AppLayout,
    Box,
    SpaceBetween,
    Header,
    Button,
    Table,
    Modal,
    ColumnLayout
} from "@amzn/awsui-components-react";


import SideNav from "./SideNav";


import { CodeView } from '@amzn/awsui-code-view';
import jsonHighlight from "@amzn/awsui-code-view/highlight/json";



import moment from 'moment';


import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";




const AuditLoggingMain = (props) => {

    const [sideHeader, setSideHeader] = useState();
    const [headerHref, setHeaderHref] = useState();
    const [sideNavBack, setSideNavBack] = useState();
    const [visible, setVisible] = useState(false)
    const [before, setBefore] = useState("")
    const [after, setAfter] = useState("")

    useEffect(() => {
        setHeaderHref(props.headerHref);
        setSideNavBack(props.sideNavBack);
        setSideHeader(props.sideHeader);

    }, [props.headerHref, props.sideNavBack, props.sideHeader])

    const viewChangesClick = (e) => {
        setBefore(e.before ? JSON.stringify(JSON.parse(e.before), 0, 2) : "Not Available")
        setAfter(e.after ? JSON.stringify(JSON.parse(e.after), 0, 2) : "Not Available")
        setVisible(true)
    }
    const content = (
        <>
            <Table
                columnDefinitions={[
                    {
                        id: "timeStamp",
                        header: "Time Stamp",
                        cell: e => moment(new Date(Number(e.last_updated_time_stamp))).format(),
                        sortingField: "last_updated_time_stamp"
                    },
                    {
                        id: "action",
                        header: "Action",
                        cell: e => e.action,
                        isRowHeader: true
                    },
                    {
                        id: "actor",
                        header: "Actor",
                        cell: e => e.actor
                    },
                    { id: "comments", header: "Comments", cell: e => e.comments },
                    { id: "before_after", header: "Before / After", cell: e => (e.before || e.after) ? <Box textAlign="left"><Button onClick={() => viewChangesClick(e)}>View Changes</Button></Box> : <Box textAlign="left">Not Applicable</Box> }
                    
                ]}
                enableKeyboardNavigation
                sortingDescending
                resizableColumns
                stripedRows
                items={props.logsData}
                loading={!props.logsData}
                loadingText="Loading logs"
                trackBy="name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No logs available</b>
                        </SpaceBetween>
                    </Box>
                }
                header={<Header>{props.sideHeader}</Header>}
            />
            <Modal
                onDismiss={() => {
                    setVisible(false)
                    setAfter("")
                    setBefore("")
                }}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" onClick={() => {
                                setVisible(false)
                                setAfter("")
                                setBefore("")
                            }}>
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Before / After"
            >
                    <ColumnLayout columns={2}>
                    <Box>
                        <h4>Before: </h4>
                        <CodeView content={before}
                            highlight={before && (jsonHighlight)}

                        />
                    </Box>
                    <Box>
                        <h4>After:</h4>
                        <CodeView content={after}
                            highlight={after && (jsonHighlight)}
                        />
                    </Box>
                    </ColumnLayout>

            </Modal>
        </>
    );

    return (
        <AppLayout
            content={content}
            headerSelector="#TopBar"
            navigation={<SideNav sideHeader={sideHeader} back={sideNavBack} headerHref={headerHref} />}
            navigationHide={false}
            maxContentWidth={Number.MAX_VALUE}
            headerVariant="high-contrast"
        ></AppLayout>
    );
};

export default AuditLoggingMain;
