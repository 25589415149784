import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Box, Button, ButtonDropdown, Container, FileUpload, Grid, Modal, SpaceBetween, Spinner, StatusIndicator, Tabs } from "@amzn/awsui-components-react";
import { v4 as uuidv4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Storage } from 'aws-amplify';
import * as d3 from "d3";
import { configureFlexicoS3, configureGenericS3 } from 'src/context/AuthContextUtility';
import { CellDataType } from 'src/utilities/ag-grid-utils';
const AnnotateS3 = (props) => {
const gridRef = useRef();
const [dsInfo, setDsInfo] = useState()
  const [columnsList, setColumnsList] = useState([])
  const [gridInfo, setGridInfo] = useState({ rowCount: 0, totalRows: 0 });
  const [aggregationValues, setAggregationValues] = useState(null)

  const [annotationLoader, setAnnotationLoader] = useState(null)
    const [annotationUploadLoader, setAnnotationUploadLoader] = useState(null)
    const [annotationError, setAnnotationError] = useState("error..")
    const [undoEditCount, setUndoEditCount] = useState(0) 
    const [selectedRowIndex, setSelectedRowIndex] = useState(0)
    const [showOldValues, setShowOldValues] = useState(0) 
    const [multiSelectedRow, setMultiSelectedRow] = useState([])
    const [rowGroupColumns, setRowGroupColumns] = useState([]) 
    const [showAnnotationImportModal, setShowAnnotationImportModal] = useState(false) 
    const [showAnnotationImportAppendModal, setShowAnnotationImportAppendModal] = useState(false)
    const [annotationSaveSuccessMessage, setAnnotationSaveSuccessMessage] = useState(null)  
  const [annotationInputFile, setAnnotationInputFile] = useState([])
  const [modalMessage, setModalMessage] = useState('')

  const annotationFileName = props.annotationFileName
  const annotationLevel = "public"
  const annotationPrefix = `${props.annotationPrefix}/`
  const unEditableColumns = props.unEditableColumns || []
  const editableColumns = props.editableColumns || []
  const currencyColumns = props.currencyColumns || []
  const [annotationColumns, setAnnotationColumns] = useState( props.annotationColumns || []) 
  const [validationColumns, setValidationColumns] = useState( props.validationColumns || []) 
     

  const defaultProjectColDef = {
    resizable: true,
    sortable: true,
    autoHeaderHeight: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerClass: 'header-center',
    editable: true,
    // singleClickEdit: true,
    enableCellChangeFlash: true,
    enableRowGroup: false,
    enableCellChangeFlash: true,
    suppressHeaderMenuButton: true,
    enableHeaderHighlight: true,
    cellSelection: 'single', // or 'multiple'
    suppressCellSelection: false,
    suppressMovable: true,
    cellClassRules: {
      'edited-row': (params) => { return params?.data?.isEdited || params?.data?.isNew },
      'new-row': (params) => { return params?.data?.isNew },
    }
  };
  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }
    ],
    // Hide sidebar by default
    defaultToolPanel: '', // empty string means closed
    // or use 'false' to hide completely
    // defaultToolPanel: false,
  };

  useEffect(() => {
  
      getAnnotationInput()
      return () => {
        // this now gets called when the component unmounts
      };
    }, [annotationFileName]);

  const resetGridView = () => {
    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;

      // Clear Filters
      gridApi?.setFilterModel(null);

      // Clear Sorting
      columnApi?.applyColumnState({
        defaultState: { sort: null },
      });

      // Reset Columns
      columnApi?.resetColumnState();

      // Reset Pinned Columns
      columnApi?.setPivotMode(false);
      
      // // Resize to fit
      // gridApi?.sizeColumnsToFit();

      // Redraw the grid
      gridApi?.redrawRows();
    }
  };

  const getAnnotationInput = async (isSave=false) => {
      setAnnotationLoader('Loading..')
      setAnnotationError(null)
      setDsInfo(null)
      setColumnsList(null)
      setSelectedRowIndex(null)
      setUndoEditCount(0)
      setMultiSelectedRow([])
      setAggregationValues(null)
      if(!isSave){
        resetGridView()
      }
      configureGenericS3()
      let level = annotationLevel
      let prefix = annotationPrefix
      let fileURL = await Storage.get(annotationFileName, { level: level, customPrefix: { public: prefix }, expires: 60 });
      let csvdata = await d3.csv(fileURL).then((result) => {
        setDsInfo(result);
        if (result?.columns) {
          const definitions = []
          for(let i=0;i<result?.columns?.length;i++){
            let colName = result?.columns[i]
            let def = {
              field: colName, headerName: colName, resizable: true, sortable: true, enableRowGroup: true, editable:false,cellSelection: 'enabled',
              filterParams: {
                closeOnApply: false,
                buttons: ['apply', 'clear'],
                defaultOption: 'contains'
              },
              cellClass: (params) => {
                return [
                  params?.data?.isEdited?.hasOwnProperty(colName) ? 'edited-cell' : '',
                  (currencyColumns.includes(colName.toLowerCase()) || editableColumns.includes(colName.toLowerCase()))? 'editable-cell': '',
                ].filter(Boolean);  // removes empty strings
              }
            }
            if(i==0){
              def['checkboxSelection'] = true
              def['headerCheckboxSelection'] = true
            }
            if (unEditableColumns.includes(colName)) {
              def['editable'] = false
            } 
            if(editableColumns.includes(colName)){
              def['editable'] = true
            }
            if (colName == 'Period - Quarter') {
              def['cellEditor'] = 'agSelectCellEditor'
              def['cellEditorParams'] = {
                values: ["Q1", "Q2", "Q3", "Q4"],
              }
            }
            if (currencyColumns.includes(colName.toLowerCase())) {
              def['valueFormatter'] = currencyFormatter
              def['valueGetter'] = (params) => {return Number(params.data?.[colName])}
              def['valueParser']=  (params) => {
                // Ensure values are numbers
                return Number(params.newValue);
              }
              def['cellDataType'] = CellDataType.NUMBER
              def['aggFunc'] = 'sum'
              def['editable'] = true
            }
            // if(pivotColumns.includes(colName)){
            //   def['pivot'] = true
            // }
  
          definitions.push(def)
          }
          setColumnsList(definitions)
        }
        setAnnotationLoader(null)
        setAnnotationError(null)
      }).catch((response) => {
        setAnnotationLoader(null)
        setAnnotationError('Loading failed')
        console.log(response)
      });
    }
    const validateAnnotationInput = async () => {
        try{
          const data = await annotationInputFile[0].arrayBuffer();
          const workbook = XLSX.read(data)
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          var readOptions = { type: 'binary', raw: false, defval: null, cellNF: false, cellDates: true, cellText: false }
          const headerInfo = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "", raw: false, cellDates: true})
          const colNames = headerInfo[0]
          setAnnotationColumns(colNames)
        }catch(e){
          setAnnotationColumns([])
        }
      }
      const resetAnnotationModalView = () => {
        setModalMessage("")
        setAnnotationUploadLoader(null)
        setAnnotationInputFile([])
        setAnnotationColumns([])
        setAnnotationLoader(null)
          setAnnotationError(null)
      }
      const saveAnnotated = () => {
          setAnnotationLoader("Saving the data..")
          let data = gridRef.current?.api.getDataAsCsv({'exportedRows': 'all',processCellCallback: (params) => {
            // Access raw data directly from node.data
            const rawValue = params.node.data[params.column.colDef.field];
            return rawValue;
          },})
          Storage.put(annotationFileName, data, {
            level: annotationLevel,
            customPrefix: { public: annotationPrefix }
        })
        .then ((result) => {     
          setAnnotationSaveSuccessMessage("Data saved successfully")
          setTimeout(() => {
            setAnnotationSaveSuccessMessage(null)
          }, 1000);
          getAnnotationInput(true)})
        .catch(err => setAnnotationError("Saving failed"));
        }
      
        const addRowToAnnotation = (data = {}) => {
          let copy = Object.assign({}, data)
          copy['isNew'] = true
          dsInfo.splice(0, 0, copy);
          setDsInfo([...dsInfo])
          setUndoEditCount(undoEditCount+1)
        }
        const delRowToAnnotation = () => {
          // sort descending to you can remove from greatest index to smallest
      
          for(let i =0;i<multiSelectedRow?.length;i++){
            dsInfo.splice(multiSelectedRow[i].rowIndex, 1);
          }
          setDsInfo([...dsInfo])
          setUndoEditCount(undoEditCount+1)
          // dsInfo.splice(selectedRowIndex, 1);
          // setDsInfo([...dsInfo])
          // setUndoEditCount(undoEditCount+1)
        }
      
        const exportAnnotated = (args={}) => {
          let csv = gridRef.current?.api.exportDataAsCsv({
            'fileName': annotationFileName,
            'exportedRows': args.filtered? 'filteredAndSorted' : 'all',
            'onlySelected': args.selectedOnly,
            processCellCallback: (params) => {
              // Access raw data directly from node.data
              if (params.node.group) {
                // Access grouped value
                return params.value;
            }else{
              const rawValue = params.node.data[params.column.colDef.field];
              return rawValue;
            }
              
            }
          });
          // gridRef.current?.api.exportDataAsExcel()
        }
      
        function currencyFormatter(params) {
          if(isNumberAfterParsed(params.value)){
            return "$" + formatNumber(params.value);
          }else{
            return '-'
          }
          
        }
        function formatNumber(number) {
            return Math.floor(number).toLocaleString();
          }
        
          function formatFloatNumber(number){
            return number.toLocaleString();
          }
        
          const onCellValueChanged = useCallback((params) => {
            const undoSize = params.api.getCurrentUndoSize();
            setUndoEditCount(undoSize)
            
            if (params.oldValue !== params.newValue) {
              let editedCols = params.data.isEdited || {}
              if(editedCols.hasOwnProperty(params.column.colId)){
                // store original old value
                editedCols[params.column.colId] = {oldValue: editedCols[params.column.colId]?.oldValue, newValue: params.newValue}
              }else{
                // new entry
                editedCols[params.column.colId] = {oldValue: params.oldValue, newValue: params.newValue}
              }
              params.data.isEdited = editedCols; 
           }
           params.api.refreshCells();
          }, []);
        
          const onCellFocused = useCallback((params) => {
            setSelectedRowIndex(params.rowIndex)
          }, []);
        
          const onSelectionChanged = (param) => {
            const selectedNodes = gridRef.current.api.getSelectedNodes();
            const selectedData = selectedNodes.map(node => ({
              rowIndex: node.rowIndex,
              data: node.data
            }));
            setMultiSelectedRow(selectedData.sort((a, b) => b.rowIndex - a.rowIndex))
          };

const onGridReady = useCallback((params) => {
    // gridRef?.current?.api.sizeColumnsToFit();
    const allColumnIds = [];
    gridRef?.current?.api?.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.api?.autoSizeColumns(allColumnIds);
    updateGridInfo()
  }, []);

  const isNumber = (value) => {
    try{
      if (typeof value === 'number' && !isNaN(value)) {
        return true;
      }
      // if (typeof value === 'string' && !isNaN(Number(value))) {
      //   return true;
      // }
      return false;
    }catch(e){
      return false
    }
  };

  const isNumberAfterParsed = (value) => {
    try{
      if (typeof value === 'number' && !isNaN(value)) {
        return true;
      }
      if (typeof value === 'string' && !isNaN(Number(value))) {
        return true;
      }
      return false;
    }catch(e){
      return false
    }
  };
    
const getContextMenuItems = (params) => {
  let menuItems = [
    'copy',
    'copyWithHeaders',
    // 'paste',
    'separator', 
    'export'
  ]
  if(props.canEdit){
    menuItems.push('separator')
    menuItems.push({
      name: 'Clone',
      action: () => {
        addRowToAnnotation(params.node.data)
      }
    })
  }
  return menuItems;
  }

  const onFilterChanged = () => {
    updateGridInfo();
  };

  const onViewportChanged = () => {
    updateGridInfo();
  };

  const updateGridInfo = () => {
    if (gridRef?.current?.api) {
      const api = gridRef.current.api;
      setGridInfo({
        rowCount: api?.getDisplayedRowCount(),
        totalRows: dsInfo?.length,
      });
    }
  };

  const onRangeSelectionChanged = useCallback((event) => {
    setAggregationValues(null)
    const gridApi = event.api;
    const cellRanges = gridRef?.current?.api?.getCellRanges();
    let count = 0
    let min = null
    let max = null
    let sum = 0

    if (cellRanges) {
      cellRanges.forEach(range => {
        range.columns.forEach(column => {
          const colId = column?.getColId();
          const valueGetter = column?.getColDef()?.valueGetter;
          
          for (let rowIndex = range?.startRow?.rowIndex; rowIndex <= range?.endRow?.rowIndex; rowIndex++) {
            const node = gridApi?.getDisplayedRowAtIndex(rowIndex);
            if (!node) continue;
  
            let value;
            if (valueGetter) {
              value = valueGetter({
                data: node?.data,
                node: node,
                column: column,
                api: gridApi
              });
            } else {
              value = node?.data[colId];
            }
            
            if(!isNumber(value)){
              continue
            }
            count=count+1
            sum = sum + value
            if(min == null || value < min){
              min = value
            }
            if(max == null ||value > max){
              max = value
            }
          }
        });
      });
    }
    if(count > 0){
      setAggregationValues({
        'average' : formatFloatNumber(sum/count),
        'count': count,
        'sum': formatFloatNumber(sum),
        'min': formatFloatNumber(min),
        'max': formatFloatNumber(max)
      })
    }
    
  }, [])

  const onColumnRowGroupChanged = useCallback(()=>{
    setRowGroupColumns(gridRef?.current?.api?.getRowGroupColumns())
    updateGridInfo()
  },[])

  const uploadAnnotationAppendInput = async () => {
      const file = annotationInputFile[0]
      setAnnotationUploadLoader("Loading..")
      const data = await file.arrayBuffer();
      let text = new TextDecoder().decode(data);
      let result = d3.csvParse(text)
      result = result.map(item => ({
        ...item,
        isNew: true
      }));
      setAnnotationError(null)
        setDsInfo(dsInfo.concat(result))
        setUndoEditCount(undoEditCount+1)
        setShowAnnotationImportAppendModal(false)
        resetAnnotationModalView()
  
    }
  const uploadAnnotationInput = async () => {
      configureGenericS3()
      const file = annotationInputFile[0]
      let level = annotationLevel
      let prefix = annotationPrefix
      setAnnotationUploadLoader("Uploading..")
      let putResp = await Storage.vault.put(annotationFileName, file, {
                      level: level,
                      customPrefix: { public: prefix },
                      acl: 'bucket-owner-full-control'
      })
      setShowAnnotationImportModal(false)
      resetAnnotationModalView()
      getAnnotationInput()
    }

  return <SpaceBetween direction="vertical" size="m">
  <Modal
  onDismiss={() => {setShowAnnotationImportModal(false);resetAnnotationModalView()}}
  visible={showAnnotationImportModal}
  header="Upload"
  size="large"
>
  
<Grid
  gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}
>
<SpaceBetween direction="vertical" size="s">
{annotationUploadLoader? <Box><Spinner></Spinner> uploading input file..</Box>: ''}
  <SpaceBetween direction="horizontal">
    <FileUpload
      onChange={({ detail }) => {
        setAnnotationInputFile(detail.value);
      }}
      value={annotationInputFile}
      i18nStrings={{
        uploadButtonText: e =>
          e ? "Choose files" : "Choose file",
        dropzoneText: e =>
          e
            ? "Drop files to generate"
            : "Drop file to generate",
        removeFileAriaLabel: e =>
          `Remove file ${e + 1}`,
        limitShowFewer: "Show fewer files",
        limitShowMore: "Show more files",
        errorIconAriaLabel: "Error"
      }}
      showFileLastModified
      showFileSize
      showFileThumbnail
      tokenLimit={1}
      accept=".csv"
      constraintText="upload input file"
    />

    {
      annotationInputFile && annotationInputFile?.length > 0 ? <Button variant="primary" disabled={annotationUploadLoader} onClick={uploadAnnotationInput}>Upload</Button> : ''
    }
    
  </SpaceBetween>

  <SpaceBetween direction="vertical" size="s">
      {annotationColumns?.map((column) => {
        return <StatusIndicator>{column}</StatusIndicator>
      })}
  </SpaceBetween>
</SpaceBetween>

{
    validationColumns && validationColumns.length > 0 ? <Container><SpaceBetween direction="vertical">
    <Box>The file should contain the following columns in below order</Box>
    <Box>
      {validationColumns.map((column) => {
        return <li>{column}</li>
      })}
    </Box>
  </SpaceBetween></Container>:''
  }


</Grid>
  
</Modal>

<Modal
  onDismiss={() => {setShowAnnotationImportAppendModal(false);resetAnnotationModalView()}}
  visible={showAnnotationImportAppendModal}
  header="Upload"
  size="large"
>
  
<Grid
  gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}
>
<SpaceBetween direction="vertical" size="s">
{annotationUploadLoader? <Box><Spinner></Spinner> Loading input file..</Box>: ''}
  <SpaceBetween direction="horizontal">
    <FileUpload
      onChange={({ detail }) => {
        setAnnotationInputFile(detail.value);
      }}
      value={annotationInputFile}
      i18nStrings={{
        uploadButtonText: e =>
          e ? "Choose files" : "Choose file",
        dropzoneText: e =>
          e
            ? "Drop files to generate"
            : "Drop file to generate",
        removeFileAriaLabel: e =>
          `Remove file ${e + 1}`,
        limitShowFewer: "Show fewer files",
        limitShowMore: "Show more files",
        errorIconAriaLabel: "Error"
      }}
      showFileLastModified
      showFileSize
      showFileThumbnail
      tokenLimit={1}
      accept=".csv"
      constraintText="upload input file"
    />

    {
      annotationInputFile && annotationInputFile?.length > 0 ? <Button variant="primary" disabled={annotationUploadLoader} onClick={()=>{uploadAnnotationAppendInput()}}>Upload</Button> : ''
    }
    
  </SpaceBetween>

  <SpaceBetween direction="vertical" size="s">
    {
            annotationInputFile  && annotationInputFile?.length > 0 && annotationColumns  && annotationColumns?.length > 0? <>
              {
                dsInfo?.columns ? <>
                  {dsInfo?.columns?.map((column, index) => {
                    if (column == annotationColumns[index]) {
                      return <StatusIndicator>{column}</StatusIndicator>
                    } else {
                      return <StatusIndicator type='error'>{dsInfo.columns[index]}</StatusIndicator>
                    }
                  })}
                </> : <>
                  {annotationColumns?.map((column, index) => {
                    return <StatusIndicator>{column}</StatusIndicator>
                  })}
                </>
              }
            </> : <></>

          }
          {annotationColumns?.map((column, index) => {
            if (dsInfo?.columns?.[index]) {
              if (column == dsInfo.columns[index]) {
                return <StatusIndicator>{column}</StatusIndicator>
              } else {
                return <StatusIndicator type='error'>{dsInfo.columns[index]}</StatusIndicator>
              }
            } else {
              return <StatusIndicator>{column}</StatusIndicator>
            }


          })}
  </SpaceBetween>
</SpaceBetween>

  {
    validationColumns && validationColumns.length > 0 ? <Container><SpaceBetween direction="vertical">
    <Box>The file should contain the following columns in below order</Box>
    <Box>
      {validationColumns.map((column) => {
        return <li>{column}</li>
      })}
    </Box>
  </SpaceBetween></Container>:''
  }


</Grid>
  
</Modal>
<Container disableContentPaddings={false} disableHeaderPaddings={true}>
<SpaceBetween direction="vertical">
<Grid
  gridDefinition={[
    { colspan: { default: 3, xxs: 3 } },
    { colspan: { default: 9, xxs: 9 } }
  ]}
>
<Box  padding="m">
  <SpaceBetween direction="horizontal" size="xs">
    { annotationLoader ? <Box> <Spinner></Spinner>{annotationLoader}</Box> : <></>}
    { annotationError ? <Box> <span className="error_message">{annotationError}</span> </Box> : <></>}
    {
      (!(undoEditCount <= 0 || rowGroupColumns?.length > 0))? <Box><StatusIndicator type="warning">Attention: You have unsaved work</StatusIndicator></Box> : ''
    }
    {
      annotationSaveSuccessMessage ? <StatusIndicator type="success">{annotationSaveSuccessMessage}</StatusIndicator>:''
    }
  </SpaceBetween>
</Box>
<Box float="right" padding="m">
  <SpaceBetween direction="horizontal" size="xl">
  {/* <Button variant="icon" iconName="undo" onClick={(()=>undo())}></Button>
  <Button variant="icon" iconName="redo" onClick={(()=>redo())}></Button> */}

   <Button onClick={(()=>saveAnnotated())} disabled={undoEditCount <= 0 || rowGroupColumns?.length > 0}>Save</Button>
  <ButtonDropdown
  items={[
    {text: "Export to excel", 
      items: [
        { text: "Current view", id: "current_view", disabled: false },
        { text: "Selected rows", id: "selected_rows", disabled: false },
        { text: "Original", id: "original", disabled: false }
      ]},
    {text: "Import", 
      items: [
        { text: "Upload from excel", id: "import_csv", disabled: false },
        { text: "Add additional data from excel", id: "import_csv_append", disabled: !props.canEdit }
      ]
    }
  ]}
  onItemClick={(event)=> {
    if(event.detail.id == 'current_view'){
      exportAnnotated({'filtered':true})
    }else if(event.detail.id == 'selected_rows'){
      exportAnnotated({'selectedOnly':true})
    }
    else if(event.detail.id == 'original'){
      exportAnnotated()
    }else if(event.detail.id == 'import_csv'){
      setShowAnnotationImportModal(true)
    }else if (event.detail.id == 'import_csv_append'){
      setShowAnnotationImportAppendModal(true)
    }
  }}
>
  Actions
</ButtonDropdown>
{
  props.canEdit ? <Button onClick={(()=>addRowToAnnotation())} iconName="insert-row">Add</Button> : ''
}
{
  props.canEdit ? <Button onClick={(()=>delRowToAnnotation())} disabled={multiSelectedRow?.length<=0} iconName="delete-marker">Delete</Button>  : ''
}
{/* <Button variant="icon" iconName="unlocked" onClick={(()=>handleViewChanges())}></Button> */}
  <Button variant="icon" iconName="refresh" onClick={(()=>getAnnotationInput())}></Button>
  </SpaceBetween>
</Box>
</Grid>
<div style={{ 
  width: '100%', 
  height: 'calc(100vh - 200px)', 
}}>
  <div 
    className="ag-theme-alpine" 
    style={{
      width: '100%',
      height: '100%',
      minHeight: '500px'  
    }}
  >
          <AgGridReact
            ref={gridRef}
            rowData={dsInfo}
            defaultColDef={defaultProjectColDef}
            columnDefs={columnsList}
            rowSelection='multiple'
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={true}
            rowMultiSelectWithClick={true}
            rowNumbers={true}
            // editType={"fullRow"}
            animateRows={true}
            rowHeight={30}
            enableCellTextSelection={false}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onCellValueChanged={onCellValueChanged}
            onCellFocused={onCellFocused}
            onGridReady={onGridReady}
            onColumnRowGroupChanged={onColumnRowGroupChanged}
            rowGroupPanelShow="always"
            sideBar={sideBar}
            // groupTotalRow="bottom"
            groupAggFiltering={false}
            pivotMode={false}
            suppressAggFuncInHeader={false}
            enableCellSelection={true}   
            enableRangeSelection={true} 
            onRangeSelectionChanged={onRangeSelectionChanged}
            getContextMenuItems={getContextMenuItems}
            enableContextMenu={true}
            // onModelUpdated={onModelUpdated}
            onFilterChanged={onFilterChanged}
            onViewportChanged={onViewportChanged}
            suppressMenuHide={true}
            suppressScrollOnNewData={true}
            ensureDomOrder={true}
          />
          {
            aggregationValues &&  aggregationValues?.count > 1? 
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
              <Box><span className="agg-text bold-text">Average:</span> <span className="agg-text">{aggregationValues.average}</span></Box>
              <Box><span className="agg-text bold-text">Count:</span> <span className="agg-text">{aggregationValues.count}</span></Box>
              <Box><span className="agg-text bold-text">Min:</span> <span className="agg-text">{aggregationValues.min}</span></Box>
              <Box><span className="agg-text bold-text">Max:</span> <span className="agg-text">{aggregationValues.max}</span></Box>
              <Box><span className="agg-text bold-text">Sum:</span> <span className="agg-text">{aggregationValues.sum}</span></Box>
            </SpaceBetween>
            </Box>
              : ''
          }
          {
            gridInfo? <Box float="left">
            <SpaceBetween direction="horizontal" size="s">
            <Box><span className="agg-text bold-text">Filtered rows:</span> <span className="agg-text">{gridInfo.rowCount}</span></Box>
            <Box><span className="agg-text bold-text">Total rows:</span> <span className="agg-text">{gridInfo.totalRows}</span></Box>
          </SpaceBetween>
          </Box>:''
          }
</div>
</div>
</SpaceBetween>

</Container>
</SpaceBetween>
}
export default AnnotateS3;