


import appSettings from 'src/Ateam-BI/CorpReporting/app_settings'
import { FRISCO_STATUS, FRISCO_TRACK_CURRENT_STATUS, FRISCO_TRACK_OVERALL_STATUS, PROCESS_KEYS } from 'src/Ateam-BI/Frisco/Constants'
import { getFriscoProcessWithTracksForQuarter } from 'src/Ateam-BI/Templates/Utils/AppActivityUtils'

export const request = (request, onResponse) => {
    let corpReporingId = appSettings[request?.stage]['appId']
    if (request?.appId == corpReporingId) {
        onResponse({
            'allowWBSelection': true,
            'allowSupportingDocUpload': true
        })

    } else {
        onResponse({  })
    }
}

