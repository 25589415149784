import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  ExpandableSection,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
  SegmentedControl,
  FormField,
  Input,
  TimeInput,
  Select,
  Modal
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import {checkAppFunctionalityAccess} from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import { setCurrentAppId } from "../../store/userAuthSlice";


import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";

import { months, etbOptions } from "./Constants";
import { constructExcelSheetWithHeadersAndData } from "../Templates/Utils/AppActivityUtils";


const RegionTriggerMapping = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const [items, setItems] = useState([]);
  const [processingStatus, setProcessingStatus] = useState("info");
  const [currentStatusId, setCurrentStatusId] = useState("");

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const [currentId, setCurrentId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);

  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);
  const [selectedSegmentId, setSelectedSegmentId] = useState('add')

  const [region, setRegion] = useState('')
  const [reportMonth, setReportMonth] = useState()
  const [triggerMonth, setTriggerMonth] = useState()
  const [triggerDay, setTriggerDay] = useState()
  const [triggerTime, setTriggerTime] = useState()
  const [etbVersion, setETBVersion] = useState()
  const [cutOffMonth, setCutOffMonth] = useState()
  const [cutOffDay, setCutOffDay] = useState()
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false)
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [addOrUpdateModal, setAddOrUpdateModal] = useState(false)
  const [addStatus, setAddStatus] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [addOrUpdateError, setAddOrUpdateError] = useState('')
  const [deleteMessage, setDeleteMessage] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)
  

  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)

  const [hasPermissionToAddOrEdit, setHasPermissionToAddOrEdit] = useState(false)

  useEffect(() => {
   
    loadData();

    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);


    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);


  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    {
      field: "region",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Region"
    },
    {
      field: "etb_version",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "ETB Version"
    },
    {
      field: "report_period",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Report Period"
    },
    {
      field: "month",
      width: "100px",
      sortable: true,
      resizable: true,
      headerName: "Month"
    },
    {
      field: "trigger_day",
      width: "120px",
      sortable: true,
      resizable: true,
      headerName: "Trigger Day"
    },
    {
      field: "trigger_time_pst",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Trigger Time PST"
    },
    {
      field: "cutoff_date",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Re-trigger cut-off"
    },
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setIsItemSelected(true)
      setSelectedSegmentId("edit")
      setSelectedItem(selectedRows[0]);
      setCurrentId(selectedRows[0].id);
      setRegion(selectedRows[0]?.region)
      setReportMonth({ label: selectedRows[0]?.report_period.split('-')[1], value: selectedRows[0]?.report_period.split('-')[1], disabled: false })
      setTriggerMonth({ label: selectedRows[0]?.month, value: selectedRows[0]?.month, disabled: false })
      setTriggerDay(Number(selectedRows[0]?.trigger_day))
      setTriggerTime(selectedRows[0]?.trigger_time_pst)
      setETBVersion({label: selectedRows[0]?.etb_version, value: selectedRows[0]?.etb_version, disabled: false })
      setCutOffMonth({ label: selectedRows[0]?.cutoff_date.split('-')[1], value: selectedRows[0]?.cutoff_date.split('-')[1], disabled: false })
      setCutOffDay(Number(selectedRows[0]?.cutoff_date.split('-')[0]))
    } else {
      setIsItemSelected(false)
      setSelectedItem([]);
      setRegion('')
      setReportMonth()
      setTriggerMonth()
      setTriggerDay()
      setTriggerTime()
      setETBVersion()
      setCutOffMonth()
      setCutOffDay()
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "TED", href: "/TEDHome" },
    { text: "Review", href: "" },
  ];

  const loadData = async () => {
    setShowLoadingIcon(true);
    const details = {
      userAlias: userId,
      id: appSettings.appId,
      accessLevelId: "3",
      stage: currentStage
    }
    const permission = await checkAppFunctionalityAccess(details)
    setHasPermissionToAddOrEdit(permission)
    if (showAllItems == false) {
      var mQry =
        `query MyQuery {
          listRegionTriggerMapping {
            region
            etb_version
            report_period
            month
            trigger_day
            trigger_time_pst
            cutoff_date
          }
        }`;
    }
    if (showAllItems) {
      var mQry = `query MyQuery {
        listRegionTriggerMapping {
          region
          etb_version
          report_period
          month
          trigger_day
          trigger_time_pst
          cutoff_date
        }
      }`;
    }

    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.listRegionTriggerMapping;
    // qResultJSON.sort(function (a, b) {
    //   var dateA = new Date(a.createDate),
    //     dateB = new Date(b.createDate);
    //   return dateB - dateA;
    // });
    setItems(qResultJSON);
    setShowLoadingIcon(false);

  };

    const downloadData = async () => {
        setDownloadLoading(true)
        let downloadResults = []
       
                const headers = [
                  "Region",
                  "ETB Version",
                  "Report Period" ,
                  "Month",
                  "Trigger Day",
                  "Trigger Time",
                  "Re-trigger cut-off"
                ]
        downloadResults = items?.map((item) => {
          return {
            "Region" : item.region,
            "ETB Version": item.etb_version,
            "Report Period" : item.report_period,
            "Month": item.month,
            "Trigger Day": item.trigger_day,
            "Trigger Time": item.trigger_time_pst,
            "Re-trigger cut-off": item.cutoff_date
          }
        })
        constructExcelSheetWithHeadersAndData(headers, downloadResults, "RegionTriggerMappingData")           
    
        setDownloadLoading(false)
      }

  const detailView = (
    <Container>
      {selectedItem.length != 0 ? (
        <ColumnLayout columns={3}>
          <Box variant="awsui-key-label">
            File id
            <Box>{selectedItem.id}</Box>
          </Box>
          {/* <Box variant='awsui-key-label'>Action
                <Box color='text-body-secondary'>
                    <Icon variant='warning' name='status-info'></Icon> &nbsp;
                    Click on this button to initiate recompute. Use this option to execute 
                    the recompute, incase the processing is stuck. Click on the button, 
                    and wait for the updates to flow in.
                </Box>
                <Button on onClick={()=>{executeRecompute()}}>ReCompute</Button>
            </Box> */}
        </ColumnLayout>
      ) : (
        <Box variant="p" color="text-status-error">
          Select any row from above table
        </Box>
      )}
    </Container>
  );

  const content = (
    <SpaceBetween direction="vertical" size="m">
      {isAuthorized ? (
        <Box>
          <Box></Box>
          <Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                        <SpaceBetween direction="horizontal" size="xs">
                          {showLoadingIcon ? (
                            <Box>
                              <Spinner size="normal"></Spinner>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
      
                          <Button
                          iconAlign="right"
                          iconName="download"
                          onClick={downloadData}
                          loading={downloadLoading}
                          >
                          export results
                          </Button> 
      
                          <Button
                            variant="normal"
                            iconName="refresh"
                            onClick={() => loadData()}
                          >
      
                          </Button>
                        </SpaceBetween>
                      }
                description="Below Table showing trigger schedules regions, contact Ateam-BI to make trigger configuration changes"
              >
                Region - Trigger Schedule 
              </Header>
            }
          >
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact 
                ref={gridRef}
                rowData={items}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="single"
                animateRows={true}
                rowHeight={30}
                checkboxSelection={true}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                enableCellTextSelection={true}
              ></AgGridReact>
            </div>
          </Container>
          <Modal
            visible={addOrUpdateModal}
            header={<div>Error</div>}
            onDismiss={() => {
              setAddOrUpdateModal(false)
              setAddOrUpdateError('')
            }}
            >
              {addOrUpdateError}
          </Modal>
          <Modal
            visible={addStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setAddStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Added!
          </Modal>
          <Modal
            visible={updateStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setUpdateStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Updated!
          </Modal>

          <Modal
            visible={deleteStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setDeleteStatus(false)
              setDeleteMessage('')
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
           {deleteMessage}
          </Modal>

          <Modal
            visible={showDeleteConfirmation}
            header={<div>Please Confirm</div>}
            onDismiss={() => {
              setShowDeleteConfirmation(false)
            }}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={()=>setShowDeleteConfirmation(false)}>Cancel</Button>
                  <Button variant="primary" onClick={()=>performDeletion()}>Confirm</Button>
                </SpaceBetween>
              </Box>
            }
            >
            The Record(s) where  <br/>
            Region is <strong>{`${region}`}</strong> and<br/>
            Report month is  <strong>{`${reportMonth?.value}`}</strong> and<br/>
            ETB Version is  <strong>{`${etbVersion?.value}`}</strong> <br/> 
            will be deleted.
          </Modal>

          </Box>
          <p></p>

          <p></p>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </SpaceBetween>
  );

  const executeQuery = async (mQry, onSuccess, onFailure) => {
    // console.log(mQry)    
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      onSuccess(result)
    }, function (error) {
      onFailure(error)
    });

  };

  const addRecord = () => {

    //Validation checks before Adding:

    if (!(region && reportMonth && triggerMonth && 
      triggerDay && triggerTime && etbVersion && cutOffMonth
      && cutOffDay)) {
      setAddOrUpdateError('All fields are required when adding new record!')
      setAddOrUpdateModal(true)
      return;
    }
    let combinationFound = false;
    items.every((item) => {
      if (item?.region === region && item?.etb_version === etbVersion?.value && item?.report_period.split('-')[1] === reportMonth.value) {
        combinationFound = true;
        return false;
      } else {
        return true;
      }
    })
    if(combinationFound) {
      setAddOrUpdateError('A record with same region, etb version and report month combination already exists, please edit that instead of adding new one.')
      setAddOrUpdateModal(true)
      return
    }
    
    //Checks Complete


    setShowUpdatingIcon(true)
    let qry = `mutation MyMutation {
      insertRegionTriggerMapping(cutOffDay: "${cutOffDay}", cutOffMonth: "${cutOffMonth?.value}", etbVersion: "${etbVersion?.value}", region: "${region}", 
      reportMonth: "${reportMonth?.value}", triggerDay: "${triggerDay}", triggerMonth: "${triggerMonth?.value}", triggerTime: "${triggerTime}", userAlias: "${userId}") {
        message
      }
    }`

    executeQuery(qry, function onSuccess(result) {
      let qResultJSON = result.data.insertRegionTriggerMapping;
      if (qResultJSON?.message?.toLowerCase() === 'success') {
        setShowUpdatingIcon(false);
        setAddStatus(true);
      } else {
        
      }
      setShowLoadingIcon(false);
      loadData()
    }, function onFailure(error) {
      setShowLoadingIcon(false); 
    })

  }

  const updateRecord = () => {

    //Validation checks before updating:

    if (!(region && reportMonth && triggerMonth && 
      triggerDay && triggerTime && etbVersion && cutOffMonth
      && cutOffDay)) {
      setAddOrUpdateError('All fields are required when updating a record!')
      setAddOrUpdateModal(true)
      return;
    }

    //Checks Complete

    setShowUpdatingIcon(true)
    let qry = `mutation MyMutation {
      updateRegionTriggerMapping(cutOffDay: "${cutOffDay}", cutOffMonth: "${cutOffMonth?.value}", etbVersion: "${etbVersion?.value}", region: "${region}", 
      reportMonth: "${reportMonth?.value}", triggerDay: "${triggerDay}", triggerMonth: "${triggerMonth?.value}", triggerTime: "${triggerTime}", userAlias: "${userId}") {
        message
      }
    }`

    executeQuery(qry, function onSuccess(result) {
      let qResultJSON = result.data.updateRegionTriggerMapping;
      if (qResultJSON?.message?.toLowerCase() === 'success') {
        setShowUpdatingIcon(false);
        setUpdateStatus(true);
      } else {
        
      }
      setShowLoadingIcon(false);
      loadData()
    }, function onFailure(error) {
      setShowLoadingIcon(false); 
    })
    
  }

  const deleteRecord = () => {
     //Validation checks before updating:

     if (!(region && reportMonth && etbVersion)) {
      setAddOrUpdateError('Region, Report Month and ETB Version are required when deleting a record!')
      setAddOrUpdateModal(true)
      return;
    }

    //Checks Complete

    setShowDeleteConfirmation(true)
    
  }
  const performDeletion = () => {

    setShowUpdatingIcon(true)
    setShowDeleteConfirmation(false)
    let qry = `mutation MyMutation {
      deleteRegionTriggerMapping(etbVersion: "${etbVersion?.value}", region: "${region}", 
      reportMonth: "${reportMonth?.value}") {
        message
      }
    }`

    executeQuery(qry, function onSuccess(result) {
      let qResultJSON = result.data.deleteRegionTriggerMapping;
      if (qResultJSON?.message?.toLowerCase().startsWith('success')) {
        setDeleteMessage(qResultJSON?.message)
        setShowUpdatingIcon(false);
        setDeleteStatus(true);
        setIsItemSelected(false)
        setSelectedItem([]);
        setRegion('')
        setReportMonth()
        setTriggerMonth()
        setTriggerDay()
        setTriggerTime()
        setETBVersion()
        setCutOffMonth()
        setCutOffDay()
      } else {
        
      }
      setShowLoadingIcon(false);
      loadData()
    }, function onFailure(error) {
      setShowLoadingIcon(false); 
    })
  }
  
  const helpContent = (
    <HelpPanel
      header={
        <div>
          <div></div>
          <h2>Add New Record / Edit Existing Record</h2>
        </div>
      }
    >
      <Container>
        <Box>
          <SegmentedControl
            selectedId={selectedSegmentId}
            onChange={({ detail }) =>{ 
              setSelectedSegmentId(detail.selectedId)
              if(detail.selectedId === 'add') {
                gridRef.current.api.deselectAll()
              }
            }}
            options={[
              { text: "Add New Record", id: "add", iconName: "add-plus" },
              { text: "Edit Existing Record", id: "edit", iconName: "edit" },
            ]}
          />
        </Box>
        <br />
        {selectedSegmentId === "add" ? (
          <>
          <Box>
            <ColumnLayout columns={2}>
              <FormField label="REGION">
                <Input
                  type="text"
                  value={region}
                  onChange={({ detail }) => {setRegion(detail.value)}}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="REPORT_MONTH">
              <Select
              selectedOption={reportMonth}
              options={months}
              onChange={({ detail }) => {
                setReportMonth(detail.selectedOption);
              }}            
            ></Select>
              </FormField>
              <FormField label="ETB_VERSION">
                <Select
              selectedOption={etbVersion}
              options={etbOptions}
              onChange={({ detail }) => {
                setETBVersion(detail.selectedOption);
              }}            
              ></Select>
              </FormField>
              <FormField label="MONTH">
              <Select
              selectedOption={triggerMonth}
              options={months}
              onChange={({ detail }) => {
                setTriggerMonth(detail.selectedOption);
              }}            
            ></Select>
              </FormField>
              <FormField label="TRIGGER_DAY">
                <Input
                  type="number"
                  value={triggerDay}
                  onChange={({ detail }) => setTriggerDay(detail.value)}
                ></Input>
              </FormField>
              <FormField label="TRIGGER_TIME_PST">
              <TimeInput
                onChange={({ detail }) => setTriggerTime(detail.value)}
                value={triggerTime}
                format="hh:mm"
                placeholder="hh:mm in 24 hours format"
              />
              </FormField>
              <FormField label="CUTOFF_MONTH">
              <Select
              selectedOption={cutOffMonth}
              options={months}
              onChange={({ detail }) => {
                setCutOffMonth(detail.selectedOption);
              }}            
            ></Select>
              </FormField>
              <FormField label="CUTOFF_DAY">
                <Input
                  type="number"
                  value={cutOffDay}
                  onChange={({ detail }) => setCutOffDay(detail.value)}
                ></Input>
              </FormField>
             
            </ColumnLayout>
          </Box>
          <br />
          <Button variant='primary' iconName= "add-plus" onClick={() => addRecord()}> Add</Button>
          <Box>
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Adding...{" "}
              </Box>
            ) : (
              <Box>
               
              </Box>
            )}
          </Box>
          </>
        ) : (
          <>
          {(isItemSelected) ? <>
          <Box>
          <ColumnLayout columns={2}>
              <FormField label="REGION">
                <Input
                  type="text"
                  value={region}
                  onChange={({ detail }) => {setRegion(detail.value)}}
                  ariaRequired
                  disabled
                ></Input>
              </FormField>
              <FormField label="ETB_VERSION">
                <Select
              selectedOption={etbVersion}
              options={etbOptions}
              onChange={({ detail }) => {
                setETBVersion(detail.selectedOption);
              }}
              disabled            
            ></Select>
              </FormField>
              <FormField label="REPORT_MONTH">
              <Select
              selectedOption={reportMonth}
              options={months}
              onChange={({ detail }) => {
                setReportMonth(detail.selectedOption);
              }}
              disabled            
            ></Select>
              </FormField>
              <FormField label="MONTH">
              <Select
              selectedOption={triggerMonth}
              options={months}
              onChange={({ detail }) => {
                setTriggerMonth(detail.selectedOption);
              }}            
            ></Select>
              </FormField>
              <FormField label="TRIGGER_DAY">
                <Input
                  type="number"
                  value={triggerDay}
                  onChange={({ detail }) => setTriggerDay(detail.value)}
                ></Input>
              </FormField>
              <FormField label="TRIGGER_TIME_PST">
              <TimeInput
                onChange={({ detail }) => setTriggerTime(detail.value)}
                value={triggerTime}
                format="hh:mm"
                placeholder="hh:mm in 24 hours format"
              />
              </FormField>
              <FormField label="CUTOFF_MONTH">
              <Select
              selectedOption={cutOffMonth}
              options={months}
              onChange={({ detail }) => {
                setCutOffMonth(detail.selectedOption);
              }}            
            ></Select>
              </FormField>
              <FormField label="CUTOFF_DAY">
                <Input
                  type="number"
                  value={cutOffDay}
                  onChange={({ detail }) => setCutOffDay(detail.value)}
                ></Input>
              </FormField>
             
            </ColumnLayout>  
          </Box>
          <br />
          <SpaceBetween size="m" direction="horizontal">
          <Button variant='primary' iconName= "edit" onClick={() => updateRecord()}>Update</Button>
          <Button iconName= "remove" onClick={() => deleteRecord()}>Delete</Button>
          </SpaceBetween>
          <Box>
          {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Updating...{" "}
              </Box>
            ) : (
              <Box>
                
              </Box>
            )}
          </Box>
          </>
          :
        <Box variant="awsui-key-label">Select a row from schedule to edit and update.</Box>
        }
          
          </>
        )}
      </Container>
    </HelpPanel>
  );
  const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: "Split panel preferences",
    preferencesPositionLabel: "Split panel position",
    preferencesPositionDescription:
      "Choose the default split panel position for the service.",
    preferencesPositionSide: "Side",
    preferencesPositionBottom: "Side",
    preferencesConfirm: "Confirm",
    preferencesCancel: "Cancel",
    closeButtonAriaLabel: "Close panel",
    openButtonAriaLabel: "Open panel",
    resizeHandleAriaLabel: "Resize split panel",
  };
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/RegionTriggerMapping" />}
      headerSelector="#TopBar"
      navigationHide={false}
      splitPanel={
        <SplitPanel
          closeBehavior="collapse"
          header="Preview"
          i18nStrings={SPLIT_PANEL_I18NSTRINGS}
        >
          {detailView}
        </SplitPanel>
      }
      tools={helpContent}
      toolsWidth={500}
      toolsOpen={hasPermissionToAddOrEdit}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default RegionTriggerMapping;
