import React, {useState, useEffect} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const [applicationName, setApplicationName] = useState(props.applicationName)
  const [id, setId] = useState(props.id)

  const history = useHistory();

  useEffect(() =>{
    setApplicationName(props.applicationName)
  },[props.applicationName])

  const items = [
    
    {
        "type": "section",
        "text": "Tools & Links",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": (<div>Journal Postings</div>),
                "href": "/GeneralTools/JournalPostings"
            },

            {
              "type": "link",
              "text": (<div>Reporting</div>),
              "href": "/GeneralTools/Reporting"
          },

          {
            "type": "link",
            "text": (<div>Analytics</div>),
            "href": "/GeneralTools/Analytics"
        },

            {
                "type": "link",
                "text": (<div>Policies & Procedures</div>),
                "href": "/GeneralTools/PoliciesAndProcedures"
            },

            {
              "type": "link",
              "text": (<div>US GAAP</div>),
              "href": "/GeneralTools/us-gapp"
          },

          {
            "type": "link",
            "text": (<div>Functional Accounting Close</div>),
            "href": "/GeneralTools/FunctionalAccountingClose"
        },
        {
          "type": "link",
          "text": (<div>Stationary Accounting Close</div>),
          "href": "/GeneralTools/StationaryAccountingClose"
      },
            
        ]

    },

    {
      "type": "link",
      "text": "Program Updates",
      "href": "https://w.amazon.com/bin/view/StatutoryProgram_Wiki/",
      "external": true
  },

    {
      "type": "link",
      "text": "Pillars",
      "href": "https://w.amazon.com/bin/view/StatutoryProgram_Wiki/WhoWeAre/",
      "external": true

  },
  {
    "type": "link",
    "text": "Contact",
    "href": "https://phonetool.amazon.com/users/jonathr",
    "external": true

},

    { "type": "divider" },

    
    {
        "type": "link",
        "text": "All Apps",
        "href": "/"
    },


    ];

  
  return (
    <SideNavigation
      activeHref={activeHref}
        header={{ href: "/GeneralTools/JournalPostings", text: "General Apps & Tools" }}      
        onFollow={e => {
          if (!e.detail.external) {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
        }
    }}
      items={items}
    />
  );
}