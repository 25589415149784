export const months = [
    { label: "JAN", value: "JAN", disabled: false },
    { label: "FEB", value: "FEB", disabled: false },
    { label: "MAR", value: "MAR", disabled: false },
    { label: "APR", value: "APR", disabled: false },
    { label: "MAY", value: "MAY", disabled: false },
    { label: "JUN", value: "JUN", disabled: false },
    { label: "JUL", value: "JUL", disabled: false },
    { label: "AUG", value: "AUG", disabled: false },
    { label: "SEP", value: "SEP", disabled: false },
    { label: "OCT", value: "OCT", disabled: false },
    { label: "NOV", value: "NOV", disabled: false },
    { label: "DEC", value: "DEC", disabled: false },
    
]

export const years = [
    { label: "22", value: "22", disabled: false },
    { label: "23", value: "23", disabled: false },
    { label: "24", value: "24", disabled: false },
    { label: "25", value: "25", disabled: false },


]

export const ted_regions =[
    { label :"All" , value :"All"} ,
    { label :"LATAM_South" , value :"LATAM_South"} ,
    { label :"LATAM_North" , value :"LATAM_North"} ,
    { label :"APAC" , value :"APAC"}
]

export const ted_insert_or_delete_regions =[
    { label :"LATAM_South" , value :"LATAM_South"} ,
    { label :"LATAM_North" , value :"LATAM_North"} ,
    { label :"APAC" , value :"APAC"}
]

export const select_default_value_all = { label :"All" , value :"All"}

export const ted_periods =[
    { label :"All" , value :"All"},
    { label :"01-MAY-2023" , value :"01-MAY-2023"} ,
    { label :"01-APR-2023" , value :"01-APR-2023"} ,
    { label :"01-MAR-2023" , value :"01-MAR-2023"}
]

export const pre_check_status =[
    { label :"All" , value :"All"},
    { label :"Passed" , value :"Passed"} ,
    { label :"Failed" , value :"Failed"} 
]

export const etbOptions =[
    { label :"Pre-Tax/Post-WB" , value :"Pre-Tax/Post-WB"},
    { label :"Pre-Tax/Pre-WB" , value :"Pre-Tax/Pre-WB"} ,
    { label :"Post-Tax/Post-WB" , value :"Post-Tax/Post-WB"} 
]


export const ofaStage = { label: "PROD", value: "PROD" }

export const AWSoptions =[ {label: "Non-AWS-Only"}, {label: "AWS-Only" }, {label: "All" }]

export const reportsDashboardLink = {
    "beta":"https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/c32b20a9-a651-4d48-b5c2-54cf1625dc9b",
    "prod": ""
}
