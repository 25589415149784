import React, { useState, useEffect } from "react";
import { TEMPLATE_APP, QSC_APP_SPECIFIC_FIELDS, TEMPLATES } from "./Constants";

import { ADD_APP_ACCESS_LEVELS } from 'src/constants/AppConstants'

import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { getAccessLevel } from "src/checkPageAccess"
import ErrorComponent from "./components/ErrorComponent";
import { getChangeRequestTickets, createQSCAppChangeRequestTicket, getQSCApp } from "./Utils/AppActivityUtils"

import { API, graphqlOperation } from "aws-amplify";

import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  Box,
  Cards,
  Button,
  Header,
  Table,
  Badge,
  Autosuggest,
  ExpandableSection,
  SpaceBetween,
  Multiselect,
  ColumnLayout,
  Tabs,
  Pagination,
  Spinner,
  SegmentedControl,
  Select,
  Link,
  Wizard,
  FormField,
  Input,
  KeyValuePairs,
  Checkbox,
  Textarea,
  Grid,
  RadioGroup,
  Icon,
  Modal
} from "@amzn/awsui-components-react";
import SideNav from "./SideNav";

export default (props) => {

  const { id } = useParams()
  const userId = useSelector((globalState) => globalState.auth.userId);
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const [NavOpen, setNavOpen] = useState(true);
  const [qscAddAppAccess, setQscAddAppAccess] = useState()
  const [isQSCApp, setIsQSCApp] = useState()
  const [showLoadingIcon, setShowLoadingIcon] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [ticketDescription, setTicketDescription] = useState('')
  const [appTickets, setAppTickets] = useState([])
  const [appName, setAppName] = useState()
  const [submitModal, setSubmitModal] = useState(false)
  const [changeRequestTicketId, setChangeRequestTicketId] = useState()

  const [customSideNavitems, setCustomSideNavitems] = useState([
    {
      "type": "link",
      "text": "About",
      "href": `/App/${id}`
    },
    {
    "type": "link",
    "text": "Migration Details",
    "href": `/App/${id}/QSCAppMigrationDetails`
  },
  {
    "type": "link",
    "text": "Change Request Management",
    "href": `/App/${id}/QSCAppChangeRequest`
  },
   { "type": "divider" },

  {
    "type": "link",
    "text": "Back",
    "href": "/"
  },])

  useEffect(() => {
    checkAddQSCAppAccesses();
    checkIfAppTypeIsQSC();
    loadData()
  }, [id])


  useEffect(() => {
    checkAddQSCAppAccesses()
  }, [userId, currentStage])


  const checkAddQSCAppAccesses = async () => {
    if (userId && currentStage) {
      let qscAccess = await getAccessLevel(userId, TEMPLATES.QSC, currentStage)
      setQscAddAppAccess(ADD_APP_ACCESS_LEVELS.includes(qscAccess?.appAccessLevel))
    }

  }

  const loadData = async () => {
    const response = await getChangeRequestTickets(id)
    setAppTickets(response)
    const qscAppResponse = await getQSCApp(id)
    if (qscAppResponse?.data?.getQSCApp) {
      setAppName(qscAppResponse?.data?.getQSCApp[0]["app_name"])
    }
  }


  const checkIfAppTypeIsQSC = async () => {
    let mQry = `
    query MyQuery {
      getAppsV2 (ids:["${id}"]) {
          ${TEMPLATE_APP.TEMPLATE_ID}
      }
    }  
        `;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    if (qResultJSON?.length >= 1 && qResultJSON[0].hasOwnProperty(TEMPLATE_APP.TEMPLATE_ID) && qResultJSON[0][TEMPLATE_APP.TEMPLATE_ID] === TEMPLATES.QSC) {
      setIsQSCApp(true)
    } else {
      setIsQSCApp(false)
    }
  }
  useEffect(() => {
    setCustomSideNavitems([
      {
        "type": "link",
        "text": "About",
        "href": `/App/${id}`
      },
      {
        "type": "link",
        "text": "Migration Details",
        "href": `/App/${id}/QSCAppMigrationDetails`
      },
      {
        "type": "link",
        "text": "Change Request Management",
        "href": `/App/${id}/QSCAppChangeRequest`
      },
      
      { "type": "divider" },

      {
        "type": "link",
        "text": "Back",
        "href": "/"
      },

    ])
  }, [id])


  const createChangeRequestTicket = async () => {
    setShowLoadingIcon(true)
    if (ticketDescription && ticketDescription?.trim() !== '' && ticketDescription !== null && typeof (ticketDescription) !== 'undefined') {
      setErrorMessage()
      const res = await createQSCAppChangeRequestTicket(id, appName, userId, ticketDescription)
      if (res) {
        setChangeRequestTicketId(res)
        setTicketDescription('')
        setSubmitModal(true)
      }
    } else {
      setErrorMessage('Ticket Description is Required')
    }
    setShowLoadingIcon(false)
  }

  const content = (
    <>{showLoadingIcon ? (
      <Modal visible={showLoadingIcon}>
        <Box>
          <Spinner></Spinner>
          Loading...
        </Box>
      </Modal>
    ) : (
      <> {typeof (isQSCApp) === 'undefined' || typeof (qscAddAppAccess) === 'undefined' ?
        <LoadingSpinner></LoadingSpinner> :
        (isQSCApp === false) ? <><ErrorComponent header="Not a valid QSC App" message={`Please make sure the app id: ${id} in the URL is accurate`} /></> :
          (qscAddAppAccess === false) ? <><ErrorComponent header="Access Denied" message={`User not authorised to access the section`} /></> :
            (isQSCApp === true && qscAddAppAccess === true) ?
              <Tabs
                tabs={[
                  {
                    label: "Create Change Request",
                    id: "createChangeRequest",
                    content: (<Container
                    >
                      <SpaceBetween>
                        <div className="header_menu">
                          <div className="header_left">
                            <Box variant="h1">Create Change Request</Box>
                          </div>
                        </div>
                        <br></br>
                      </SpaceBetween>
                      <SpaceBetween size="l">

                        <Grid
                          gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                        >
                          <div> <Textarea
                            class="description"
                            onChange={({ detail }) => setTicketDescription(detail.value)}
                            value={ticketDescription}
                            placeholder="Ticket Description"
                          /> </div>
                          <div> <span className="mandatory">*</span> </div>
                        </Grid>

                        <Button variant="primary" onClick={createChangeRequestTicket} >Create Change Request</Button>
                        <Box>
                          {errorMessage && (
                            <Box variant="p" color="text-status-error">
                              {errorMessage}
                            </Box>
                          )}
                        </Box>
                      </SpaceBetween>

                      <Modal
                        onDismiss={() => {
                          setSubmitModal(false)
                          setChangeRequestTicketId()

                        }}
                        visible={submitModal}
                        footer={
                          <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                              <Button variant="primary" onClick={() => {
                                setSubmitModal(false)
                                setChangeRequestTicketId()
                              }}>Okay</Button>
                            </SpaceBetween>
                          </Box>
                        }
                        header="Change Request Created"
                      >
                        <Button
                          ariaLabel="Open corresponding ticket (opens new tab)"
                          iconAlign="right"
                          iconName="external"
                          target="_blank" href={`https://t.corp.amazon.com/${changeRequestTicketId}`} variant="link">
                          Open Ticket
                        </Button>
                      </Modal>

                    </Container>)
                  },
                  {
                    label: "Previous Change Requests",
                    id: "previousChangeRequests",
                    content: (<Table
                      renderAriaLive={({
                        firstIndex,
                        lastIndex,
                        totalItemsCount
                      }) =>
                        `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                      }
                      columnDefinitions={[
                        {
                          id: "ticket_type",
                          header: "Ticket Type",
                          cell: item => (
                            item.ticket_type || '-'
                          ),

                          isRowHeader: true
                        },
                        {
                          id: "created_by",
                          header: "Created By",
                          cell: item => item.created_by || "-"
                        },
                        {
                          id: "created_timestamp",
                          header: "Created Timestamp",
                          cell: item => item.created_timestamp || "-"
                        },
                        {
                          id: "ticket", header: "Ticket", cell: e => (e.ticket_id) ? <Box textAlign="left">
                            <Button
                              ariaLabel="Open corresponding ticket (opens new tab)"
                              iconAlign="right"
                              iconName="external"
                              target="_blank" href={`https://t.corp.amazon.com/${e.ticket_id}`} variant="link">
                              Open Ticket
                            </Button></Box> : <Box textAlign="left">Not Applicable</Box>
                        }
                      ]}
                      enableKeyboardNavigation
                      items={appTickets}
                      loadingText="Loading Change Requests"
                      sortingDisabled
                      empty={
                        <Box
                          margin={{ vertical: "xs" }}
                          textAlign="center"
                          color="inherit"
                        >
                          <SpaceBetween size="m">
                            <b>No Change Requests</b>
                          </SpaceBetween>
                        </Box>
                      }
                    />)
                  }
                ]}
              />
              : <></>
      }
      </>
    )}
    </>
  )


  return (
    <AppLayout
      disableContentPaddings={false}
      navigation={<SideNav activeHref={`/App/${id}/QSCAppChangeRequest`} id={id} applicationName={appName} customSideNavitems={customSideNavitems} />}
      navigationOpen={NavOpen}
      content={content}
      headerSelector="#TopBar"
      toolsHide={true}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      headerVariant="high-contrast"
    />

  );
}
