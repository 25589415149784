import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  ExpandableSection,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
  Table,
  Input,
  Select
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";
import './triggereventlogs.scss';
import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import { select_default_value_all } from "./Constants";
import { setFilterOptions, setFilterOptionsWithAll } from "./FilterUtilities"
import { setCurrentAppId } from "../../store/userAuthSlice";
import { constructExcelSheetWithHeadersAndData } from "../Templates/Utils/AppActivityUtils";



const TriggerEventLogs = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const [items, setItems] = useState([]);
  const [processingStatus, setProcessingStatus] = useState("info");
  const [currentStatusId, setCurrentStatusId] = useState("");

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const [currentId, setCurrentId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);

  const [retriggers, setRetriggerSections] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [previewErrorMessage, setPreviewErrorMessage] = useState('No preview available');
  const [toolsHide, setToolsHide] = useState(true);
  const [retriggerErrorMessage, setReTriggerErrorMessage] = useState('');
  const [retriggerSuccessMessage, setRetriggerSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filterRegion, setFilterRegion] = useState(select_default_value_all);
  const [filterPreChecks, setFilterPreChecks] = useState(select_default_value_all);
  const [filterEntity, setFilterEntity] = useState('');
  const [filterPeriod, setFilterPeriod] = useState(select_default_value_all);
  const [filterRegionListWithAll, setFilterRegionListWithAll] = useState([]);
  const [filterPreChecksListWithAll, setFilterPreChecksListWithAll] = useState([]);
  const [filterPeriodListWithAll, setFilterPeriodListWithAll] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false)
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );

  useEffect(() => {
    
    loadData();


    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);


    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const preCheckIcon = props => (
    props.data.pre_check_status === 'Passed' ?
      <Box>
        <Icon name='status-positive' variant='success' ></Icon>
      </Box>

      : props.data.pre_check_status === 'Failed' ? <Box>
        <Icon name='status-negative' variant='warning' ></Icon>
      </Box>
        : <span>{props.data.pre_check_status}</span>

  )

  const [columnDefs] = useState([
    {
      field: "Open",
      width: "50px",
      resizable: true,
      cellRenderer: "agGroupCellRenderer",
      sortable: false,
      filter: false,
      checkboxSelection: true,
      headerName: "",
      checkboxSelection: true, 
      headerCheckboxSelection: true, 
      headerCheckboxSelectionFilteredOnly:true ,
      pinned: 'left'
    },
    // { field: "batch_id", width: "100px", resizable: true, headerName: "Batch" },
    { field: "region", width: "100px", resizable: true, headerName: "Region" },
    { field: "entity", width: "100px", resizable: true, headerName: "Entity" },
    { field: "report_period", width: "100px", resizable: true, headerName: "Period" },
    { field: "etb_version", width: "150px", resizable: true, headerName: "Version" },
    { field: "pre_check_status", width: "120px", resizable: true, headerName: "Pre-Checks", cellRenderer: preCheckIcon },
    { field: "created_time", width: "300px", resizable: true, headerName: "Processed Date" },
    { field: "trigger_request_type", width: "100px", resizable: true, headerName: "Request" },
    { field: "status", width: "150px", resizable: true, headerName: "Status" },
    { field: "retrigger_status", width: "150px", resizable: true, headerName: "Retrigger Status" },
    { field: "retrigger_by", width: "150px", resizable: true, headerName: "Retrigger By" },
    { field: "retrigger_request_time", width: "150px", resizable: true, headerName: "Retrigger Request" }
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const loadPreviewData = async (selectedRow) => {
    setShowLoadingIcon(true);
    var mQry = `query MyQuery {
      listEntityRecentLogs(entity: "`+ selectedRow?.entity + `") {
        batch_id
        entity
        region
        report_period
        report_month
        trigger_day
        trigger_time_pst
        etb_version
        actual_trigger_day
        effective_trigger_day
        status
        trigger_request_type
        local_gaap_etb_total
        us_gaap_etb_total
        lg_etb_total
        transformation_time
        transformation_status
        pre_check_status
        created_time
        updated_time
        retrigger_request_time
        retrigger_status
        retrigger_by
        created_by
        updated_by
      }
    }`;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.listEntityRecentLogs;
    setPreviewData(qResultJSON)
    setShowLoadingIcon(false);
  }

  const resetPreview = (message) => {
    setPreviewErrorMessage(message)
    setPreviewData(null)
  }
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedItems(selectedRows);
    if (selectedRows.length > 0) {
      setCurrentId(selectedRows[0].id);
    }
    if (selectedRows?.length == 1) {
      // load preview data
      loadPreviewData(selectedRows[0])
    } else if (selectedRows?.length > 1) {
      resetPreview('More than one row is selected.')
    } else {
      resetPreview('No preview available.')
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "TED", href: "/TEDHome" },
    { text: "Review", href: "" },
  ];

  // TEMP : Main items is temporarily added, until the filter is implmented on cloud
  const filterItems = (itemsToFilter) => {
    let filtersApplied = getAppliedFiltersMap()
    Object.keys(filtersApplied).forEach(key => {
      if (filtersApplied[key] !== 'All') {
        itemsToFilter = itemsToFilter.filter(d => d[key] === filtersApplied[key]);
      }
    })
    return itemsToFilter
  }

  // TEMP : Main items is temporarily added, until the filter is implmented on cloud
  const getAppliedFiltersMap = () => {
    let filtersApplied = {}
    if (filterRegion && filterRegion?.value) {
      filtersApplied['region'] = filterRegion?.value
    }
    if (filterPreChecks && filterPreChecks?.value) {
      filtersApplied['pre_check_status'] = filterPreChecks?.value
    }
    if (filterPeriod && filterPeriod?.value) {
      filtersApplied['report_period'] = filterPeriod?.value
    }
    if (filterEntity) {
      filtersApplied['entity'] = filterEntity
    }
    return filtersApplied
  }

  const getAppliedFiltersArray = () => {
    let filtersApplied = []
    if (filterRegion && filterRegion?.value) {
      filtersApplied.push(`region: "` + filterRegion?.value + `"`)
    }
    if (filterPreChecks && filterPreChecks?.value) {
      filtersApplied.push(`pre_check_status: "` + filterPreChecks?.value + `"`)
    }
    if (filterPeriod && filterPeriod?.value) {
      filtersApplied.push(`report_period: "` + filterPeriod?.value + `"`)
    }
    filtersApplied.push(`entity: "` + filterEntity + `"`)
    return filtersApplied
  }

  const getFiltersQueryString = () => {
    let filtersApplied = getAppliedFiltersArray()
    let filterQuery = ''
    if (filtersApplied && filtersApplied.length > 0) {
      filterQuery = `(` + filtersApplied.join(", ") + `)`
    }
    return filterQuery
  }
  const loadData = async () => {
    setShowLoadingIcon(true);
    let filterQry = getFiltersQueryString()
    if (showAllItems == false) {
      var mQry =
        `query MyQuery{
          listActivePeriodLogs `+ filterQry + `{
            batch_id
            entity
            region
            report_period
            report_month
            trigger_day
            trigger_time_pst
            etb_version
            actual_trigger_day
            effective_trigger_day
            status
            trigger_request_type
            local_gaap_etb_total
            us_gaap_etb_total
            lg_etb_total
            transformation_time
            transformation_status
            pre_check_status
            created_time
            updated_time
            retrigger_request_time
            retrigger_status
            retrigger_by
            created_by
            updated_by
            log_id
          }
        }`;
    }
    if (showAllItems) {
      var mQry = `query MyQuery { 
        listActivePeriodLogs{
          batch_id
          entity
          region
          report_period
          report_month
          trigger_day
          trigger_time_pst
          etb_version
          actual_trigger_day
          effective_trigger_day
          status
          trigger_request_type
          local_gaap_etb_total
          us_gaap_etb_total
          lg_etb_total
          transformation_time
          transformation_status
          pre_check_status
          created_time
          updated_time
          retrigger_request_time
          retrigger_status
          retrigger_by
          created_by
          updated_by
          log_id
        }
      }`;
    }

    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.listActivePeriodLogs;
      // load filters from data only when we have are fetching all the data - not on every load, since some loads can be after applying filters
      if (filterRegion === select_default_value_all) {
        setFilterRegionListWithAll(setFilterOptionsWithAll(qResultJSON, 'region'))
      }
      if (filterPreChecks === select_default_value_all) {
        setFilterPreChecksListWithAll(setFilterOptionsWithAll(qResultJSON, 'pre_check_status'))
      }
      if (filterPeriod === select_default_value_all) {
        setFilterPeriodListWithAll(setFilterOptionsWithAll(qResultJSON, 'report_period'))
      }
      setItems(qResultJSON);
      setShowLoadingIcon(false);
      setErrorMessage("")
    }, function (error) {
      if (filterQry && filterQry.length > 0) {
        setItems(filterItems(mainItems))
      }
      setShowLoadingIcon(false);
      setErrorMessage(error?.errors[0]?.message)
    });


  };

  const getSelectedLogIds = () => {
    var result = []
    selectedItems.forEach(item => {
      // result.push({log_id: item?.log_id})
      result.push(`{log_id: "` + item?.log_id + `"}`)
    });
    return result
  }

  const retrigger = () => {
    clearRetriggerMessages()
    if (!selectedItems || selectedItems.length == 0) {
      setReTriggerErrorMessage('Select atleast one row to re-trigger')
      return
    }
    let mQry = ''
    if (selectedItems && selectedItems.length == 1) {
      mQry = `mutation MyMutation {
        updateRetriggerLogsSingle(log_id: "`+ selectedItems[0]?.log_id.toString() + `", user_id: "` + userId + `") {
          message
        }
      }
`
    } else {
      let logIdArray = getSelectedLogIds()
      mQry = `
      mutation MyMutation {
        updateReTriggerLogs(log_ids: [` + logIdArray.join(",") + `], user_id: "` + userId + `") {
          message
        }
      }`;
    }

    API.graphql(graphqlOperation(mQry)).then(result => {
      clearRetriggerMessages()
      if (result?.data?.updateRetriggerLogsSingle?.message?.toLowerCase() === 'success') {
        setRetriggerSuccessMessage(`re-trigger initiated successfully`)
      } else if (result?.data?.updateReTriggerLogs?.message?.toLowerCase() === 'success') {
        setRetriggerSuccessMessage(`re-trigger initiated successfully`)
      } else {
        setReTriggerErrorMessage(`re-trigger failed`)
      }
      loadData()
      setSelectedItems([])
    }, function (error) {
      setReTriggerErrorMessage(error?.errors[0]?.message)
    });
  }
  const clearRetriggerMessages = () => {
    setReTriggerErrorMessage()
    setRetriggerSuccessMessage()
  }
  const clearRetriggerEntries = () => {
    clearRetriggerMessages()
    setSelectedItems([])
    gridRef.current.api.forEachNode(node => node.setSelected(false));
  }

  const highlightRed = props => (
    props.data.pre_check_status === 'Passed' ?
      <span>{props.data.local_gaap_etb_total}</span> :
      <span style={{ color: 'red' }}>{props.data.local_gaap_etb_total}</span>
  )


  const downloadFilteredData = async () => {
      setDownloadLoading(true)
      let downloadResults = []
     
              const headers = [
                "Region",
                "Entity" ,
                "Period" ,
                "Version",
                "Pre-Checks",
                "Processed date",
                "Request",
                "Status",
                "Retrigger Status",
                "Retrigger By",
                "Retrigger Request"
              ]
      downloadResults = items?.map((item) => {
        return {
          "Region" : item.region,
          "Entity" : item.entity,
          "Period" : item.report_period,
          "Version": item.etb_version,
          "Pre-Checks": item.pre_check_status,
          "Processed date": item.created_time,
          "Request": item.trigger_request_type,
          "Status": item.status,
          "Retrigger Status": item.retrigger_status,
          "Retrigger By": item.retrigger_by,
          "Retrigger Request": item.retrigger_request_time
        }
      })
      constructExcelSheetWithHeadersAndData(headers, downloadResults, "TEDTriggerEventLogsFiltered")           
  
      setDownloadLoading(false)
    }

  const detailView = (
    <Box>
      {previewData ? (

        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: "100%" }}
        >
          <AgGridReact
            columnDefs={[
              { field: "batch_id", width: "100px", resizable: true, headerName: "Batch ID" },
              { field: "entity", width: "100px", resizable: true, headerName: "Entity" },
              { field: "region", width: "100px", resizable: true, headerName: "Region" },
              { field: "report_period", width: "150px", resizable: true, headerName: "Report period" },
              { field: "report_month", width: "150px", resizable: true, headerName: "Report month" },
              { field: "trigger_day", width: "100px", resizable: true, headerName: "Trigger day" },
              { field: "trigger_time_pst", width: "150px", resizable: true, headerName: "Trigger time (PST)" },
              { field: "etb_version", width: "150px", resizable: true, headerName: "ETB version" },
              { field: "actual_trigger_day", width: "150px", resizable: true, headerName: "Actual trigger day" },
              { field: "effective_trigger_day", width: "200px", resizable: true, headerName: "Effective trigger day" },
              { field: "status", width: "150px", resizable: true, headerName: "Status" },
              { field: "trigger_request_type", width: "150px", resizable: true, headerName: "Trigger request type" },
              { field: "local_gaap_etb_total", width: "200px", resizable: true, headerName: "Local GAAP ETB", cellRenderer: highlightRed },
              { field: "us_gaap_etb_total", width: "200px", resizable: true, headerName: "US GAAP ETB", cellRenderer: highlightRed },
              { field: "lg_etb_total", width: "200px", resizable: true, headerName: "LG ETB", cellRenderer: highlightRed },
              { field: "transformation_time", width: "150px", resizable: true, headerName: "Transformation time" },
              { field: "transformation_status", width: "150px", resizable: true, headerName: "Transformation status" },
              { field: "pre_check_status", width: "150px", resizable: true, headerName: "Pre check status" },
              { field: "created_time", width: "200px", resizable: true, headerName: "Created time" },
              { field: "updated_time", width: "200px", resizable: true, headerName: "Updated time" },
              { field: "retrigger_request_time", width: "200px", resizable: true, headerName: "Retrigger request time" },
              { field: "retrigger_status", width: "150px", resizable: true, headerName: "Retrigger status" },
              { field: "retrigger_by", width: "150px", resizable: true, headerName: "Retrigger by" },
              { field: "created_by", width: "150px", resizable: true, headerName: "Created by" },
              { field: "updated_by", width: "150px", resizable: true, headerName: "Updated by" },
            ]}

            rowData={previewData}
            animateRows={true}
            rowHeight={30}
          ></AgGridReact>
        </div>
      ) : (
        <Box variant="p" color="text-status-error">
          {previewErrorMessage}
        </Box>
      )}
    </Box>
  );

  const resetFilter = () => {
    setFilterRegion(select_default_value_all)
    setFilterPreChecks(select_default_value_all)
    setFilterPeriod(select_default_value_all)
    setErrorMessage('')
    setFilterEntity('')
  }

  const content = (
    <SpaceBetween direction="vertical" size="m">
      {isAuthorized ? (
        <Box>
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}

                    <Button
                    iconAlign="right"
                    iconName="download"
                    onClick={downloadFilteredData}
                    loading={downloadLoading}
                    >
                    export filtered results
                    </Button> 

                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >

                    </Button>
                  </SpaceBetween>
                }
              >
                Entity ETB Trigger Status
              </Header>
            }
          >
            <div
              className="ag-theme-alpine"
              style={{ height: 150, width: "100%" }}
            >
              <Box>
                <ColumnLayout columns={4}>
                  <Box>
                    <Box variant='awsui-key-label'>Region</Box>
                    <Select selectedOption={filterRegion} options={filterRegionListWithAll} onChange={({ detail }) => { setFilterRegion(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Pre-checks</Box>
                    <Select selectedOption={filterPreChecks} options={filterPreChecksListWithAll} onChange={({ detail }) => { setFilterPreChecks(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Period</Box>
                    <Select selectedOption={filterPeriod} options={filterPeriodListWithAll} onChange={({ detail }) => { setFilterPeriod(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Entity</Box>
                    <Input value={filterEntity} onChange={({ detail }) => setFilterEntity(detail.value)} ></Input>
                  </Box>
                  <Box textAlign="right">

                  </Box>
                  <Box></Box>
                </ColumnLayout>
                <SpaceBetween size='m' direction='horizontal'>
                  <Button variant='normal' onClick={resetFilter}>Reset</Button>
                  <Button variant='primary' onClick={loadData}>Filter</Button>
                </SpaceBetween>
              </Box>

              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={items}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="multiple"
                animateRows={true}
                rowHeight={30}
                checkboxSelection={true}
                onSelectionChanged={onSelectionChanged}
                selectedItems={selectedItems}
                rowMultiSelectWithClick={true}
                enableCellTextSelection={true}
              ></AgGridReact>
            </div>
          </Container>
          <p></p>

          <p></p>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Re-trigger ETB version</h2>
          {selectedItems.length == 0 ? (
            <small>Select a mapping to update/delete</small>
          ) : (
            <div></div>
          )}
        </div>
      }
    >
      <div>

        <p>What the icons mean?</p>
        <p><Icon name="status-negative" variant='warning'></Icon> = Failed</p>
        <p><Icon name='status-positive' variant="success"></Icon> = Passed</p>
        <p></p>
        <br></br>
      </div>

      <div data-awsui-column-layout-root="true">

        <Table
          columnDefinitions={[
            {
              id: "item_entity",
              header: "Entity",
              cell: item => item.entity
            },
            {
              id: "item_etb_version",
              header: "Etb Version",
              cell: item => item.etb_version
            },
            {
              id: "item_report_period",
              header: "Period",
              cell: item => item.report_period
            }
          ]}
          items={selectedItems}
          loadingText="Loading resources"
          sortingDisabled
        />
      </div>
      <br></br>
      <SpaceBetween size='m' direction='horizontal'>
        <Button variant='normal' onClick={clearRetriggerEntries}>Clear</Button>
        <Button variant='primary' onClick={retrigger}>Re-trigger</Button>
      </SpaceBetween>
      {retriggerSuccessMessage && (
        <Box variant="p" color="text-status-success">
          {retriggerSuccessMessage}
        </Box>
      )}
      {retriggerErrorMessage && (
        <Box variant="p" color="text-status-error">
          {retriggerErrorMessage}
        </Box>
      )}

    </HelpPanel>
  );
  const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: "Split panel preferences",
    preferencesPositionLabel: "Split panel position",
    preferencesPositionDescription:
      "Choose the default split panel position for the service.",
    preferencesPositionSide: "Side",
    preferencesPositionBottom: "Side",
    preferencesConfirm: "Confirm",
    preferencesCancel: "Cancel",
    closeButtonAriaLabel: "Close panel",
    openButtonAriaLabel: "Open panel",
    resizeHandleAriaLabel: "Resize split panel",
  };
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/TriggerEventLogs" />}
      headerSelector="#TopBar"
      navigationHide={false}
      splitPanel={
        <SplitPanel
          closeBehavior="collapse"
          header="Click here to view the selected entity trigger data"
          i18nStrings={SPLIT_PANEL_I18NSTRINGS}
        >
          {detailView}
        </SplitPanel>
      }
      tools={helpContent}
      toolsOpen={toolsHide}
      onToolsChange={() => { setToolsHide(!toolsHide) }}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default TriggerEventLogs;
