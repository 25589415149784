import { API, graphqlOperation } from "aws-amplify";
import { configurePlusAppsync, configureGenericAppSync } from 'src/context/AuthContextUtility';



export const getDataFromMetaByAppId = async (appId) => {

    await configurePlusAppsync()
    let metaData;
    let qry = `query MyQuery {
        getAbcAppMetadata(app_id: "${appId}")
    }
      `
    let response = await API.graphql(graphqlOperation(qry)).then((result) => {
        metaData = result.data.getAbcAppMetadata
        metaData = JSON.parse(metaData)
        configureGenericAppSync()

    });
    await configureGenericAppSync()
    return metaData

}
