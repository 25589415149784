import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";

import { API, graphqlOperation } from 'aws-amplify';
import {mapper, APP_ACCESS_LEVEL, ACCESS_LEVEL_IDs} from './constants/AppConstants'

function checkAuth(authorizedGroups, userLDAPS){
  let result = false
  const authorized =  authorizedGroups.find((group) => (
    userLDAPS.includes(group)
 ))
  if(authorized) {
    result = true;
  }
  return result
}

export const isMemberOf = async (userId, ldap, stage) => {
  stage = (stage === 'dev' ? 'beta' : stage)
  let mQry = `query MyQuery {
    getBrassIsMemberOf(stage: "${stage}", ldap_group: "${ldap}")
  }
  `
  let response = await API.graphql(graphqlOperation(mQry));
    let brassResponse = response.data.getBrassIsMemberOf;
  
  return brassResponse;
}


const checkAccess = async (userAlias, id, accessLevelId, stage, key = undefined) => {
  stage = (stage === 'dev' ? 'beta' : stage)
  let mQry;

  if(key === undefined) {
    mQry =
      `  query MyQuery {
        brassCheckAccess(id: "${id}", accessLevelId: ${accessLevelId},  stage: "${stage}") {
          is_authorized
          optional_data
        }
      }
    `;
  } else {
    mQry =
    `  query MyQuery {
      brassCheckAccess(id: "${id}", accessLevelId: ${accessLevelId},  stage: "${stage}", key: "${key}") {
        is_authorized
        optional_data
      }
    }
  `;
  }

    let response = await API.graphql(graphqlOperation(mQry));
    let brassResponse = response.data.brassCheckAccess;
  
  return brassResponse;
}

export const checkGeneralAccess = async ({userAlias, id, stage}) => {

  const response = await checkAccess(userAlias, id, ACCESS_LEVEL_IDs.APP_GENERAL_ACCESS, stage)
  return response["is_authorized"];

}

export const checkAppAdminAccess = async ({userAlias, id, stage}) => {

  const response = await checkAccess(userAlias, id, ACCESS_LEVEL_IDs.APP_ADMIN, stage)
  return response["is_authorized"];

}

// Data in optionalAccessLevelIds is ranked (Whichever comes first has higher presidence over the rest)
export const getAccessLevel = async (userAlias, id, stage, optionalAccessLevelIds = []) => {

  const accessLevelResponse = {
    appAccessLevel: ACCESS_LEVEL_IDs.NO_ACCESS,
    optionalData: null
  }
  
  //App Admin
  const appAdminResponse = await checkAccess(userAlias, id, ACCESS_LEVEL_IDs.APP_ADMIN, stage)
  if(appAdminResponse["is_authorized"]) {
    accessLevelResponse["appAccessLevel"] = ACCESS_LEVEL_IDs.APP_ADMIN
    return accessLevelResponse
  }

  //Full Edit
  const fullEditResponse = await checkAccess(userAlias, id, ACCESS_LEVEL_IDs.FULL_EDIT, stage)
  if(fullEditResponse["is_authorized"]) {
    accessLevelResponse["appAccessLevel"] = ACCESS_LEVEL_IDs.FULL_EDIT
    return accessLevelResponse
  }

  // Partial Edit
  for (let accessLevelId of optionalAccessLevelIds) {
    const response = await checkAccess(userAlias, id, accessLevelId, stage)
    if(response["is_authorized"]) {
      accessLevelResponse["appAccessLevel"] = ACCESS_LEVEL_IDs.PARTIAL_EDIT
      accessLevelResponse["optionalData"] = response["optional_data"]
      return accessLevelResponse
    }
  }

  //Read Only
  const readOnlyResponse = await checkAccess(userAlias, id, ACCESS_LEVEL_IDs.READ_ONLY, stage)
  if(readOnlyResponse["is_authorized"]) {
    accessLevelResponse["appAccessLevel"] = ACCESS_LEVEL_IDs.READ_ONLY
    return accessLevelResponse
  }

  return accessLevelResponse;

}


export const getAccessLevelCorpReporting = async (userAlias, appId, stage) => {

  const accessLevelResponse = {
    appAccessLevel: ACCESS_LEVEL_IDs.NO_ACCESS,
    optionalData: null
  }
  
  //App Admin
  const appAdminResponse = await checkAccess(userAlias, appId, ACCESS_LEVEL_IDs.APP_ADMIN, stage)
  if(appAdminResponse["is_authorized"]) {
    accessLevelResponse["appAccessLevel"] = ACCESS_LEVEL_IDs.APP_ADMIN
    return accessLevelResponse
  }


  return accessLevelResponse;

}

export const checkAppFunctionalityAccess = async ({userAlias, id, accessLevelId, stage, key}) => {
  
  const response = await checkAccess(userAlias, id, accessLevelId, stage, key)
  return response["is_authorized"];

}

export const checkIfPortalAdmin = async (userAlias, stage) => {
  const response = await checkAccess(userAlias, "ABC_ADMIN", ACCESS_LEVEL_IDs.PORTAL_ADMIN, stage)
  return response["is_authorized"];
}

export const checkIfSupportAdmin = async (userAlias, stage) => {
  const response = await checkAccess(userAlias, "SUPPORT_TEAM_ADMIN", ACCESS_LEVEL_IDs.APP_ADMIN, stage)
  return response["is_authorized"];
}

async function checkPageAccess(AppId, userLDAPS) {

  let result = true;
     
  if(AppId === mapper?.boffin?.id) {
    result = checkAuth(mapper.boffin.groups, userLDAPS)
  }else if(AppId ===  mapper?.mjemonitoring?.id) {
    result = checkAuth(mapper.mjemonitoring.groups, userLDAPS)
}

// if(AppId ===  mapper?.ted?.id) {
  //   result = checkAuth(mapper.ted.groups, userLDAPS)
  //  }
 
  return result;
}



export default checkPageAccess