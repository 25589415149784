import React, { useState, useEffect } from "react";


import "../../index.scss";
import { useSelector, useDispatch } from "react-redux";

import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import { useHistory, useParams } from "react-router-dom";

import { API, graphqlOperation } from "aws-amplify";
import Template from '../Templates/Template';
import { TEMPLATES } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from "../Templates/TemplateMaple";
import AppTemplate from "./AppTemplate";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let {id} = useParams();
  const [customSideNavitems, setCustomSideNavitems] = useState([
    {
        "type": "link",
        "text": "About",
        "href": `/App/${id}`
    },

    { "type": "divider" },

    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },
    ])

  useEffect(() => {
    
    window.scrollTo(0, 0)
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  return (
    <AppTemplate template_id={TEMPLATES.MAPLE} renderJson={{template_id:TEMPLATES.MAPLE}}/>
  );
};
