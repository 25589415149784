import 'ag-grid-enterprise';
import {  LicenseManager } from "ag-grid-enterprise";
import { retrieveAGGridLicenseKey } from './AWSServices';

export const AgGridLicenseKeyParameterStoreKey = '/abc/ui/aggrid/license';
export const configureAgGridLicense = async (): Promise<string> => {
  let licenseKey =(await retrieveAGGridLicenseKey()).SecretString || '';
  if (licenseKey) {
    // ModuleRegistry.registerModules([CsvExportModule]);
    LicenseManager.setLicenseKey(JSON.parse(licenseKey)["aggrid-license"]);
    return new Promise((resolve) => resolve('setLicenseKey_Success'));
  } else {
    return new Promise((reject) => reject('setLicenseKey_Failed'));
  }
};