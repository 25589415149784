import React, {  useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";



const AppRedirect = (props) => {
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {

        history.push(`/App/${id}`)
        return () => {
        };
    }, []);
    return (
        <></>
    )
}
export default AppRedirect

