import { SecretKeyConfig, graphQLConfig,s3Config, APIGateWayConfig, QuickSightDashboardConfig, GlueJobConfig, StepFunctionConfig} from 'src/context/AppContextModels';

export const APP_TITLE = 'Accounting Central';

export const eLDAPGroup = {
  ADMIN_LDAP: 'ateam-bi',
  DEV_LDAP: 'ateam-bi'
};

export const LDAP_ADMINS: string[] = ['ateam-bi'];

export const SECRET_KEY_ARN_CONFIG: SecretKeyConfig[] = [
  {
    environment: 'dev',
    secretARN: 'arn:aws:secretsmanager:us-west-2:330801203043:secret:accounting-bi-central-appSync-APIKey-beta-KHdswc'
  },
  {
    environment: 'beta',
    secretARN: 'arn:aws:secretsmanager:us-west-2:330801203043:secret:accounting-bi-central-appSync-APIKey-beta-KHdswc'
  },
  {
    environment: 'prod',
    secretARN: 'arn:aws:secretsmanager:us-west-2:359182851103:secret:accounting-bi-central-appSync-APIKey-prod-4oNu4S'
  }
];

export const GRAPHQL_CONFIG: graphQLConfig[] =  [
  {
    environment: 'dev',
    secretName : 'accounting-bi-central-appSync-APIKey-beta',
    grapQLEndPoint : 'https://efkypdtrnne37djo7ryeqlbwqm.appsync-api.us-east-1.amazonaws.com/graphql'
  },

  {
    environment: 'beta',
    secretName : 'accounting-bi-central-appSync-APIKey-beta',
    grapQLEndPoint : 'https://efkypdtrnne37djo7ryeqlbwqm.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  {
    environment: 'prod',
    secretName : 'accounting-bi-central-appSync-APIKey-prod',
    grapQLEndPoint : 'https://mgu5jvaqbjh5xntepue27znrhm.appsync-api.us-east-1.amazonaws.com/graphql',
  }
];

export const MJE_GRAPHQL_CONFIG: graphQLConfig[] =  [
  {
    environment: 'dev',
    secretName : 'accounting-bi-central-appSync-APIKey-beta',
    grapQLEndPoint : 'https://efkypdtrnne37djo7ryeqlbwqm.appsync-api.us-east-1.amazonaws.com/graphql'
  },

  {
    environment: 'beta',
    secretName : 'accounting-bi-central-appSync-APIKey-beta',
    grapQLEndPoint : 'https://efkypdtrnne37djo7ryeqlbwqm.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  {
    environment: 'prod',
    secretName : 'accounting-bi-central-appSync-APIKey-prod',
    grapQLEndPoint : 'https://mgu5jvaqbjh5xntepue27znrhm.appsync-api.us-east-1.amazonaws.com/graphql',
  }
];

export const FLEXICO_GRAPHQL_CONFIG: graphQLConfig[] =  [
  {
    environment: 'dev',
    secretName : '',
    grapQLEndPoint : 'https://cmw7t4kaavexzad62kmwmecvlu.appsync-api.us-east-1.amazonaws.com/graphql'
  },

  {
    environment: 'beta',
    secretName : '',
    grapQLEndPoint : 'https://cmw7t4kaavexzad62kmwmecvlu.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  {
    environment: 'prod',
    secretName : '',
    grapQLEndPoint : 'https://2m7fqxuva5fwxly67eyywwhww4.appsync-api.us-east-1.amazonaws.com/graphql',
  }
];

export const APPSYNC_PLUS_GRAPHQL_CONFIG: graphQLConfig[] =  [
  {
    environment: 'dev',
    secretName : '',
    grapQLEndPoint : 'https://7sapniobunfotk7xndfciqgdqe.appsync-api.us-east-1.amazonaws.com/graphql'
  },

  {
    environment: 'beta',
    secretName : '',
    grapQLEndPoint : 'https://7sapniobunfotk7xndfciqgdqe.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  {
    environment: 'prod',
    secretName : '',
    grapQLEndPoint : 'https://ojlqwlgjufc4nhzbgamqfu7fvu.appsync-api.us-east-1.amazonaws.com/graphql',
  }
];

export const S3_CONFIG: s3Config[] =  [
  {
    environment: 'dev',
    bucket : 'ui4bi-bucket84824-dev',
    region : 'us-east-1'
  },

  {
    environment: 'beta',
    bucket : 'ui4bi-bucket84824-dev',
    region : 'us-east-1'
  },
  {
    environment: 'prod',
    bucket : 'ui4bi-prod-bucket164517-dev',
    region : 'us-east-1',
  }
];

export const MJE_S3_CONFIG: s3Config[] =  [
  {
    environment: 'dev',
    bucket : 'ui4bi-bucket84824-dev',
    region : 'us-east-1'
  },

  {
    environment: 'beta',
    bucket : 'ui4bi-bucket84824-dev',
    region : 'us-east-1'
  },
  {
    environment: 'prod',
    bucket : 'ui4bi-bucket-prod140700-dev',
    region : 'us-east-1',
  }
];

export const FRISCO_S3_CONFIG: s3Config[] =  [
  {
    environment: 'dev',
    bucket : 'beta-frisco',
    region : 'us-east-1'
  },

  {
    environment: 'beta',
    bucket : 'beta-frisco',
    region : 'us-east-1'
  },
  {
    environment: 'prod',
    bucket : 'prod-frisco',
    region : 'us-east-1',
  }
];

export const LOGICO_S3_CONFIG: s3Config[] =  [
  {
    environment: 'dev',
    bucket : 'beta-logico',
    region : 'us-east-1'
  },

  {
    environment: 'beta',
    bucket : 'beta-logico',
    region : 'us-east-1'
  },
  {
    environment: 'prod',
    bucket : 'prod-logico',
    region : 'us-east-1',
  }
];

export const FLEXICO_S3_CONFIG: s3Config[] =  [
  {
    environment: 'dev',
    bucket : 'beta-flexico',
    region : 'us-east-1'
  },

  {
    environment: 'beta',
    bucket : 'beta-flexico',
    region : 'us-east-1'
  },
  {
    environment: 'prod',
    bucket : 'prod-flexico',
    region : 'us-east-1',
  }
];

export const APIGATEWAY_CONFIG: APIGateWayConfig[] =  [
  {
    environment: 'dev',
    ApiGateWayEndpoint : 'https://fem33hb4ae.execute-api.us-east-1.amazonaws.com/Beta',
  },

  {
    environment: 'beta',
    ApiGateWayEndpoint : 'https://fem33hb4ae.execute-api.us-east-1.amazonaws.com/Beta',
  },
  {
    environment: 'prod',
    ApiGateWayEndpoint : 'https://evpyxp1v3m.execute-api.us-east-1.amazonaws.com/ProdRed',
  }
];

export const QUICKSIGHT_DASHBOARD_CONFIG:QuickSightDashboardConfig[] = [
  {
    environment: 'dev',
    url : 'https://jfx11eme7b.execute-api.us-east-1.amazonaws.com/beta/get_embed_url',
  },

  {
    environment: 'beta',
    url : 'https://jfx11eme7b.execute-api.us-east-1.amazonaws.com/beta/get_embed_url',
  },
  {
    environment: 'prod',
    url : '',
  }
]

export const STEP_FUNCTION_CONFIG:StepFunctionConfig[] = [
  {
    environment: 'dev',
    arn : 'arn:aws:states:us-east-1:330801203043:stateMachine:FRISCOTransactions',
  },

  {
    environment: 'beta',
    arn : 'arn:aws:states:us-east-1:330801203043:stateMachine:FRISCOTransactions',
  },
  {
    environment: 'prod',
    arn : 'arn:aws:states:us-east-1:359182851103:stateMachine:FRISCOTransactions',
  }
]

export const FLEXICO_STEP_FUNCTION_CONFIG:StepFunctionConfig[] = [
  {
    environment: 'dev',
    arn : 'arn:aws:states:us-east-1:330801203043:stateMachine:FlexicoTransactions',
  },

  {
    environment: 'beta',
    arn : 'arn:aws:states:us-east-1:330801203043:stateMachine:FlexicoTransactions',
  },
  {
    environment: 'prod',
    arn : 'arn:aws:states:us-east-1:359182851103:stateMachine:FlexicoTransactions',
  }
]

export const GLUE_JOB_CONFIG:GlueJobConfig[] = [
  {
    environment: 'dev',
    name : 'frisco_glue_main',
  },

  {
    environment: 'beta',
    name : 'frisco_glue_main',
  },
  {
    environment: 'prod',
    name : 'frisco_glue_main',
  }
]

export const FLEXICO_GLUE_JOB_CONFIG:GlueJobConfig[] = [
  {
    environment: 'dev',
    name : 'logging_poc',
  },

  {
    environment: 'beta',
    name : 'logging_poc',
  },
  {
    environment: 'prod',
    name : 'logging_poc',
  }
]

export const APP_FAV_ABC = 'favoritesABC'

export const FEATURED_ABC_APP = 'featuredABCApp'

// AWS RUM default casing convention.
// convention: element_action_name
export enum eRUM_CUSTOM_EVENT {
  USER_LOGGED_IN = 'user_logged_in'
}

export enum PORTAL {
  boffin, ted, mjemonitoring
}

export const mapper = {
  boffin : {
    id : '93',
    groups: ['ateam-bi','boffin-authorized-users']
  },
  ted : {
    id : '01H6SVT5HTFK3FXKA3MFXCXK12',
    groups: ['ateam-bi']
  },
  mjemonitoring : {
    id : '55',
    groups: ['ateam-bi', 'UI4BI_MJE_MONITORING_TEAM']
  },
  default : {
    id : '',
    groups: ['ateam-bi']
  }
}

export enum APP_ACCESS_LEVEL {
  APP_ADMIN, FULL_EDIT, PARTIAL_EDIT, READ_ONLY, NO_ACCESS
}

export enum ACCESS_LEVEL_IDs {
  PORTAL_ADMIN = 1,
  APP_ADMIN = 2,
  FULL_EDIT = 3, 
  READ_ONLY = 5,
  APP_SPECIFIC_ACTION = 6,
  APP_GENERAL_ACCESS = 7
}
export const CORP_REPORTING_ADMIN_ACCESS_LEVELS = [ACCESS_LEVEL_IDs.APP_ADMIN]
export const ADMIN_ACCESS_LEVELS = [ACCESS_LEVEL_IDs.PORTAL_ADMIN]
export const ADD_APP_ACCESS_LEVELS = [ACCESS_LEVEL_IDs.PORTAL_ADMIN, ACCESS_LEVEL_IDs.APP_ADMIN]
export const DELETE_APP_ACCESS_LEVELS = [ACCESS_LEVEL_IDs.PORTAL_ADMIN, ACCESS_LEVEL_IDs.APP_ADMIN]
// export const DELETE_APP_ACCESS_LEVELS = []
export const EDIT_APP_ACCESS_LEVELS = [ACCESS_LEVEL_IDs.PORTAL_ADMIN, ACCESS_LEVEL_IDs.APP_ADMIN, ACCESS_LEVEL_IDs.FULL_EDIT]