import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  ExpandableSection,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
  Table,
  Input,
  Select,
  Pagination,
  Badge
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";
import './triggereventlogs.scss';
import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import {select_default_value_all } from "./Constants";
import {setFilterOptions, setFilterOptionsWithAll} from "./FilterUtilities"
import { setCurrentAppId } from "../../store/userAuthSlice";

import { constructExcelSheetWithHeadersAndData } from "../Templates/Utils/AppActivityUtils";


const TriggerHistory = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const [items, setItems] = useState([]);

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);

  const [toolsHide, setToolsHide] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterRegion, setFilterRegion] = useState(select_default_value_all);
  const [filterPreChecks, setFilterPreChecks] = useState(select_default_value_all);
  const [filterEntity, setFilterEntity] = useState('');
  const [filterPeriod, setFilterPeriod] = useState(select_default_value_all);
  const [filterRegionListWithAll, setFilterRegionListWithAll] = useState([]);
  const [filterPreChecksListWithAll, setFilterPreChecksListWithAll] = useState([]);
  const [filterPeriodListWithAll, setFilterPeriodListWithAll] = useState([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [downloadLoading, setDownloadLoading] = useState(false)


  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );

  useEffect(()=> {
    loadFilters()
  },[])

  useEffect(() => {
   
    loadData();


    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate, pageNumber]);
  
  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);


    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

 
  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const preCheckIcon = props => (
    props.data.pre_check_status === 'Passed' ?
      <Box>
        <Icon name='status-positive' variant='success' ></Icon>
      </Box>

      : props.data.pre_check_status === 'Failed' ? <Box>
        <Icon name='status-negative' variant='warning' ></Icon>
      </Box>
        : <span>{props.data.pre_check_status}</span>

  )

  const [columnDefs] = useState([
    // { field: "batch_id", width: "100px", resizable: true, headerName: "Batch" },
    { field: "region", width: "100px", resizable: true, headerName: "Region" },
    { field: "entity", width: "100px", resizable: true, headerName: "Entity" },
    { field: "report_period", width: "150px", resizable: true, headerName: "Period" },
    { field: "etb_version", width: "150px", resizable: true, headerName: "Version" },
    { field: "pre_check_status", width: "120px", resizable: true, headerName: "Pre-Checks", cellRenderer: preCheckIcon },
    { field: "created_time", width: "300px", resizable: true, headerName: "Processed Date" },
    { field: "trigger_request_type", width: "100px", resizable: true, headerName: "Request" },
    { field: "status", width: "150px", resizable: true, headerName: "Status" },
    { field: "retrigger_status", width: "150px", resizable: true, headerName: "Retrigger Status" },
    { field: "retrigger_by", width: "150px", resizable: true, headerName: "Retrigger By" },
    { field: "retrigger_request_time", width: "200px", resizable: true, headerName: "Retrigger Request" }
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "TED", href: "/TEDHome" },
    { text: "Review", href: "" },
  ];

  const getAppliedFiltersArray = (page) => {
    let filtersApplied = []
    if (filterRegion && filterRegion?.value) {
      filtersApplied.push(`region: "` + filterRegion?.value + `"`)
    }
    if (filterPreChecks && filterPreChecks?.value) {
      filtersApplied.push(`pre_check_status: "` + filterPreChecks?.value + `"`)
    }
    if (filterPeriod && filterPeriod?.value) {
      filtersApplied.push(`report_period: "` + filterPeriod?.value + `"`)
    }
    filtersApplied.push(`entity: "` + filterEntity + `"`)
    if(page) {
      filtersApplied.push(`page_no: ${page}`)

    } else {
      filtersApplied.push(`page_no: ${pageNumber}`)
    }
    return filtersApplied
  }

  const getFiltersQueryString = (page = undefined) => {
    let filtersApplied = getAppliedFiltersArray(page)
    let filterQuery = ''
    if (filtersApplied && filtersApplied.length > 0) {
      filterQuery = `(` + filtersApplied.join(", ") + `)`
    }
    return filterQuery
  }
  const loadData = async () => {
    setShowLoadingIcon(true);
    let filterQry = getFiltersQueryString()
    if (showAllItems == false) {
      var mQry =
        `query MyQuery{
          listTriggerHistoryLogs `+ filterQry + `{
            batch_id
            entity
            region
            report_period
            report_month
            trigger_day
            trigger_time_pst
            etb_version
            actual_trigger_day
            effective_trigger_day
            status
            trigger_request_type
            local_gaap_etb_total
            us_gaap_etb_total
            lg_etb_total
            transformation_time
            transformation_status
            pre_check_status
            created_time
            updated_time
            retrigger_request_time
            retrigger_status
            retrigger_by
            created_by
            updated_by
          }
        }`;
    }
    if (showAllItems) {
      var mQry = `query MyQuery { 
        listTriggerHistoryLogs{
          batch_id
          entity
          region
          report_period
          report_month
          trigger_day
          trigger_time_pst
          etb_version
          actual_trigger_day
          effective_trigger_day
          status
          trigger_request_type
          local_gaap_etb_total
          us_gaap_etb_total
          lg_etb_total
          transformation_time
          transformation_status
          pre_check_status
          created_time
          updated_time
          retrigger_request_time
          retrigger_status
          retrigger_by
          created_by
          updated_by
        }
      }`;
    }

    let logQueryCount = `query MyQuery {
  getTEDHistoryLogsCount
    ${filterQry}
  
}`


    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.listTriggerHistoryLogs;
      // load filters from data only when we have are fetching all the data - not on every load, since some loads can be after applying filters
      
      setItems(qResultJSON);
      setShowLoadingIcon(false);
    }, function (error) {
      setShowLoadingIcon(false);
      setErrorMessage(error?.errors[0]?.message)
    });

    const countResponse = await API.graphql(graphqlOperation(logQueryCount)).then((result) => {
      let count = result.data.getTEDHistoryLogsCount;
      setTotalRecordCount(count)
      setPageCount(Math.ceil(count / 3000))
    })

  };

  const loadFilters = async () => {
    setShowLoadingIcon(true)
    let filtersQuery = `query MyQuery {
      getTEDHistoryFilters
    }`
      
    const filtersResponse = await API.graphql(graphqlOperation(filtersQuery)).then((result) => {
      let qResultJSON = JSON.parse(result.data.getTEDHistoryFilters)
      if(filterRegion === select_default_value_all){
        setFilterRegionListWithAll(setFilterOptionsWithAll(qResultJSON?.regions, 'region'))
      }
      if(filterPreChecks === select_default_value_all){
        setFilterPreChecksListWithAll(setFilterOptionsWithAll(qResultJSON?.pre_check_statuses, 'pre_check_status'))
      }
      if(filterPeriod === select_default_value_all){
        setFilterPeriodListWithAll(setFilterOptionsWithAll(qResultJSON?.report_periods, 'report_period'))
      }
      setShowLoadingIcon(false);

    }, function (error) {
      setShowLoadingIcon(false);
      setErrorMessage(error?.errors[0]?.message)
    })
  }


  const resetFilter = () => {
    setFilterRegion(select_default_value_all)
    setFilterPreChecks(select_default_value_all)
    setFilterPeriod(select_default_value_all)
    setErrorMessage('')
    setFilterEntity('')
  }

  const downloadFilteredData = async () => {
    setDownloadLoading(true)
    let downloadResults = []
    const promises = []
    for (let i = 1; i <= pageCount; i++) {
      let filterQry = getFiltersQueryString(i)
      let mQry =
        `query MyQuery{
          listTriggerHistoryLogs `+ filterQry + `{
            batch_id
            entity
            region
            report_period
            report_month
            trigger_day
            trigger_time_pst
            etb_version
            actual_trigger_day
            effective_trigger_day
            status
            trigger_request_type
            local_gaap_etb_total
            us_gaap_etb_total
            lg_etb_total
            transformation_time
            transformation_status
            pre_check_status
            created_time
            updated_time
            retrigger_request_time
            retrigger_status
            retrigger_by
            created_by
            updated_by
          }
        }`;
      let promise =  API.graphql(graphqlOperation(mQry))
      promises.push(promise)
    }
    await Promise.all(promises).then((values) => {
      for (let value of values) {
        let qResultJSON = value.data.listTriggerHistoryLogs;
        downloadResults.push(...qResultJSON)      
      }
    })
            const headers = [
              "Region",
              "Entity" ,
              "Period" ,
              "Version",
              "Pre-Checks",
              "Processed date",
              "Request",
              "Status",
              "Retrigger Status",
              "Retrigger By",
              "Retrigger Request"
            ]
    downloadResults = downloadResults.map((item) => {
      return {
        "Region" : item.region,
        "Entity" : item.entity,
        "Period" : item.report_period,
        "Version": item.etb_version,
        "Pre-Checks": item.pre_check_status,
        "Processed date": item.actual_trigger_day,
        "Request": item.trigger_request_type,
        "Status": item.status,
        "Retrigger Status": item.retrigger_status,
        "Retrigger By": item.retrigger_by,
        "Retrigger Request": item.retrigger_request_time
      }
    })
    constructExcelSheetWithHeadersAndData(headers, downloadResults, "TEDTriggerHistoryFiltered")           

    setDownloadLoading(false)
  }

  const content = (
    <SpaceBetween direction="vertical" size="m">
      {isAuthorized ? (
        <Box>
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                    iconAlign="right"
                    iconName="download"
                    onClick={downloadFilteredData}
                    loading={downloadLoading}
                    >
                    export filtered results
                    </Button> 
                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >

                    </Button>
                  </SpaceBetween>
                }
              >
                Entity ETB Trigger History
              </Header>
            }
          >
            <div
              className="ag-theme-alpine"
              style={{ height: 150, width: "100%" }}
            >
              <Box>
                <ColumnLayout columns={4}>
                  <Box>
                    <Box variant='awsui-key-label'>Region</Box>
                    <Select selectedOption={filterRegion} options={filterRegionListWithAll} onChange={({ detail }) => { setFilterRegion(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Pre-checks</Box>
                    <Select selectedOption={filterPreChecks} options={filterPreChecksListWithAll} onChange={({ detail }) => { setFilterPreChecks(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Period</Box>
                    <Select selectedOption={filterPeriod} options={filterPeriodListWithAll} onChange={({ detail }) => { setFilterPeriod(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Entity</Box>
                    <Input value={filterEntity} onChange={({ detail }) => setFilterEntity(detail.value)} ></Input>
                  </Box>
                  <Box textAlign="right">

                  </Box>
                  <Box></Box>
                </ColumnLayout>
                <SpaceBetween size='m' direction='horizontal'>
                  <Button variant='normal' onClick={() => {
                    resetFilter()
                  }
                  }>Reset</Button>
                  <Button variant='primary' onClick={()=>{
                    loadData()
                    setPageNumber(1)
                    }}>Filter</Button>
                </SpaceBetween>
              </Box>

              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </div>
            
            <h5>Total record(s) count: <Badge color="blue">{totalRecordCount}</Badge></h5>
            
            
                <small style={{fontStyle:'italic'}}>
                 &nbsp; A page contains 3000 or less record(s)
                </small>

              <br></br>
            <div
              className="ag-theme-alpine"
              style={{ height: 500, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={items}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                animateRows={true}
                rowHeight={30}
              ></AgGridReact>
            </div>
            <Box float="right">
              <Pagination
                currentPageIndex={pageNumber}
                onChange={({ detail }) =>
                  setPageNumber(detail.currentPageIndex)
                }
                pagesCount={pageCount}
              />
            </Box>
          </Container>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Info</h2>
        </div>
      }
    >
      <div>

        <p>What the icons mean?</p>
        <p><Icon name="status-negative" variant='warning'></Icon> = Failed</p>
        <p><Icon name='status-positive' variant="success"></Icon> = Passed</p>
        <p></p>
        <br></br>
      </div>
      <div>
        <h4>Column details: </h4>
        <h5>Request</h5>
        <p>This shows if ETB data was automatically shared with Local Tax (at agreed cut off times in Step A) or if it was manually shared</p>
      </div>
      <div>
        <h5>Retrigger status</h5>
        <p>This shows if the manual ETB passed or failed validation.</p>
        <ul>
          <li>If status is "Passed", ETB data was successfully shared with Local Tax manually.</li>
          <li>If status is "Failed", no ETB data has been shared with Local Tax.</li>
        </ul>
      </div>

      <div>
        <h5>Retrigger by</h5>
        <p>This lists the username of the person who triggered the manual ETB delivery</p>
      </div>

      <div>
        <h5>Retrigger request</h5>
        <p>This lists the time stamp of when the user initiated the manual ETB request (i.e. the time stamp of when "Re-trigger" was clicked.</p>
      </div>
      <div>
        <h5>Pre-Check</h5>
        <p>This date will not change. It will show if the automatic delivery of ETB data passed or failed validation.</p>
        <p>If manual ETB is run:</p>
        <ul>
          <li>"Pre-Check" = Failed; "Retrigger status" = Passed. No further action.</li>
          <li>"Pre-Check" = Failed; "Retrigger status" = Failed. No ETB data has been shared with Local Tax. Local Accounting to review STATPACK again to correct validation error.</li>
        </ul>
      </div>

      <div>
        <h5>Processed date</h5>
        <p> This date will not change. It will show the date automatic delivery of ETB data was attempted.</p>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </HelpPanel>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/TriggerHistory" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={helpContent}
      toolsOpen={toolsHide}
      onToolsChange={() => { setToolsHide(!toolsHide) }}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default TriggerHistory;
