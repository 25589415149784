import React, { useState, useEffect } from "react";
import { setCurrentAppId, setTemplateId } from "../../store/userAuthSlice";

import {
  AppLayout,
  Button,
  ColumnLayout,
  Tabs,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Alert,
  Modal,
  Spinner,
  Popover,
  StatusIndicator,
  HelpPanel,
  Flashbar
} from "@amzn/awsui-components-react";

import "../../index.scss";
import { useSelector, useDispatch } from "react-redux";

import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import { useHistory, useParams } from "react-router-dom";

import { API, graphqlOperation } from "aws-amplify";
import Template from '../Templates/Template';
import { TEMPLATE_APP,TEMPLATES, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from "../Templates/TemplateMaple";
import TemplateQSC from "../Templates/TemplateQSC";
import { PROCESS_KEYS } from "../Frisco/Constants";
import { getQSCApp } from "../Templates/Utils/AppActivityUtils"

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);

  //UseCase Details
  let {id} = useParams();
  const [template_id, setTemplateID] = useState(props?.template_id);
  const [usecaseComments, setUsecaseComments] = useState();

  const [flashBarComments, setFlashBarComments] = useState();
  const[appType, setAppType] = useState()
  const [customSideNavitems, setCustomSideNavitems] = useState([
    {
        "type": "link",
        "text": "About",
        "href": `/App/${id}`
    },

    { "type": "divider" },

    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },
    ])
  
    const [qscCustomSideNavitems, setQscCustomSideNavitems] = useState([
      {
          "type": "link",
          "text": "About",
          "href": `/App/${id}`
      }
      ])
  const [renderJson, setRenderJson] = useState({})

  const [mQry,setMQry] = useState(`query MyQuery {
      getAppWithProcessesV2(id: "${id}") {
          ${TEMPLATE_APP.APP_DESCRIPTION}
            ${TEMPLATE_APP.APP_NAME}
            ${TEMPLATE_APP.APP_ID}
            ${TEMPLATE_APP.TEMPLATE_ID}
            ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
            ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
            ${TEMPLATE_APP.URL}
            ${TEMPLATE_APP.PROCESS_OWNER}
            ${TEMPLATE_APP.GRC_CONTROL_OWNER}
            ${TEMPLATE_APP.TDD}
            ${TEMPLATE_APP.STATUS}
            ${TEMPLATE_APP.REGION}
            ${TEMPLATE_APP.CADENCE}
            ${TEMPLATE_APP.PROCESS_MJE_COUNT}
            ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
            ${TEMPLATE_APP.PDD}
            ${TEMPLATE_APP.LEDGER_INFO}
            ${TEMPLATE_APP.LAUNCH_MCM}
            ${TEMPLATE_APP.LAUNCH_QQ_YY}
            ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
            ${TEMPLATE_APP.AUTOMATION_TOOLS}
            ${TEMPLATE_APP.ARD}
            ${TEMPLATE_APP.DATASOURCE}
            ${TEMPLATE_APP.ORGANIZATION}
            ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
            ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
            ${TEMPLATE_APP.GRC_CONTROL_ID}
            ${TEMPLATE_APP.VP}
            ${TEMPLATE_APP.CREATE_TIME_STAMP}
            ${TEMPLATE_APP.CREATED_BY}
            ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
            ${TEMPLATE_APP.LAST_UPDATED_BY}
            ${TEMPLATE_APP.SIM_TICKET_URL}
            ${TEMPLATE_APP.SIM_TICKET_LABEL}
            ${TEMPLATE_APP.USE_CASE}
            ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
            }
            ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
            }
            ${TEMPLATE_APP.PERMISSION}{
              ${TEMPLATE_APP_PERMISSION.LINK}
              ${TEMPLATE_APP_PERMISSION.NAME}
              ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
            }
            ${TEMPLATE_APP.DEVELOPER_TEAM}{
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
            ${TEMPLATE_APP.OUTPUT}{
              ${TEMPLATE_APP_OUTPUT.TYPE}
              ${TEMPLATE_APP_OUTPUT.VALUE}
              ${TEMPLATE_APP_OUTPUT.LABEL}
              ${TEMPLATE_APP_OUTPUT.AWS_ACCOUNT}
              ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
            }
            ${TEMPLATE_APP.ACCOUNTING_TEAM} {
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }

            processes {
              ${PROCESS_KEYS.app_id}
              ${PROCESS_KEYS.process_id}
              ${PROCESS_KEYS.label}
            }
        }
      }  
`)

  useEffect(() => {
    if(id){
      dispatch(setCurrentAppId(id));
      dispatch(setTemplateId(template_id))
      loadData();
    }
    window.scrollTo(0, 0)
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {

    setFlashBarComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarComments([]),
      content: (
        <>
          {usecaseComments}
        </>
      ),
      id: "comments"
    }])

  },[usecaseComments])

  useEffect(()=>{
    setShowLoadingIcon(true);

    if(appType == TEMPLATES.QSC) {
      loadQSCAppDetails()
    } else if (appType == TEMPLATES.MAPLE){
      loadMapleAppDetails()
    }

    setShowLoadingIcon(false);

  },[appType])
  
  const loadData = async () => {

    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.getAppWithProcessesV2;
          setAppType(qResultJSON?.template_id)

    }, function (error) {
      // no JSON in DB. Looks to be invalid appID. Go home
      history.push('/')
    });    
    
  };

  const loadQSCAppDetails = async () => {
        const res =  await getQSCApp(id)
        if(res?.data?.getQSCApp) {
          setRenderJson(res?.data?.getQSCApp[0])    
        }
  }

  const loadMapleAppDetails = async() => {
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.getAppWithProcessesV2;
          setRenderJson(qResultJSON)

    }, function (error) {
      // no JSON in DB. Looks to be invalid appID. Go home
      history.push('/')
    });
  }
  return (
    (template_id==TEMPLATES.MAPLE || renderJson?.template_id == TEMPLATES.MAPLE)?
    <TemplateMaple id={id} renderJson={renderJson} customSideNavitems={customSideNavitems} />:
    (template_id==TEMPLATES.QSC || renderJson?.template_id == TEMPLATES.QSC)?
    <TemplateQSC id={id} renderJson={renderJson} customSideNavitems={qscCustomSideNavitems} />:
    <Template id={id} renderJson={renderJson} customSideNavitems={customSideNavitems} />
  );
};
