import React, { useState, useEffect } from "react";

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import {
  Badge
} from '@amzn/awsui-components-react';

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref); 
  const [groupId, setGroupId] = useState(props.groupId?props.groupId:undefined);

  const history = useHistory();
  const [items, setItems] = useState([
    {
        "type": "link",
        "text": "Home",
        "href": `/CorpReportingHome`
    },
    {
        "type": "link",
        "text": "Details",
        "href": `/App/${props.id}`
    },
    { "type": "divider" },
    {
        "type": "link",
        "text": "Back",
        "href": "#/ATeamBIHome"
    },
  ])
  useEffect(() => {
    let navItems = [
      {
        "type": "link",
        "text": `Workstreams`,
        "href": `/CorpReportingHome/modules`
      },
      {
        "type": "link",
        "text": "Deliverables",
        "href": `/CorpReportingHome/deliverables`
      },
      {
        "type": "link",
        "text": "Home",
        "href": `/CorpReportingHome`
      },
    ]
    if(props.isAdmin){
      navItems.push({
        "type": "link",
        "text": "Admin portal",
        "href": "/CorpReportingHome/admin"
      })
    }
    if(props?.groupId && props?.canEdit){
      navItems.push({
        "type": "link",
        "text": <> <Badge>+</Badge> New process</>,
        "href": groupId?`/App/${props.id}/process?group=${groupId}&next_step=CORP_ADMIN_APPROVAL&back=CorpReportingHome`:`/App/${props.id}/process`
      })
    }
    navItems.push({ "type": "divider" })
    navItems.push({
      "type": "link",
      "text": "Back",
      "href": "/"
  })
    setItems(navItems)
  }, [props])
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: activeHref, text: name }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
      }}
      items={items}
    />
  );
}
