import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  ExpandableSection,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
  Tabs,
  SegmentedControl,
  Modal,
  Table,
  Pagination
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";

import { useHistory, useParams } from "react-router-dom";
import AccessDenied from "../AccessDenied";
import WaitForPageAccess from "../WaitForPageAccess";

import { setCurrentComputeId } from "../store/userBoffinSlice";
import moment from 'moment';

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { setCurrentAppId } from "../store/userAuthSlice";
import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../context/AuthContextProvider";
import { Board, BoardItem } from "@amzn/awsui-board-components";
import {
  boardI18nStrings,
  boardItemI18nStrings,
} from "src/i18n-strings/board-I18nStrings";

import { getRequestedWasWorkflows, getApproverWasWorkflows } from "../utilities/wasApprovalAPIs"
import './styles.scss'

const ApprovalsHome = (props) => {
  const userAuthenticationDetails = useAuth();

  const dispatch = useDispatch();

  const history = useHistory();
  const gridRef = useRef();
  const gridRef2 = useRef();
  const gridRef3 = useRef();

  const { id } = useParams();

  const [activeHref, setActiveHref] = useState();
  const [sideHeader, setSideHeader] = useState("Approvals Home");
  const [headerHref, setHeaderHref] = useState();
  const [sideNavBack, setSideNavBack] = useState();
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const currentUser = useSelector((globalState) => globalState.auth.userId)


  const [selectedSegment, setSelectedSegment] = useState("pastRequested");
  const [processingStatus, setProcessingStatus] = useState("info");
  const [currentStatusId, setCurrentStatusId] = useState("");
  const [pastApprovalMetadata, setPastApprovalMetadata] = useState({});
  const [pendingApprovalMetadata, setPendingApprovalMetadata] = useState({});
  const [requestedApprovalMetadata, setRequestedApprovalMetadata] = useState({});


  const [boardItemsRequested, setBoardItemsRequested] = useState([
    {
      id: "1",
      rowSpan: 6,
      columnSpan: 6,
      data: {},
      header: "Requested Approvals",
    },
  ]);
  const [boardItemsPending, setBoardItemsPending] = useState([
    { id: "1", rowSpan: 6, columnSpan: 6, data: {}, header: "Pending Action" },
  ]);

  const [boardItemsPast, setBoardItemsPast] = useState([
    { id: "1", rowSpan: 6, columnSpan: 6, data: {}, header: "Completed" },
  ]);

  const [itemsPending, setItemsPending] = useState([]);
  const [itemsPast, setItemsPast] = useState([]);
  const [itemsRequested, setItemsRequested] = useState([]);

  const [currentId, setCurrentId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);

  // const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [pastDataLoading, setPastDataLoading] = useState(true);
  const [requestedDataLoading, setRequestedDataLoading] = useState(true);
  const [pendingDataLoading, setPendingDataLoading] = useState(true);

  const [showAllItems, setShowAllItems] = useState(false);

  useEffect(() => {
    loadData();

    //   Subscribe here

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);


  const openButton = (props) => (
    <Box>
      <a href={`/ApprovalsHome/Workflow/${props.workflowInstanceId}`}> {props.workflowInstanceId} </a>
    </Box>
  );


  const processAlias = (props) => (
    <SpaceBetween direction="horizontal" size="s">
      <img
        alt=""
        className="rounded-circle"
        src={
          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
          props.createdBy
        }
        width={10}
        height={10}
      />
      <Box textAlign="center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://phonetool.amazon.com/users/" + props.createdBy}
        >
          {props.createdBy}
        </a>
      </Box>
    </SpaceBetween>


  )

  const timeStamp = (props) => (
    <Box>{moment(new Date(Number(props.createdOn))).format()}</Box>
  )

  const getBadge = (props) => {
    if (props?.status == 'pending') {
      return <span className='orange_badge'>{props.status}</span>
    } else if (props.status == 'completed') {
      return <span className='green_badge'>{props.status}</span>
    } else if (props.status == 'rejected') {
      return <span className='red_badge'>{props.status}</span>
    }
    else if (props.status == 'cancelled') {
      return <span className='grey_badge'>{props.status}</span>
    } else {
      return <span className='blue_badge'>{props.status}</span>
    }
  }



  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    {
      id: "workflowInstanceId",
      header: "Workflow Instance Id",
      cell: e => e.workflowInstanceId ? openButton(e) : '',
      width: 250,
      minWidth: 250,
      sortingField: "workflowInstanceId"
    },
    {
      id: "name",
      header: "Workflow Title",
      cell: e => e.name,
      width: 400,
      minWidth: 250,
      sortingField: "name"
    },
    {
      id: "createdBy",
      header: "Requested By",
      cell: e => processAlias(e),
      width: 200,
      sortingField: "createdBy"
    },
    {
      id: "status",
      header: "Type",
      cell: e => getBadge(e),
      width: 150,
      sortingField: "status"
    },
    {
      id: "createdOn",
      header: "Created At",
      cell: e => timeStamp(e),
      minWidth: 250,
      sortingField: "createdOn"
    }

  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setSelectedItem(selectedRows[0]);
      setCurrentId(selectedRows[0].id);
      dispatch(setCurrentComputeId(selectedRows[0].id));
    }
  };

  const loadData = async () => {
    // setShowLoadingIcon(true);
    console.log(id);

    if (id) {
      setHeaderHref(`/ApprovalsHome/App/${id}`);
      setSideNavBack(`/App/${id}`)
    } else {
      setHeaderHref(`/ApprovalsHome`);
      setSideNavBack(`/`)
    }

    const pendingRequestedDetails = {
      ownerAlias: currentUser,
      stage: currentStage,
      workflowStatus: "pending"      // Possible values: pending, rejected, canceled, completed
    }

    if (id) pendingRequestedDetails.tags = `[{key: "app_id", value:"${id}"}]`
    const res = await getRequestedWasWorkflows(pendingRequestedDetails)
    setItemsRequested(res.workflowInstances)
    setRequestedDataLoading(false)
    setRequestedApprovalMetadata(res.metaData)

    const pendingApproverDetails = {
      userAliasAllStates: currentUser,
      stage: currentStage,
      userResponseStatus: "PENDING",      // Possible values: PENDING, COMPLETE
      workflowStatus: "pending"
      // tags: '[{key: "app_id", value:"123"}]'
    }
    if (id) pendingApproverDetails.tags = `[{key: "app_id", value:"${id}"}]`
    const res2 = await getApproverWasWorkflows(pendingApproverDetails)
    setItemsPending(res2.workflowInstances)
    setPendingDataLoading(false)
    setPendingApprovalMetadata(res2.metaData)
    // const details = {
    //   ownerAlias: "subbui",
    //   stage:"beta",
    //   workflowStatus: "completed",      // Possible values: pending, rejected, canceled, completed
    //   tags: '[{key: "app_id", value:"123"}]'
    // }

    const pastApprovalDetails = {
      userAliasAllStates: currentUser,
      stage: currentStage,
      userResponseStatus: "COMPLETE",     // Possible values: PENDING, COMPLETE
      // tags: '[{key: "app_id", value:"123"}]'
      pageNo: pastApprovalMetadata?.pageNo ? pastApprovalMetadata.pageNo : 0,
      pageSize: pastApprovalMetadata?.pageSize ? pastApprovalMetadata.pageSize : 50
    }

    if (id) pastApprovalDetails.tags = `[{key: "app_id", value:"${id}"}]`

    const res3 = await getApproverWasWorkflows(pastApprovalDetails)
    setItemsPast(res3.workflowInstances)
    setPastDataLoading(false)
    setPastApprovalMetadata(res3.metaData)
    // setShowLoadingIcon(false);
  };

  const getNextPagePastApprovals = async (pageNo) => {
    setPastDataLoading(true)
    const pastApprovalDetails = {
      userAliasAllStates: currentUser,
      stage: currentStage,
      userResponseStatus: "COMPLETE",     // Possible values: PENDING, COMPLETE
      // tags: '[{key: "app_id", value:"123"}]'
      pageNo: pageNo ? pageNo : 0,
      pageSize: pastApprovalMetadata?.pageSize ? pastApprovalMetadata.pageSize : 50
    }

    if (id) pastApprovalDetails.tags = `[{key: "app_id", value:"${id}"}]`

    const res3 = await getApproverWasWorkflows(pastApprovalDetails)
    setItemsPast(res3.workflowInstances)
    setPastDataLoading(false)
    setPastApprovalMetadata(res3.metaData)
  }

  const getNextPageRequestedApprovals = async (pageNo) => {
    setRequestedDataLoading(true)

    const pendingRequestedDetails = {
      ownerAlias: currentUser,
      stage: currentStage,
      workflowStatus: "pending",      // Possible values: pending, rejected, canceled, completed
      pageNo: pageNo ? pageNo : 0,
      pageSize: pastApprovalMetadata?.pageSize ? pastApprovalMetadata.pageSize : 50
    }

    if (id) pendingRequestedDetails.tags = `[{key: "app_id", value:"${id}"}]`
    const res = await getRequestedWasWorkflows(pendingRequestedDetails)
    setItemsRequested(res.workflowInstances)
    setRequestedDataLoading(false)
    setRequestedApprovalMetadata(res.metaData)
  }

  const getNextPagePendingApprovals = async (pageNo) => {
    setPendingDataLoading(true)

    const pendingApproverDetails = {
      userAliasAllStates: currentUser,
      stage: currentStage,
      userResponseStatus: "PENDING",      // Possible values: PENDING, COMPLETE
      workflowStatus: "pending",
      // tags: '[{key: "app_id", value:"123"}]'
      pageNo: pageNo ? pageNo : 0,
      pageSize: pastApprovalMetadata?.pageSize ? pastApprovalMetadata.pageSize : 50
    }
    if (id) pendingApproverDetails.tags = `[{key: "app_id", value:"${id}"}]`
    const res2 = await getApproverWasWorkflows(pendingApproverDetails)
    setItemsPending(res2.workflowInstances)
    setPendingDataLoading(false)
    setPendingApprovalMetadata(res3.metaData)
  }
  const content = (
    <SpaceBetween direction="vertical" size="m">
      <Tabs
        tabs={[
          {
            label: "Requested by you (Pending)",
            id: "requested",
            content: (
              <Box>
                <Box></Box>

                <div
                  className="ag-theme-alpine"
                  style={{ height: "92%", width: "100%" }}
                >

                  <Table
                    header={
                      <Header>Requested Approvals</Header>
                    }
                    columnDefinitions={columnDefs}
                    enableKeyboardNavigation
                    items={itemsRequested}
                    loadingText="Loading approvals requested"
                    loading={requestedDataLoading}
                    resizableColumns
                    sortingDisabled
                    stripedRows
                    empty={
                      <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                      >
                        <SpaceBetween size="m">
                          <b>No approvals requested</b>
                        </SpaceBetween>
                      </Box>
                    }
                    pagination={
                      <Pagination
                        currentPageIndex={requestedApprovalMetadata?.pageNo ? requestedApprovalMetadata.pageNo + 1 : 1} pagesCount={requestedApprovalMetadata?.totalPages ? requestedApprovalMetadata?.totalPages : 1}
                        onChange={({ detail }) => { getNextPageRequestedApprovals(detail.currentPageIndex - 1) }} />
                    }
                  />
                </div>
                <p></p>

                <p></p>
              </Box>
            ),
          },
          {
            label: "Pending your approval",
            id: "pendingApproval",
            content: (
              <Box>
                <Box></Box>

                <div
                  className="ag-theme-alpine"
                  style={{ height: "92%", width: "100%" }}
                >
                  <Table
                    header={
                      <Header>Pending Action</Header>
                    }

                    columnDefinitions={columnDefs}
                    enableKeyboardNavigation
                    items={itemsPending}
                    loadingText="Loading approvals pending"
                    loading={pendingDataLoading}
                    resizableColumns
                    sortingDisabled
                    stripedRows
                    empty={
                      <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                      >
                        <SpaceBetween size="m">
                          <b>No pending approvals</b>
                        </SpaceBetween>
                      </Box>
                    }
                    pagination={
                      <Pagination
                        currentPageIndex={pendingApprovalMetadata?.pageNo ? pendingApprovalMetadata.pageNo + 1 : 1} pagesCount={pendingApprovalMetadata?.totalPages ? pendingApprovalMetadata?.totalPages : 1}
                        onChange={({ detail }) => { getNextPagePendingApprovals(detail.currentPageIndex - 1) }} />
                    }
                  />
                </div>
                <p></p>

                <p></p>
              </Box>
            ),
          },
          {
            label: "Past Approvals",
            id: "pastApprovals",
            content: (
              <Box>
                <Box></Box>


                <div
                  className="ag-theme-alpine"
                  style={{ height: "92%", width: "100%" }}
                >

                  <Table
                    header={
                      <Header>Completed</Header>
                    }

                    columnDefinitions={columnDefs}
                    enableKeyboardNavigation
                    items={itemsPast}
                    loadingText="Loading past approvals"
                    loading={pastDataLoading}
                    resizableColumns
                    sortingDisabled
                    stripedRows
                    empty={
                      <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                      >
                        <SpaceBetween size="m">
                          <b>No past approvals</b>
                        </SpaceBetween>
                      </Box>
                    }
                    pagination={
                      <Pagination
                        currentPageIndex={pastApprovalMetadata?.pageNo ? pastApprovalMetadata.pageNo + 1 : 1} pagesCount={pastApprovalMetadata?.totalPages ? pastApprovalMetadata?.totalPages : 1}
                        onChange={({ detail }) => { getNextPagePastApprovals(detail.currentPageIndex - 1) }} />
                    }
                  />

                </div>

                <p></p>

                <p></p>
              </Box>
            ),
          },
        ]}
      />
      {/* {showLoadingIcon ? (
        <Modal visible={showLoadingIcon}>
          <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
        </Modal>
      ) : (
        <Box></Box>
      )} */}
    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel>
      <div></div>
    </HelpPanel>
  );
  return (
    <AppLayout
      content={content}
      headerSelector="#TopBar"
      navigationHide={true}
      toolsHide={true}
      maxContentWidth={Number.MAX_VALUE}
    ></AppLayout>
  );
};

export default ApprovalsHome;
