import React, { useState, useEffect } from "react";
import { TEMPLATE_APP, QSC_APP_SPECIFIC_FIELDS, TEMPLATES } from "./Constants";
import CustomSelect from "./components/CustomSelect";
import CustomRadioGroup from "./components/CustomRadioGroup";
import CustomSelectAlias from "./components/CustomSelectAlias";
import CustomSelectMultiAlias from "./components/CustomSelectMultiAlias";

import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomInput from "./components/CustomInput";
import CustomMultiInput from "./components/CustomMultiInput";
import { ADD_APP_ACCESS_LEVELS } from 'src/constants/AppConstants'
import { getEditableFields } from "./Utils/FormMetadata";
import { getSuggestedLogins } from "./Utils/AppActivityUtils";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import { getAccessLevel } from "src/checkPageAccess"
import AccessDenied from "../../AccessDenied";
import ErrorComponent from "./components/ErrorComponent";
import { updateQSCAppAdditionalDetails, getQSCAppAdditionalDetails, getQSCMigrationUpdates, getQSCMigrationUpdatesById } from "./Utils/AppActivityUtils"

import { API, graphqlOperation } from "aws-amplify";

import {
    AppLayout,
    BreadcrumbGroup,
    Container,
    Box,
    Cards,
    Button,
    Header,
    Table,
    Badge,
    Autosuggest,
    ExpandableSection,
    SpaceBetween,
    Multiselect,
    ColumnLayout,
    Tabs,
    Pagination,
    Spinner,
    SegmentedControl,
    Select,
    Link,
    Wizard,
    FormField,
    Input,
    KeyValuePairs,
    Checkbox,
    Textarea,
    Grid,
    RadioGroup,
    Icon,
    Modal
} from "@amzn/awsui-components-react";
import SideNav from "./SideNav";

export default (props) => {

    const { id } = useParams()
    const userId = useSelector((globalState) => globalState.auth.userId);
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const [NavOpen, setNavOpen] = useState(true);
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const [renderJson, setRenderJson] = useState({})
    const [renderJsonEdited, setRenderJsonEdited] = useState({})
    const [qscAddAppAccess, setQscAddAppAccess] = useState()
    const [isQSCApp, setIsQSCApp] = useState()
    const [adminNote, setAdminNote] = useState()
    const [showLoadingIcon, setShowLoadingIcon] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [migrationUpdates, setMigrationUpdates] = useState()

    const [customSideNavitems, setCustomSideNavitems] = useState([
      {
        "type": "link",
        "text": "About",
        "href": `/App/${id}`
      },
      {
        "type": "link",
        "text": "Migration Details",
        "href": `/App/${id}/QSCAppMigrationDetails`
    },
    {
      "type": "link",
      "text": "Change Request Management",
      "href": `/App/${id}/QSCAppChangeRequest`
  },
  { "type": "divider" },

    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },])

    useEffect(() => {
        checkAddQSCAppAccesses();
        checkIfAppTypeIsQSC();
        loadData()
    }, [id])


    useEffect(()=>{
        checkAddQSCAppAccesses()
      },[userId, currentStage])

      useEffect(() => {
        setRenderJsonEdited(renderJson)
      }, [renderJson])


    const checkAddQSCAppAccesses = async() => {
        if(userId && currentStage) {
          let qscAccess = await getAccessLevel(userId,TEMPLATES.QSC,currentStage)
          setQscAddAppAccess(ADD_APP_ACCESS_LEVELS.includes(qscAccess?.appAccessLevel))
        }
    
      }

    const loadData = async () => {
      const response = await getQSCAppAdditionalDetails(id)
      if(response?.data?.getQSCApp[0]) {
        setRenderJson(response?.data?.getQSCApp[0])

      }

      const migrationUpdatesResponse = await getQSCMigrationUpdatesById(id)
        setMigrationUpdates(migrationUpdatesResponse)
    }
    
    const getCurrentTime = () => {
        return Date.now();
      }
    const checkIfAppTypeIsQSC = async () => {
        let mQry = `
    query MyQuery {
      getAppsV2 (ids:["${id}"]) {
          ${TEMPLATE_APP.TEMPLATE_ID}
      }
    }  
        `;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    if(qResultJSON?.length >=1 && qResultJSON[0].hasOwnProperty(TEMPLATE_APP.TEMPLATE_ID) && qResultJSON[0][TEMPLATE_APP.TEMPLATE_ID] === TEMPLATES.QSC) {
      setIsQSCApp(true)
    } else {
      setIsQSCApp(false)
    }
    }
    useEffect(() => {
        setCustomSideNavitems([
          {
            "type": "link",
            "text": "About",
            "href": `/App/${id}`
        },
            {
                "type": "link",
                "text": "Migration Details",
                "href": `/App/${id}/QSCAppMigrationDetails`
            },
            {
              "type": "link",
              "text": "Change Request Management",
              "href": `/App/${id}/QSCAppChangeRequest`
          },
            
            { "type": "divider" },

            {
                "type": "link",
                "text": "Back",
                "href": "/"
            },

        ])
    }, [id])

    useEffect(() => {
      setRenderJsonEdited(renderJson)
    }, [renderJson])


    const isEditable = (fieldName, subKey = null) => {
        // if (subKey) {
        //   return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['editable'])
        // }
        // else {
        //   return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['editable'])
        // }
    
        if (subKey) {
            return (isEditMode)
          }
          else {
            return (isEditMode)
          }
    
      }
    
      const cancelEdition = () => {
        setIsEditMode(false)
        setRenderJsonEdited(renderJson)
      }

      const updateQSCAdditionalDetails = async () => {
        setShowLoadingIcon(true)
        renderJsonEdited["timestamp"] = `${getCurrentTime()}`
        renderJsonEdited["updated_by"] = userId
        renderJsonEdited["admin_note"] = adminNote
        updateQSCAppAdditionalDetails(renderJsonEdited, function onSuccess(response) {
          setRenderJson(JSON.parse(JSON.stringify(response.data.updateQSCAdditionalDetails)))
          cancelEdition()
          loadData()
          setShowLoadingIcon(false)
          history.push("/App/" + id + "/QSCAppMigrationDetails")
          
      }, function onFailure(error) {
          setErrorMessage(error?.errors[0]?.message)
          setShowLoadingIcon(false)
      })
      }


  const getSelectFieldValues = (fieldName, subKey = null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      if(fieldName === TEMPLATE_APP.STATUS && (usecaseStatus === 'Active' || usecaseStatus === 'Deprecated') && !isNewApp) {
        const index = fieldMetadata[fieldName]['values'].findIndex((option) => (option.value === 'WIP'))
        fieldMetadata[fieldName]['values'][index]["disabled"] = true
        fieldMetadata[fieldName]['values'][index]["disabledReason"] = 'Cannot update from "Active" or "Deprecated" status to "WIP".'
      } else if(fieldName === TEMPLATE_APP.STATUS && isNewApp) {
        fieldMetadata[fieldName]['values'].forEach((item) => {
          if(item.value === 'Active' || item.value === 'Deprecated') {
            item["disabled"] = true
            item["disabledReason"] = '"Active" and "Deprecated" statuses are disabled when creating an application, please select "WIP".'
          }
        })
      }
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    } else {
      return []
    }
  }

  const getSelectFieldsForAdhoc = (fieldName, subKey = null) => {
    if (fieldName === QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS) {
    
        return [
            { value: "Under Review", label: "Under Review" },
            { value: "Migration Completed", label: "Migration Completed" },
            { value: "Change Request Under Review", label: "Change Request Under Review" },
            { value: "Change Request Migration Completed", label: "Change Request Migration Completed" }

        ]
    } else {
        return []
    }
}

  const getMultiInputFieldValues = (fieldName) => {
    if (renderJsonEdited && renderJsonEdited[fieldName]) {
      return renderJsonEdited[fieldName]
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey = null) => {
    if (subKey) {
      if (!renderJsonEdited.hasOwnProperty(fieldName)) {
        renderJsonEdited[fieldName] = {}
      }
      renderJsonEdited[fieldName][subKey] = value
    } else {
      renderJsonEdited[fieldName] = value
    }
    setRenderJsonEdited(JSON.parse(JSON.stringify(renderJsonEdited)))
  }

  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if (renderJsonEdited.hasOwnProperty(fieldName)) {
        return renderJsonEdited[fieldName][subKey]
      } else {
        return null
      }
    } else {
      return renderJsonEdited ? renderJsonEdited[fieldName] : null
    }
  }

    const content = (
      <>{showLoadingIcon ? (
        <Modal visible={showLoadingIcon}>
          <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
        </Modal>
      ) : (
      <> {typeof(isQSCApp) === 'undefined' ?
        <LoadingSpinner></LoadingSpinner> :
        (isQSCApp === false) ? <><ErrorComponent header="Not a valid QSC App" message={`Please make sure the app id: ${id} in the URL is accurate`}/></> :
        (isQSCApp === true) ? 
        <Tabs
      tabs={[
        {
          label: "Migration Details",
          id: "additionalDetails",
          content: (<Container
            >
              <SpaceBetween>
                <div className="header_menu">
                  <div className="header_left">
                    <Box variant="h1">Migration Details
                    {
                    
                    qscAddAppAccess ?
                        (isEditMode) ?
                          '' :
                          <span>
                            <Button iconName="edit" variant="icon" onClick={()=>{setIsEditMode(true)}} />
                          </span>
                        :
                        <></>
                  }
                  </Box>
                  </div>
                </div>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ColumnLayout columns={2}>   
    
                <div>
                  <Box variant="h4">Migration Status</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS) ?
                      <CustomSelect field={QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldsForAdhoc} />
                      : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS)}</Box>
                  }
                </div>
    
                <div>
                  <Box variant="h4">fgbsbi prod dashboard URL</Box>
                  {
                    isEditable(QSC_APP_SPECIFIC_FIELDS.FGBSBI_PROD_DASHBOARD_URL) ?
                    <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_PROD_DASHBOARD_URL} placeholder="fgbsbi prod dashboard URL" updateValue={updateValue} getValue={getValue} required={true} ></CustomInput>
                    : <Box variant="p">{getValue(QSC_APP_SPECIFIC_FIELDS.FGBSBI_PROD_DASHBOARD_URL) ? getValue(QSC_APP_SPECIFIC_FIELDS.FGBSBI_PROD_DASHBOARD_URL) : '-'}</Box>
                  }
                </div>
    
                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Launch MCM"
                  >
                    {
                      isEditable(TEMPLATE_APP.LAUNCH_MCM) ?
                        <CustomMultiInput field={TEMPLATE_APP.LAUNCH_MCM} updateValue={updateValue} getValues={getMultiInputFieldValues}></CustomMultiInput>
                        :
                        <ul>
                          {getMultiInputFieldValues(TEMPLATE_APP.LAUNCH_MCM)?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item.trim()}
                            </li>
                          ))}
                        </ul>
                    }
                  </ExpandableSection>
                </div>
    
                </ColumnLayout>
                {
                isEditMode ?
                <SpaceBetween direction="vertical" size="l">
                  <Box variant="h4">Admin Note</Box>
                      <Textarea 
                      class="description"
                      onChange={({ detail }) => setAdminNote(detail.value)}
                      value={adminNote}
                      placeholder="Admin Note"
                      ></Textarea>
                      <div className="buttons">
                    <SpaceBetween direction="horizontal" size="l">
                      <Button variant="primary" onClick={updateQSCAdditionalDetails}  >Update</Button> 
                      <Button onClick={cancelEdition}>Cancel</Button>
                    </SpaceBetween>
                  </div>
                    </SpaceBetween>
                  :
                  ''
              }
              </SpaceBetween>
    
            </Container>)
        },
        {
          label: "Status Updates",
          id: "changeRequestUpdates",
          content: (<Table
            renderAriaLive={({
              firstIndex,
              lastIndex,
              totalItemsCount
            }) =>
              `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            columnDefinitions={[
              {
                id: "admin_note",
                header: "Admin note",
                cell: item => (
                  item.admin_note || '-'
                ),
        
                isRowHeader: true
              },
              {
                id: "migration_status",
                header: "Migration Status Changed To",
                cell: item => item.migration_status || "-",
              },
              {
                id: "updated_by",
                header: "Updated By",
                cell: item => item.updated_by || "-"
              },
              {
                id: "timestamp",
                header: "Timestamp",
                cell: item => item.timestamp || "-"
              }
            ]}
            enableKeyboardNavigation
            items={migrationUpdates}
            loadingText="Loading Status Updates"
            sortingDisabled
            empty={
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No Status Updates</b>
                </SpaceBetween>
              </Box>
            }
          />)
        }
      ]}
    />
         : <></>
        }
      </>
      )}
      </>
    )


    return (
        <AppLayout
            disableContentPaddings={false}
            navigation={<SideNav activeHref={`/App/${id}/QSCAppMigrationDetails`} id={id} customSideNavitems={customSideNavitems} />}
            navigationOpen={NavOpen}
            content={content}
            headerSelector="#TopBar"
            toolsHide={true}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            headerVariant="high-contrast"
        />

    );
}
