import React, {useState, useEffect} from 'react';

import { useAuth } from 'src/context/AuthContextProvider';
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import {getAccessLevel, checkIfPortalAdmin} from "src/checkPageAccess"
import { useSelector, useDispatch } from 'react-redux'
import {ADD_APP_ACCESS_LEVELS, ADMIN_ACCESS_LEVELS} from 'src/constants/AppConstants'
import { TEMPLATES } from 'src/Ateam-BI/Templates/Constants';

export default (props) => {
  const userAuthenticationDetails = useAuth();
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();
  const userId  = userAuthenticationDetails.Alias;
  const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
  const [orgList, setOrgList] = useState([])
  
  const getBasicNavItems = () => {

    const applicationSidenavItems = [
      {
          "type": "link",
          "text": (<div>All</div>),
          "href": "/"
      }
  ]
  orgList?.forEach((org) => {
    applicationSidenavItems.push({
      "type": "link",
      "text": (<div>{org?.label}</div>),
      "href": `/Org/${org?.value}`
    })
  })
  return applicationSidenavItems
  }

  const [items, setItems] = useState([]);


  // useEffect(() => {
  //   showRestrictedOptions()   
  // }, [])

  useEffect(() => {
    setOrgList(props.orgs)
  },[props.orgs])


  useEffect(() => {
    const itemsToDisplay = [
      {
          "type": "section",
          "text": "Applications",
          "defaultExpanded": true,
          "items": getBasicNavItems()
      },
      {
        "type": "section",
          "text": "Audits",
          "defaultExpanded": true,
          "items": [{
            "type": "link",
            "text": (<div>All Audit Logs</div>),
            "href": "/AllAuditLogs"
          }]
      }
      ]
      const handleRestrictedOptions = async () => {
        const bool = await showRestrictedOptions() 
        if(bool) {
          let item = {
            "type": "section",
              "text": "Admin",
              "defaultExpanded": true,
              "items": [{
                "type": "link",
                "text": (<div>Admin panel</div>),
                "href": "/Admin"
              }]
          }
          itemsToDisplay.push(item)
        }
      }
      handleRestrictedOptions()
      setItems(itemsToDisplay) 
  },[orgList])

  const showRestrictedOptions = async () => {

    let hasAdminAccess = await checkIfPortalAdmin(userId,currentStage)
    if(hasAdminAccess){
      return true;
    }
  }


  return (
    <SideNavigation
      activeHref={activeHref}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={items}
    />
  );
}