import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import './style.scss'
import { TEMPLATE_APP_OUTPUT } from '../Constants';

const CustomMultiHybridInputsForAppOutputs = (props) => {
    var [rows, setRows] = useState([]);
    var [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        if(isInValid()){
            setErrorMessage('Output type is mandatory for all rows')
        }else{
            setErrorMessage('')
            props.updateValue(props.field, getMergedInputValues())
        }
    }, [rows])

    const isInValid = () => {
        let values =  getMergedInputValues()
        let invalid = false;
        values.forEach((value) => {
            if(value.type == null){
                invalid = true
            }
        })

        return invalid
    }

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <SpaceBetween direction="horizontal" size="xs">
            <Select
                selectedOption={value.type ? {value:value?.type} : null}
                onChange={({ detail }) =>
                    onEdit(detail.selectedOption.value, index, TEMPLATE_APP_OUTPUT.TYPE)
                }
                options={props.getOptions(props.field, props.subKeyForSelection)}
                placeholder="Choose output type"
                />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_OUTPUT.LABEL)}
                placeholder="Label"
                value={value?.label}
            />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_OUTPUT.VALUE)}
                placeholder="URL/Link"
                value={value?.value}
            />
            {value.type?.toLowerCase() == 'quicksight dashboard'? 
            <Select
            selectedOption={value.aws_account ?{value:value?.aws_account} : null}
            onChange={({ detail }) =>
                onEdit(detail.selectedOption.value, index, TEMPLATE_APP_OUTPUT.AWS_ACCOUNT)
            }
            options={[{'value':'FGBSBI', 'label': 'FGBSBI'},{'value':'FAST', 'label': 'FAST'}]}
            placeholder="Choose AWS account"
            />:''
            }
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>

    </SpaceBetween>
            
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        var newRow = {}
        newRow[TEMPLATE_APP_OUTPUT.TYPE] = null
        newRow[TEMPLATE_APP_OUTPUT.VALUE] = ''
        newRow[TEMPLATE_APP_OUTPUT.LABEL] = ''
        setRows([...rows,newRow ])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        if(values == null){
            return
        }
        rows=[];
        values.forEach(element => {
            rows.push(element)
        });
        setRows(JSON.parse(JSON.stringify(values)))
    }

    return (
        <Box>
            {errorMessage? <span className="error"> {errorMessage} </span> : ''}
            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomMultiHybridInputsForAppOutputs;