export const glossary = [
    { label: "Ledger Impact" , description:  "Definition about the same goes here." },
    { label: "Launch MCM" , description:  "Definition about the same goes here." },
    { label: "Ledger QQ_YY" , description:  "Definition about the same goes here." },
    { label: "Run Frequency" , description:  "Definition about the same goes here." },
    { label: "Annual MER Savings" , description:  "Definition about the same goes here." },
    { label: "MJE Count" , description:  "Definition about the same goes here." },
    { label: "Use Case Regions" , description:  "Definition about the same goes here." } ,
    { label: "Use Case Type" , description:  "Definition about the same goes here." },

    { label: "Data Source Details" , description:  "Definition about the same goes here." },
    { label: "ARD" , description:  "Definition about the same goes here." },
    { label: "PDD" , description:  "Definition about the same goes here." },
    { label: "TDD" , description:  "Definition about the same goes here." },
    { label: "Input Source Types" , description:  "Definition about the same goes here." },
    { label: "Output Type" , description:  "Definition about the same goes here." },
    { label: "Automation Tools" , description:  "Definition about the same goes here." },
    { label: "Output Link" , description:  "Definition about the same goes here." },

    { label: "Developer Team" , description:  "Definition about the same goes here." },
    { label: "Process Owner" , description:  "Definition about the same goes here." },
    { label: "Organization" , description:  "Definition about the same goes here." },
    { label: "Accounting Team" , description:  "Definition about the same goes here." },
    { label: "Team Email" , description:  "Definition about the same goes here." },
    { label: "Accounting VP" , description:  "Definition about the same goes here." },
]

export const TEMPLATE_APP = {
    APP_ID : 'app_id',
    TEMPLATE_ID : 'template_id' ,
    APP_NAME : 'app_name' ,
    APP_DESCRIPTION : 'app_description' ,
    DEVELOPER_TEAM : 'developer_team' ,
    PROCESS_OWNER : 'process_owner' ,
    GRC_CONTROL_OWNER : 'grc_control_owner' ,
    URL : 'url' ,
    FREE_FORM_METADATA : 'free_form_metadata',
    ARD: 'ard' ,
    FLASH_INFO_DESCRIPTION: 'flash_info_description' ,
    DEV_ALERT_MESSAGES: 'dev_alert_messages',
    DATASOURCE: 'data_source' ,
    INPUT_SOURCE_TYPE: 'input_source_type' ,
    LAUNCH_MCM: 'launch_mcm' ,
    LAUNCH_QQ_YY: 'launch_qq_yy' ,
    LEDGER_INFO: 'ledger_info' ,
    OUTPUT: 'output',
    PDD: 'pdd' ,
    ANNUAL_MER_SAVINGS: 'annual_mer_savings' ,
    PROCESS_MJE_COUNT: 'mje_count' ,
    CADENCE: 'cadence' ,
    CHART_OF_ACCOUNT: 'chart_of_accounts',
    AUTOMATION_TOOLS: 'automation_tools',
    REGION: 'region',
    STATUS: 'status',
    TDD: 'tdd',
    VP: 'vp',
    ORGANIZATION: 'organization',
    ACCOUNTING_TEAM: 'accounting_team',
    PERMISSION: 'permission',
    CREATE_TIME_STAMP: 'created_time_stamp',
    CREATED_BY: 'created_by',
    LAST_UPDATED_TIME_STAMP: 'last_updated_time_stamp',
    LAST_UPDATED_BY: 'last_updated_by',
    SERVICE: 'service',
    SIM_TICKET_URL: 'sim_ticket_url',
    SIM_TICKET_LABEL: 'sim_ticket_label',
    SOURCE_DATA_TABLES: 'source_data_tables',
    USE_CASE: 'use_case',
    WORKFLOWS: 'workflows',
    FINANCIAL_YEAR_IMPACT: 'financial_year_impact',
    GRC_CONTROL_ID: 'grc_control_id',
    FINANCIAL_RELEVANCE: 'financial_relevance',
    TEAM_SUB: {
        TEAM: 'team',
        EMAIL: 'email'
    },
    OUTPUT_SUB: {
        TYPE: 'type',
        VALUE: 'value',
        LABEL: 'label'
    }
}

export const QSC_APP_SPECIFIC_FIELDS = {
    MIGRATION_STATUS : 'migration_status',
    L7_APPROVER : 'l7_approver',
    DEVELOPER_ALIAS : 'developer_alias',
    QSC_DATASOURCE: 'qsc_datasource',
    FGBSBI_GAMMA_DATASET_URL: 'fgbsbi_gamma_dataset_url',
    FGBSBI_GAMMA_ANALYSIS_URL: 'fgbsbi_gamma_analysis_url',
    FGBSBI_GAMMA_DASHBOARD_URL: 'fgbsbi_gamma_dashboard_url',
    FGBSBI_PROD_DASHBOARD_URL: 'fgbsbi_prod_dashboard_url',
    OTHER_BENEFIT: 'other_benefit',
    DOWNSTREAM_USE: 'downstream_use',
    FDV_QUERY_NAME: 'fdv_query_name',
    FDV_DATASET_URL: 'fdv_dataset_url',
    GRC_SIM_TICKET: 'grc_sim_ticket',
    GAMMA_ANALYSIS_AND_DASHBOARD_SHARED :'gamma_analysis_and_dashboard_shared',
    PRIMARY_OR_SECONDARY_LEDGER_IMPACTING: 'primary_or_secondary_ledger_impacting',
    FINANCIAL_IMPACT_GREATER_THAN_500M_USD: 'financial_impact_greater_than_500m_usd',
    RESULT_IN_JE_ACC_RECON_FSD_KC: 'result_in_je_or_account_recon_or_fs_disclosure_or_key_control',

}
export const TEMPLATE_APP_CHART_OF_ACCOUNTS = {
    FPA_DIM_ACCOUNTS: 'fpa_dim_accounts',
    CHART_OF_ACCOUNTS_PK: 'chart_of_accounts_pk'
}
export const TEMPLATE_APP_PERMISSION = {
    LINK: 'link',
    NAME: 'name',
    APP_PERMISSION_PK: 'app_permission_pk'
}
export const TEMPLATE_APP_SOURCE_DATA_TABLES = {
    UPSTREAM_SOURCE: 'upstream_source',
    SCHEMA: 'source_schema',
    TABLE: 'source_table',
    DESCRIPTION: 'description',
    DATABASE: 'source_database',
    SOURCE_TABLE_PK: 'source_table_pk'

}
export const TEMPLATE_APP_OUTPUT = {
    TYPE: 'type',
    VALUE: 'value',
    LABEL: 'label',
    AWS_ACCOUNT: 'aws_account',
    APP_OUTPUT_PK: 'app_output_pk'
}
export const TEMPLATE_APP_TEAM = {
    TEAM: 'team',
    EMAIL: 'email'
}
export const TEMPLATE_APP_USE_CASE = {
    ARD: 'uc_ard' ,
    COMMENTS: 'uc_comments' ,
    DATASOURCE_DETAILS: 'uc_data_source_details' ,
    DESCRIPTION: 'uc_description' ,
    INPUT_SOURCE_TYPES: 'uc_input_source_types' ,
    LAUNCH_MCM: 'uc_launch_mcm' ,
    LAUNCH_QQ_YY: 'uc_launch_qq_yy' ,
    LEDGER_IMPACT: 'uc_ledger_impact' ,
    NAME: 'uc_name' ,
    OUTPUT_LINK: 'uc_output_link' ,
    OUTPUT_TYPE: 'uc_output_type' ,
    PDD: 'uc_pdd' ,
    PROCESS_ANNUAL_MER_SAVINGS: 'uc_process_annual_mer_savings' ,
    PROCESS_MJE_COUNT: 'uc_process_mje_count' ,
    PROCESS_RUN_FREQUENCY: 'uc_process_run_frequency' ,
    AUTOMATION_TOOLS: 'uc_automation_tools',
    REGIONS: 'uc_regions',
    STATUS: 'uc_status',
    TDD: 'uc_tdd',
    TYPE: 'uc_type' ,
    FREE_FORM_METADATA: 'uc_free_form_metadata'
}

export const TEMPLATE_APP_ACCOUNTING = {
    DEVELOPER_TEAM : 'acc_developer_team',
    TEAM: 'acc_team',
    EMAIL: 'acc_email',
    VP: 'acc_vp',
    PROCESS_OWNER_ALIAS: 'acc_process_owner_alias',
    ORGANIZATION: 'acc_organization',
    FREE_FORM_METADATA: 'acc_free_form_metadata'
}

export const TEMPLATES = {
    MAPLE: '01H8YZ31RPXS11HFABMHA6J4F0',
    ABC: '01H8YZ31RPXS11HFABMHA6J4F0',
    QSC: '01JA95981ME0AQ39TNMZ2J8STD'
}

export const APP_STATUSES = {
    DEV: {
        Active: '177',
        Deprecated: '178',
        WIP: '443'
    },
    BETA: {
        Active: '177',
        Deprecated: '178',
        WIP: '443'
    },
    PROD: {
        Active: '178',
        Deprecated: '179',
        WIP: '349'
    }
}

export const APP_TYPES = {
   '01H8YZ31RPXS11HFABMHA6J4F0' : 'ABC',
   '01JA95981ME0AQ39TNMZ2J8STD' : 'QSC'
}

export const APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE = {
    DEV: ['888'],
    BETA: ['888'],
    PROD: []
}