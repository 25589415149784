import { FEATURED_ABC_APP } from '../constants/AppConstants'
import { API, graphqlOperation } from "aws-amplify";

export const addFeatured = async (appId, label, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
        createUserPreferences(input: {label: "${label}", preference_identifier: "${appId}", user_and_preference: "SYSTEM_USER#${FEATURED_ABC_APP}"}) {
          preference_identifier
        }
      }
      `
  await executeQuery(qry, onSuccess, onFailure)

}

export const delFeatured = async (appId, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    deleteUserPreferences(input: {preference_identifier: "${appId}", user_and_preference: "SYSTEM_USER#${FEATURED_ABC_APP}"}) {
      preference_identifier
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)

}

export const listFeatured = async (onSuccess, onFailure) => {
  let qry = `query MyQuery {
    listUserPreferences(limit: 10000, filter: {user_and_preference: {eq: "SYSTEM_USER#${FEATURED_ABC_APP}"}}) {
      items {
        preference_identifier
        label
      }
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)
}

export const getFeatured = async (appId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getUserPreferences(preference_identifier: "${appId}", user_and_preference: "SYSTEM_USER#${FEATURED_ABC_APP}") {
      preference_identifier
      label
      user_and_preference
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)
}

const executeQuery = async (qry, onSuccess, onFailure) => {
  await API.graphql(graphqlOperation(qry)).then(result => {
    onSuccess(result)
  }, function (error) {
    onFailure(error)
  });
}

export const checkIsFeatured = async (appId, onResult) => {
  await getFeatured(appId, function onSuccess(result) {
    if (result?.data?.getUserPreferences?.preference_identifier === appId) {
      onResult(true)
    } else {
      onResult(false)
    }
  },
    function onFailure(result) {
      onResult(false)
    })
}

export const featured = async (appId, onAdded, onDeleted, onFailure) => {
  await checkIsFeatured(appId, function (isFeatured){
    if (isFeatured) {
      delFeatured(appId, function (result) {
        onDeleted(result)
      }, function (result) { 
        onFailure(result)
      })
    } else {
      addFeatured(appId, "default", function (result) {
        onAdded(result)
      }, function (result) { 
        onFailure(result)
      })
    }
  })
}