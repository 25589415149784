import React, { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import { Link, useLocation, useParams } from "react-router-dom";
import favLogo from '../images/star-regular-brown.svg'
import favLogoSolid from '../images/star-solid-brown.svg'
import { useAuth } from '../context/AuthContextProvider';


import { ADD_APP_ACCESS_LEVELS } from 'src/constants/AppConstants'
import ApprovalsHome from '../Approvals/approvalsHome';


import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  Box,
  Cards,
  Button,
  Header,
  Table,
  Badge,
  Autosuggest,
  ExpandableSection,
  SpaceBetween,
  Multiselect,
  ColumnLayout,
  Tabs,
  Pagination,
  Spinner,
  ButtonDropdown,
  CopyToClipboard,
  ContentLayout,
  Icon,
  PieChart,
  Select,
  HelpPanel,
  Grid,
  Steps,
  Popover,
  SegmentedControl,
  TextContent,
  Toggle
} from "@amzn/awsui-components-react";

import "./homepage.scss";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { addFavorite, listFavorites, delFavorite, getFavorite } from '../utilities/Favorites'
import Favorite from 'src/generic-components/Favorite';
import { TEMPLATE_APP, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_ACCOUNTING, TEMPLATE_APP_USE_CASE, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import { API, graphqlOperation } from "aws-amplify";
import { getEditableFields } from "src/Ateam-BI/Templates/Utils/FormMetadata";
import { setCurrentAppId } from "src/store/userAuthSlice";
import { setOrganization } from "src/store/userAuthSlice";
import { getAccessLevel } from "src/checkPageAccess"
import { TEMPLATES, APP_TYPES, APP_STATUSES } from 'src/Ateam-BI/Templates/Constants';
import Featured from "src/generic-components/Featured";

import {  getSuggestedLogins } from "../Ateam-BI/Templates/Utils/AppActivityUtils"


const AllProducts = (props) => {
  const history = useHistory();
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const userAuthenticationDetails = useAuth();
  const firstName = userAuthenticationDetails.GivenName;
  const userId = useSelector((globalState) => globalState.auth.userId);
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const [qscAddAppAccessDisabled, setQscAddAppAccessDisabled] = useState(true)
  const [abcAddAppAccessDisabled, setAbcAddAppAccessDisabled] = useState(true)
  const ln = useLocation()


  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [isProducsLoading, setIsProducsLoading] = useState(true);
  const [toolsHide, setToolsHide] = useState(true);

  const [filteringOptions, setFilteringOptions] = useState([]);
  const [NavOpen, setNavOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [value, setValue] = useState("");

  const [selectedOptions, setSelectedOptions] = useState();

  const [organizations, setOrganizations] = useState([]);
  const [useCaseTypes, setUseCaseTypes] = useState([]);
  const [statuses, setStatuses] = useState([])
  const [allAutomationTools, setAllAutomationTools] = useState([])
  const [allAccountingVps, setAllAccountingVps] = useState([]);
  const [processOutputs, setProcessOutputs] = useState([]);
  const [appTypes, setAppTypes] = useState([]);


  const [allSuggestions, setAllSuggestions] = useState([])
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [favoritesMap, setFavoritesMap] = useState({});
  const [favDisabled, setFavDisabled] = useState(false);
  const [activeTabId, setActiveTabId] = useState(false);
  const [changeToggle, setChangeToggle] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pagesCount, setPagesCount] = useState()
  const [pageItems, setPageItems] = useState()
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [loader, setLoader] = useState(false);
  const [loaderRequestsQueue, setLoaderRequestsQueue] = useState(new Set());
  const [view, setView] = useState('Grid')
  const qryRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false)
  const currentHour = (new Date()).getHours()
  const [greeting, setGreeting] = useState(() => {
    if (currentHour < 12) {
      return "Good Morning"
    } else if (currentHour < 18) {
      return "Good Afternoon"
    } else {
      return "Good Evening"
    }
  })
  
  const [aliasesLoading, setAliasesLoading] = useState(false)
  const [suggestedLogins, setSuggestedLogins] = useState([])
  

  const { org } = useParams()
  const [ownedBy, setOwnedBy] = useState({label:`Default`, value:``})

  const [pieChartItems, setPieChartItems] = useState([])


  const dispatch = useDispatch();
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const [orgTogglechecked,setOrgToggleChecked] = useState( org ? true : false)

  useEffect(() => {
    const handleFilters = async () => {
      await queryApps()
    }
    handleFilters()

  }, [searchParams.toString()])

  useEffect(() => {
    loadProducts();
    setActiveTabId(searchParams.get("section") ? searchParams.get("section") : 'default')

    return () => {
      // this now gets called when the component unmounts
      dispatch(setOrganization())
    };
  }, []);

  useEffect(() => {
    dispatch(setCurrentAppId(''));
  }, [])

  useEffect(() => {
    checkAddAppAccesses()
  }, [userId, currentStage])

  useEffect(() => {
    dispatch(setOrganization(org))
    if(org) {
      setOrgToggleChecked(true)
    } else {
      setOrgToggleChecked(false)
    }
  }, [org])

  useEffect(()=>{
    if(typeof(orgTogglechecked) === 'boolean' && orgTogglechecked === false && org){

        if(searchParams.toString()) {
          history.push(`/?${searchParams.toString()}`)
        } else {
          history.push(`/`)
        }
    } else {
      setOwnedBy()
    }
  },[orgTogglechecked])


  useEffect(() => {
    setPageItems(
      filteredProducts
        .slice(20 * (currentPageIndex - 1), 20 * (currentPageIndex))
    )


    const constItemsMap = {}
    filteredProducts?.forEach((item) => {
      if (item?.organization?.length) {
        for (const org of item?.organization) {
          if (constItemsMap.hasOwnProperty(org)) {
            constItemsMap[org]['value'] = constItemsMap[org]['value'] + 1
          } else {
            constItemsMap[org] = { 'title': org, 'value': 1 }
          }
        }
      } else {
        if (constItemsMap.hasOwnProperty('N/A')) {
          constItemsMap['N/A']['value'] = constItemsMap['N/A'][value] + 1
        } else {
          constItemsMap['N/A'] = { 'title': "N/A", 'value': 1 }
        }
      }
    })
    const chartItems = Object.values(constItemsMap)

    if (chartItems?.length > 0) {
      setPieChartItems(chartItems)
    }

  }, [currentPageIndex, filteredProducts])


  useEffect(() => {
 
    const handleChange = async () => {
      await queryApps()
    }
    handleChange()

  }, [ln.pathname, ownedBy, org])

  useEffect(() => {
    const orgs = fieldMetadata[TEMPLATE_APP.ORGANIZATION]?.values;
    setOrganizations(orgs);


    const types = fieldMetadata[TEMPLATE_APP.USE_CASE]?.values;
    setUseCaseTypes(types);

    const processOutputs = fieldMetadata[TEMPLATE_APP.OUTPUT]?.values;
    setProcessOutputs(processOutputs);

    const appTypes = fieldMetadata[TEMPLATE_APP.TEMPLATE_ID]?.values;
    setAppTypes(appTypes);

    const allStatuses = fieldMetadata[TEMPLATE_APP.STATUS]?.values;
    setStatuses(allStatuses);

    const allAppAutomationTools = fieldMetadata[TEMPLATE_APP.AUTOMATION_TOOLS]?.values;
    setAllAutomationTools(allAppAutomationTools);
  }, [fieldMetadata])

  useEffect(() => {

    const orgs = fieldMetadata[TEMPLATE_APP.ORGANIZATION]?.values;
    setOrganizations(orgs);


    const types = fieldMetadata[TEMPLATE_APP.USE_CASE]?.values;
    setUseCaseTypes(types);

    const processOutputs = fieldMetadata[TEMPLATE_APP.OUTPUT]?.values;
    setProcessOutputs(processOutputs);

    const appTypes = fieldMetadata[TEMPLATE_APP.TEMPLATE_ID]?.values;
    setAppTypes(appTypes);

    generateFilterDropdown(TEMPLATE_APP.VP);

    generateAppTypesFilterDropdown();

    const suggestions = products.map((product) => (
      {
        value: product.app_name,
        description: product.app_description
      }
    ))
    setAllSuggestions(suggestions)
    setSearchSuggestions(suggestions)

    setPagesCount(Math.ceil(products.length / 20))
  }, [products]);


  const checkAddAppAccesses = async () => {
    if (userId && currentStage) {
      let abcAccess = await getAccessLevel(userId, TEMPLATES.MAPLE, currentStage)
      let qscAccess = await getAccessLevel(userId, TEMPLATES.QSC, currentStage)
      setAbcAddAppAccessDisabled(!ADD_APP_ACCESS_LEVELS.includes(abcAccess?.appAccessLevel))
      setQscAddAppAccessDisabled(!ADD_APP_ACCESS_LEVELS.includes(qscAccess?.appAccessLevel))
    }

  }


  const generateFilterDropdown = async (dynamoDBKey) => {

    // Get this values from DB at all times, cannot use the values from previous response because they might have been filtered responses already
    let mQry = `
    query MyQuery {
      getAppsV2  {
          ${TEMPLATE_APP.VP}
      }
    }  
        `;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    const tempFiltered = qResultJSON
      .filter(
        (p) =>
          p[dynamoDBKey] &&
          p[dynamoDBKey] !== "" &&
          p[dynamoDBKey] !== "To be updated"
      );
    const dropDown = []
    let valueSet = new Set()
    tempFiltered.forEach((qResultJSON) => {

      if (qResultJSON.hasOwnProperty(dynamoDBKey)) {

        qResultJSON[dynamoDBKey].forEach((val) => {
          (val == 'nan') ? '' : valueSet.add(val.trim())
        })
      }
    });

    valueSet.forEach((val) => {
      dropDown.push({
        label: val,
        value: val
      });
    })

    setAllAccountingVps(dropDown);
  }

  const generateAppTypesFilterDropdown = async () => {
    let mQry = `
    query MyQuery {
      getAppsV2  {
          ${TEMPLATE_APP.TEMPLATE_ID}
      }
    }  
        `;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    const tempFiltered = qResultJSON
      .filter(
        (p) =>
          p[TEMPLATE_APP.TEMPLATE_ID] &&
          p[TEMPLATE_APP.TEMPLATE_ID] !== "" &&
          p[TEMPLATE_APP.TEMPLATE_ID] !== "To be updated"
      );
    const dropDown = []
    let valueSet = new Set()
    tempFiltered.forEach((qResultJSON) => {

      if (qResultJSON.hasOwnProperty(TEMPLATE_APP.TEMPLATE_ID)) {

        (qResultJSON[TEMPLATE_APP.TEMPLATE_ID] == 'nan') ? '' : valueSet.add(qResultJSON[TEMPLATE_APP.TEMPLATE_ID].trim())
      }
    });

    valueSet.forEach((val) => {
      dropDown.push({
        label: APP_TYPES[val],
        value: val
      });
    })
    setAppTypes(dropDown)
  }

  const clearFavoriteMap = () => {
    let current_keys = Object.keys(favoritesMap)
    current_keys.forEach((key) => {
      delete favoritesMap[key]
    });
  }

  const makeFavoriteMap = (items) => {
    items.forEach((item, index) => {
      favoritesMap[item.preference_identifier] = true
    });
  }

  const getAllFavorites = async () => {
    listFavorites(userId, function onSuccess(result) {
      clearFavoriteMap()
      makeFavoriteMap(result.data.listUserPreferences.items)
      setFavDisabled(false)
    },
      function onFailure(result) {

      })
  }

  const isFavorite = async (appId) => {
    getFavorite(appId, userId, function onSuccess(result) {
      favoritesMap[appId] = true
    },
      function onFailure(result) {
        favoritesMap[appId] = false
      })
  }

  const getItemsForFilterFromDropdown = (items) => {
    let res = []
    items.forEach((item) => {
      res.push(item.value)
    })
    return res
  }

  const getFilters = async () => {
    let qry = []
    let filtersApplied = []
    if (searchParams.get('searchQuery')) {
      qry.push(`searchString: "${searchParams.get('searchQuery')}"`)
    }

    if (searchParams.getAll('types')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.USE_CASE}",values:${JSON.stringify(searchParams.getAll('types'))}}`)
    }
    if (searchParams.getAll('outputs')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.OUTPUT}",values:${JSON.stringify(searchParams.getAll('outputs'))}}`)
    }
    if (searchParams.getAll('appTypes')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.TEMPLATE_ID}",values:${JSON.stringify(searchParams.getAll('appTypes').map((appType) => TEMPLATES[appType]))}}`)
    }
    if (searchParams.getAll('vps')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.VP}",values:${JSON.stringify(searchParams.getAll('vps'))}}`)
    }
    if (searchParams.getAll('tools')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.AUTOMATION_TOOLS}",values:${JSON.stringify(searchParams.getAll('tools'))}}`)
    }

    if (searchParams.getAll('status')?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.STATUS}",values:${JSON.stringify(searchParams.getAll('status').map((status)=> APP_STATUSES[currentStage?.toUpperCase()][status]))}}`)
    }

    if(ownedBy && ownedBy?.value) {
      filtersApplied.push(`{type:"owned_by", values:${JSON.stringify([ownedBy.value])}}`)
    }

    if (searchParams.getAll('orgs')?.length > 0 && !ln.pathname.startsWith('/Org')) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.ORGANIZATION}",values:${JSON.stringify(searchParams.getAll('orgs'))}}`)
    } else if ((ln.pathname.startsWith('/Org') || ln.pathname.startsWith('/org')) && org) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.ORGANIZATION}",values:${JSON.stringify([org])}}`)
    }


    if (filtersApplied?.length > 0) {
      let tempFilterQry = ''
      tempFilterQry = tempFilterQry + '['
      tempFilterQry = tempFilterQry + filtersApplied.join(',')
      tempFilterQry = tempFilterQry + ']'
      qry.push(`filters:${tempFilterQry}`)
    }
    if (qry.length > 0) {
      return `(${qry.join(",")})`
    } else {
      return ''
    }

  }

  const loadProducts = async () => {
    setLoader(true)
    await getAllFavorites()
    await loadSearchDropDownValues();
  };

  const queryApps = async () => {
    setLoader(true)
    setIsProcessing(true)
    const filters = await getFilters()
    let mQry = `
    query MyQuery {
      getAppsV2 ${filters} {
        ${TEMPLATE_APP.APP_DESCRIPTION}
          ${TEMPLATE_APP.APP_NAME}
          ${TEMPLATE_APP.APP_ID}
          ${TEMPLATE_APP.TEMPLATE_ID}
          ${TEMPLATE_APP.URL}
          ${TEMPLATE_APP.PROCESS_OWNER}
          ${TEMPLATE_APP.GRC_CONTROL_OWNER}
          ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
          ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
          ${TEMPLATE_APP.TDD}
          ${TEMPLATE_APP.STATUS}
          ${TEMPLATE_APP.REGION}
          ${TEMPLATE_APP.CADENCE}
          ${TEMPLATE_APP.PROCESS_MJE_COUNT}
          ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
          ${TEMPLATE_APP.PDD}
          ${TEMPLATE_APP.LEDGER_INFO}
          ${TEMPLATE_APP.LAUNCH_MCM}
          ${TEMPLATE_APP.LAUNCH_QQ_YY}
          ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
          ${TEMPLATE_APP.AUTOMATION_TOOLS}
          ${TEMPLATE_APP.ARD}
          ${TEMPLATE_APP.DATASOURCE}
          ${TEMPLATE_APP.ORGANIZATION}
          ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
          ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
          ${TEMPLATE_APP.GRC_CONTROL_ID}
          ${TEMPLATE_APP.VP}
          ${TEMPLATE_APP.CREATE_TIME_STAMP}
          ${TEMPLATE_APP.CREATED_BY}
          ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
          ${TEMPLATE_APP.LAST_UPDATED_BY}
          ${TEMPLATE_APP.SIM_TICKET_URL}
          ${TEMPLATE_APP.SIM_TICKET_LABEL}
          ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
          }
          ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
          }
          ${TEMPLATE_APP.USE_CASE}
          ${TEMPLATE_APP.PERMISSION}{
            ${TEMPLATE_APP_PERMISSION.LINK}
            ${TEMPLATE_APP_PERMISSION.NAME}
            ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
          }
          ${TEMPLATE_APP.DEVELOPER_TEAM}{
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
          ${TEMPLATE_APP.OUTPUT}{
            ${TEMPLATE_APP_OUTPUT.TYPE}
            ${TEMPLATE_APP_OUTPUT.VALUE}
            ${TEMPLATE_APP_OUTPUT.LABEL}
            ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
          }
          ${TEMPLATE_APP.ACCOUNTING_TEAM} {
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
      }
    }  
        `;
    // latest qry
    qryRef.current = mQry

    var utc = Date.now();
    // console.log("-----START QUERY------")
    // console.log(utc)
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    // console.log("-----END QUERY------")
    // console.log(utc, Date.now()-utc)


    if (qryRef.current == mQry) {
      setProducts(qResultJSON);
      setFilteredProducts(qResultJSON);
      setLoader(false)
      setIsProcessing(false)
      setCurrentPageIndex(1)
      setIsProducsLoading(false);
    }

  }

  const loadSearchDropDownValues = async () => {
    let res = await getEditableFields()
    setFieldMetadata(res)
  }
  const favorite = async (appId) => {
    await isFavorite(appId)
    setFavDisabled(true)
    if (favoritesMap && favoritesMap[appId]) {
      await delFavorite(appId, userId, function onSuccess(result) {
        delete favoritesMap[appId]
      }, function onFailure(result) { })
    } else {
      await addFavorite(appId, userId, "default", function onSuccess(result) {
        favoritesMap[appId] = true
      }, function onFailure(result) { })
    }
    setFavDisabled(false)
  }

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };
  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "Apps", href: "" },
  ];

  const getCommaSeperatedOutputs = (item) => {
    let types = []
    item.output.forEach((output) => {
      types.push(output.type)
    })
    return types.join(', ')
  }

  const getUrl = (item) => {
    if (item.url) {
      return item.url
    } else {
      return `App/${item.app_id}`
    }
  }

   const getSuggestedLoginsCaller = async (fragment, size = 10) => {
      setSuggestedLogins([])
      setAliasesLoading(true)
      const response = await getSuggestedLogins(fragment, size)
      const data = JSON.parse(response.data.getSuggestedLogins)
      let suggestions = data["suggestions"]
      suggestions = suggestions.map((login) =>
      ({
          label: login?.toLowerCase(),
          value: login?.toLowerCase()
      }))
      suggestions.push(
        {
          label: `Default`,
          value: ``
      }
      )
      setSuggestedLogins(suggestions)
      setAliasesLoading(false)
      return suggestions
  }

  const topView = (
    
      <div className="top-view-alias-org">
              <Box>
                {!orgTogglechecked && (
                  <>
                  View as:

                  <Select
                        selectedOption={ownedBy? {label:`${ownedBy.label}`, value:`${ownedBy.value}`} : {label:`Default`, value:``}}
                        onChange={({ detail }) =>
                        setOwnedBy(detail.selectedOption)
                        }
                        placeholder="Select Alias"
                        options={suggestedLogins}
                        statusType={aliasesLoading ? "loading" : "finished"}
                        filteringType="manual"
                        loadingText="Loading aliases"
                        empty="No valid alias to recommend for entered text"
                        onLoadItems ={({detail})=>{
                          
                         getSuggestedLoginsCaller(detail?.filteringText)
                          
                        }}
                    />

                  {ownedBy && ownedBy?.value && <small>{`Apps owned by ${ownedBy?.value} or by people who report directly or indirectly to ${ownedBy?.value}`}</small>}
                </>
                )}
             

              </Box>

          <Box>
            <Toggle
      onChange={({ detail }) =>
        setOrgToggleChecked(detail.checked)
      }
      checked={orgTogglechecked}
    >
      <Box>
      <small>View by organization</small>
      </Box>
      </Toggle>
      <Select
      selectedOption={org? {label:`${org}`, value:`${org}`} : {label:`All`, value:``} }
      onChange={({ detail }) =>
        history.push(`/Org/${detail.selectedOption.value}`)
      }
      options={organizations}
      disabled={!orgTogglechecked}
    />

      </Box>
      </div>
    
  )

  const AllApps = (
        <>
          <Autosuggest
            onChange={({ detail }) => {
              searchParams.delete(`searchQuery`)
              if (detail.value) {
                searchParams.append(`searchQuery`, detail.value)
              }
              history.replace({
                ...history.location.search,
                search: `${searchParams.toString()}`,
              })

            }
            }
            value={searchParams.get('searchQuery') ? searchParams.get('searchQuery') : ''}
            placeholder={`Search ${org ? org : `all`} applications`}
            enteredTextLabel={(value) => `Searching: ${value}`}
            options={searchSuggestions}
            virtualScroll
          />
          <br />
          <Box>
            <ExpandableSection
              variant="default"
              headerText="Filter Options"
            >
              <ColumnLayout columns={4}>
                {ln.pathname?.startsWith('/Org') && org ? (
                  <></>
                ) : (
                  <Box>
                    <Box variant="awsui-key-label">Organizations</Box>
                    <Multiselect
                      selectedOptions={searchParams.getAll('orgs').map((org) => (
                        {
                          label: org,
                          value: org
                        }
                      ))}
                      onChange={({ detail }) => {
                        // console.log(history.location.search)
                        searchParams.delete(`orgs`)
                        detail.selectedOptions?.forEach((org) => searchParams.append(`orgs`, org.value))

                        history.replace({
                          ...history.location.search,
                          search: `${searchParams.toString()}`,
                        })

                      }
                      }
                      options={organizations}
                      placeholder="Filter on Organizations"
                    />
                  </Box>)}

                <Box>
                  <Box variant="awsui-key-label">Use Case Types</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('types').map((type) => (
                      {
                        label: type,
                        value: type
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`types`)
                      detail.selectedOptions?.forEach((type) => searchParams.append(`types`, type.value))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={useCaseTypes}
                    placeholder="Filter on Use Case Types"
                  />
                </Box>

                <Box>
                  <Box variant="awsui-key-label">App status</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('status').map((status) => (
                      {
                        label: status,
                        value: status
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`status`)
                      detail.selectedOptions?.forEach((status) => searchParams.append(`status`, status.value))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={statuses}
                    placeholder="Filter on app status"
                  />
                </Box>

                <Box>
                  <Box variant="awsui-key-label">Automation tools</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('tools').map((tool) => (
                      {
                        label: tool,
                        value: tool
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`tools`)
                      detail.selectedOptions?.forEach((tool) => searchParams.append(`tools`, tool.value))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={allAutomationTools}
                    placeholder="Filter on automation tools"
                  />
                </Box>


                {/* <Box>
                  <Box variant="awsui-key-label">Accounting VPs</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('vps').map((vp) => (
                      {
                        label: vp,
                        value: vp
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`vps`)
                      detail.selectedOptions?.forEach((vp) => searchParams.append(`vps`, vp.value))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={allAccountingVps}
                    placeholder="Filter on Accounting VPs"
                  />
                </Box> */}


                {/* <Box>
                  <Box variant="awsui-key-label">Process output</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('outputs').map((output) => (
                      {
                        label: output,
                        value: output
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`outputs`)
                      detail.selectedOptions?.forEach((output) => searchParams.append(`outputs`, output.value))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={processOutputs}
                    placeholder="Filter on process outputs"
                  />
                </Box> */}

                {/* <Box>
                  <Box variant="awsui-key-label">App Type</Box>
                  <Multiselect
                    selectedOptions={searchParams.getAll('appTypes').map((appType) => (
                      {
                        label: `${appType}`,
                        value: TEMPLATES[appType]
                      }
                    ))}
                    onChange={({ detail }) => {
                      // console.log(history.location.search)
                      searchParams.delete(`appTypes`)
                      detail.selectedOptions?.forEach((appType) => searchParams.append(`appTypes`, appType.label))

                      history.replace({
                        ...history.location.search,
                        search: `${searchParams.toString()}`,
                      })

                    }
                    }
                    options={appTypes}
                    placeholder="Filter on App type"
                  />
                </Box> */}


              </ColumnLayout>
            </ExpandableSection>
          </Box>
          <br></br>
          {loader ? (<Box>
      <Spinner></Spinner>
      Loading...
    </Box>) : (
      <>
      {view == 'Grid' ? <Cards
            cardDefinition={{
              header: item => (
                <div className="header_menu">
                  <div className="link">
                    <Link to={getUrl(item)}> {item.app_name}</Link>
                  </div>
                  <div className={favDisabled ? "icon_disabled" : "icon"} disabled={favDisabled} >
                    {favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(item.app_id)}></img>)}
                    {!favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(item.app_id)}></img>)}
                  </div>
                </div>
              ),
              sections: [
                {
                  id: "app_description",
                  header: "",
                  content: (item) => {
                    if(item?.app_description?.length < 200) {
                      // let spaces = '\u00A0'.repeat(200 - item?.app_description?.length)
                      return <>{item?.app_description}</>
                    } else {
                      return `${item?.app_description?.slice(0, 200)}...`
                    }
                    },
                },
                {
                  id: "labels",
                  content: (item) => { 
                  let list = []
                if(item.status) {
                  if(item.status === 'Deprecated') {
                    list.push(<span className="card-label-red"><Icon name="status-negative" variant="error" />&nbsp;{item.status}</span>)
                  } else if(item.status === 'WIP') {
                    list.push(<span className="card-label-grey"><Icon name="status-pending" variant="normal" />&nbsp;{item.status}</span>)
                  } else if(item.status === 'Active') {
                    list.push(<span className="card-label-green"><Icon name="status-positive" variant="success" />&nbsp;{item.status}</span>)
                  }
                } else {
                  list.push(<span className="card-label-yellow"><Icon name="status-warning" variant="warning" />&nbsp;No status</span>)
                }
                let useCaseTypes = []
                if (item.use_case) {
                  useCaseTypes = item.use_case?.forEach((i) =>  list.push(<span className="card-label-blue">{i}</span>))
                }
                const labels = list.concat(useCaseTypes)
                return (<ul  style={{ listStyleType: "none", paddingLeft: "0px" }}><SpaceBetween direction="horizontal" size="m">{labels.map((label, index) => (
                <li key={index}>{label}</li>
                ))}</SpaceBetween></ul>)
                }}
                // ,  
                
                // {
                //   id: "useCaseType",
                //   content: (item) => (item.use_case ? <ul style={{ listStyleType: "none", paddingLeft: "0px" }}><SpaceBetween direction="horizontal" size="m">{item?.use_case?.map((useCase, index) => (
                //     <li key={index}><span className="card-label-blue">{useCase}</span></li>
                //   ))}</SpaceBetween> </ul> : "Not Available"),
                  
                // }
              ]
            }}
            header={
              <Header
                actions={

                  (!(abcAddAppAccessDisabled && qscAddAppAccessDisabled) ? 
                      <ButtonDropdown
                      items={[
                        { text: "ABC App", id: "ABC_APP", "href": "/App", disabled: abcAddAppAccessDisabled },
                        { text: "QSC App", id: "QSC_APP", "href": "/QSCApp", disabled: qscAddAppAccessDisabled }
                      ]}
                      variant="primary"
                    // onItemClick={(event) => addAppClick(event)}
                    disabled={abcAddAppAccessDisabled && qscAddAppAccessDisabled}
                    >
                      Add an application
                    </ButtonDropdown> : <></>
                    )
                }

              >
                <SpaceBetween direction="horizontal" alignItems="center"> <span style={{color: "#656871"}}>Add an app to your favorites by clicking</span><img className="favorite_star" src={favLogo}></img></SpaceBetween>
              
              </Header>
            }
            stickyHeader
            cardsPerRow={[{ minWidth: 900, cards: 4 }]}
            items={pageItems}
            loading={isProducsLoading}
            loadingText="Loading resources"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No applications</b>
                <Box
                  padding={{ bottom: "s" }}
                  variant="p"
                  color="inherit"
                >
                  No applications to display.
                </Box>
              </Box>
            }

            pagination={
              <Pagination currentPageIndex={currentPageIndex}
                onChange={({ detail }) =>
                  setCurrentPageIndex(detail.currentPageIndex)
                }
                pagesCount={pagesCount} />
            }
            filter = {
              <TextContent> <h4>Results <span style={{color: "#656871"}}>{pageItems
                ? "(" + filteredProducts.length + ")"
                : ""}
                </span>
                </h4>
            </TextContent>
            }
          /> :
            <Table
              columnDefinitions={[
                {
                  id: "fav",
                  cell: e => <div className={favDisabled ? "center_disabled" : "center"} disabled={favDisabled} >
                    {favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(e.app_id)}></img>)}
                    {!favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(e.app_id)}></img>)}
                  </div>,
                  width: 50,
                  isRowHeader: true
                },
                {
                  id: "name",
                  header: "Name",
                  cell: e => <Link to={getUrl(e)}> {e.app_name}</Link>,
                  width: 300,
                },
                {
                  id: "app_description",
                  header: "Description",
                  cell: e => e.app_description,
                  width: 350,
                },
                {
                  id: "status",
                  header: "Status",
                  cell: e => (e.status ? (
                        e.status === 'Active' ?
                      <><span><Icon name="status-positive" variant="success" />&nbsp;<span style={{color: "#00802f"}}>{e.status}</span></span><br/></>
                      : e.status === 'Deprecated' ? 
                      <><span ><Icon name="status-negative" variant="error" />&nbsp;<span style={{color: "#db0000"}}>{e.status}</span></span><br/></>
                      : e.status === 'WIP' ?
                      <><span><Icon name="status-pending" variant="normal" />&nbsp;<span style={{color: "#0f141a"}}>{e.status}</span></span><br/></>
                      :<></>
                    ) :  <span><Icon name="status-warning" variant="warning" />&nbsp;<span style={{color: "#855900"}}>No status</span></span>),
                    // (e.status ? e.status : "No status"),
                  
                  width: 200,
                },
                {
                  id: "organization",
                  header: "Organization",
                  cell: e => (e.organization ? e.organization.join(', ') : "Not Available"),
                  width: 200,
                },
                {
                  id: "useCaseType",
                  header: "Use Case Type",
                  cell: e => (e.use_case ? e.use_case.join(', ') : "Not Available"),
                  width: 200,
                },
                {
                  id: "processOutput",
                  header: "Process output",
                  cell: e => (e.output ? getCommaSeperatedOutputs(e) : "Not Available"),
                  width: 200,
                },
                {
                  id: "vp",
                  header: "VP",
                  cell: e => (e.vp ? e.vp.join(', ') : "Not Available"),
                  width: 200,
                }
              ]}
              items={pageItems}
              loadingText="Loading resources"
              resizableColumns
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No applications</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No applications to display.
                  </Box>
                </Box>
              }
              header={
                <Header
                  actions={
                    (!(abcAddAppAccessDisabled && qscAddAppAccessDisabled) ? 
                      <ButtonDropdown
                      items={[
                        { text: "ABC App", id: "ABC_APP", "href": "/App", disabled: abcAddAppAccessDisabled },
                        { text: "QSC App", id: "QSC_APP", "href": "/QSCApp", disabled: qscAddAppAccessDisabled }
                      ]}
                      variant="primary"
                    // onItemClick={(event) => addAppClick(event)}
                    disabled={abcAddAppAccessDisabled && qscAddAppAccessDisabled}
                    >
                      Add an application
                    </ButtonDropdown> : <></>
                    )
                   
                  }
                >
                  <SpaceBetween direction="horizontal" alignItems="center"> <span style={{color: "#656871"}}>Add an app to your favorites by clicking</span><img className="favorite_star" src={favLogo}></img></SpaceBetween>
                </Header>
              }
              pagination={
                <Pagination currentPageIndex={currentPageIndex}
                  onChange={({ detail }) =>
                    setCurrentPageIndex(detail.currentPageIndex)
                  }
                  pagesCount={pagesCount} />
              }
              filter={
                <TextContent> <h4>Results <span style={{color: "#656871"}}>{pageItems
                  ? "(" + filteredProducts.length + ")"
                  : ""}
                  </span>
                  </h4>
              </TextContent>
              }
            />
          }

      </>
    )    
    }
    </>
  )


  const onFavoriteChange = () => {
    //loadProducts()
    getAllFavorites()
  }
  const FavoriteUI = (
    <>
      {loader ? (<Box>
        <Spinner></Spinner>
        Loading...
      </Box>) : (<Box>
        <Favorite userId={userId} allApps={filteredProducts} changeToggle={onFavoriteChange} view={view} />
      </Box>)}
    </>
  )

  const FeaturedAppsUI = (
    <>
      {loader ? (<Box>
        <Spinner></Spinner>
        Loading...
      </Box>) : (<Box>
        <Featured userId={userId} allApps={filteredProducts} changeToggle={onFavoriteChange} view={view} />
      </Box>)}
    </>
  )
  const toolsContent = (<>
  </>)
  const Content = (
    <Box>
      <br></br>
      <ContentLayout
      >
        {topView}
        <Header>
          
          <div>
            <Box
              variant="h3"
              padding="n"
              fontSize="heading-l"
              color="inherit"
            >
              {greeting}, {firstName}!
              <br></br>
              <br></br>

            </Box>


          </div>
          {/* </SpaceBetween> */}
        </Header>
        <Container
        >
          {
            activeTabId !== `insights` && (
              <Box>
                <div class='views'>
                  <SpaceBetween size="m" direction="horizontal">
                    {/* <img className={view == 'Grid' ? 'button_selected' : 'button_unselected'} src={grid} onClick={() => setView('Grid')}></img>
                  <img className={view == 'List' ? 'button_selected' : 'button_unselected'} src={list} onClick={() => setView('List')}></img> */}
                    <SegmentedControl
                      selectedId={view}
                      onChange={({ detail }) =>
                        setView(detail.selectedId)
                      }
                      label="View segmented control"
                      options={[
                        { text: "Card view", id: "Grid" },
                        { text: "List view", id: "List" },
                      ]}
                    />
                  </SpaceBetween>
                </div>
              </Box>
            )
          }

          <Tabs
            tabs={[
              {
                label:`Close Central`,
                id: "closeCentral",
                content: <>Development in progress</>
              },
              {
                label: "Executive page",
                id: "executivePage",
                content: <>Development in progress</>
              },
              {
                label: org ? `${org} applications` : `All applications`,
                id: "default",
                content: AllApps
              },
              {
                label: "My favorites",
                id: "favorites",
                content: FavoriteUI
              },
              {
                label: "Featured",
                id: "featured",
                content: FeaturedAppsUI
              },
              {
                label: "Dashboards",
                id: "dashboards",
                content: <>Development in progress</>
              },
              {
                label: "Insights",
                id: "insights",
                content: <>
                  {filteredProducts?.length > 0 && (
                    <Box>

                      <PieChart
                        data={pieChartItems}
                        ariaDescription="Donut chart showing generic progress."
                        ariaLabel="Org donut chart"
                        hideFilter
                        // hideTitles={(pieChartItems?.length) > 8 ? true : false}
                        // hideLegend={(pieChartItems?.length) <= 8 ? true : false}
                        hideDescriptions
                        innerMetricValue="Orgs"
                        // size="small"
                        statusType={loader ? "loading" : "finished"}
                        variant="donut"
                        detailPopoverContent={(datum, sum) => [
                          { key: "App count", value: datum.value }
                        ]}
                        empty={
                          <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                              There is no data available
                            </Box>
                          </Box>
                        }
                        noMatch={
                          <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                              There is no matching data to display
                            </Box>
                            <Button>Clear filter</Button>
                          </Box>
                        }
                      />
                      <br></br>
                    </Box>
                  )}
                </>
              },
              {
                label: "My approvals",
                id: "approvals",
                content: <><ApprovalsHome/></>
              },
            ]}
            activeTabId={activeTabId}
            onChange={(event) => { setActiveTabId(event.detail.activeTabId) }}
          />

        </Container>
      </ContentLayout>


    </Box>
  );
  return (
    <AppLayout
      disableContentPaddings={false}
      // navigation={<SideNav activeHref={ln.pathname} orgs={organizations} />}
      maxContentWidth={Number.MAX_VALUE}
      navigationHide={true}
      content={Content}
      contentType="default"
      headerSelector="#TopBar"
      toolsHide={true}
      tools={toolsContent}
      // onNavigationChange={() => {
      //   setNavOpen(!NavOpen);
      // }}
    />
  );
};

export default AllProducts;