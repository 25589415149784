import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween,
    TokenGroup,
    Container,
    Grid,
    Textarea,
    CodeEditor,
    Spinner,
    StatusIndicator
} from "@amzn/awsui-components-react";
import './style.scss'
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/cloud_editor.css';
import 'ace-builds/css/theme/cloud_editor_dark.css';
import { verifyDynamicQuery } from "src/utilities/FlexicoAPIs";
const CustomMultiValuedParameter = (props) => {
    var [rows, setRows] = useState(props.getValues(props.field, null) || []);
    var [errorMessage, setErrorMessage] = useState('');
    const [ace, setAce] = useState(undefined);
    var [ceLoading, setCeLoading] = useState(true);

    const cei18nStrings = {
        loadingState: 'Loading code editor',
        errorState: 'There was an error loading the code editor.',
        errorStateRecovery: 'Retry',
      
        editorGroupAriaLabel: 'Code editor',
        statusBarGroupAriaLabel: 'Status bar',
      
        cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
        errorsTab: 'Errors',
        warningsTab: 'Warnings',
        preferencesButtonAriaLabel: 'Preferences',
      
        paneCloseButtonAriaLabel: 'Close',
      
        preferencesModalHeader: 'Preferences',
        preferencesModalCancel: 'Cancel',
        preferencesModalConfirm: 'Confirm',
        preferencesModalWrapLines: 'Wrap lines',
        preferencesModalTheme: 'Theme',
        preferencesModalLightThemes: 'Light themes',
        preferencesModalDarkThemes: 'Dark themes',
      };

    useEffect(() => {
        loadRows()
        
        async function loadAce() {
            const ace = await import('ace-builds');
            await import('ace-builds/webpack-resolver');
            ace.config.set('useStrictCSP', true);
      
            return ace;
          }
      
          loadAce()
            .then(ace => setAce(ace))
            .finally(() => setCeLoading(false));
        return () => {
        };
    }, []);

    useEffect(() => {
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows?.filter(function (row) {
            return row != null;
        });
        for (let i = 0; i < filtered?.length; i++) {
            let transformedVals = []
            if (filtered[i]['field_type'] == 'input') {
                transformedVals.push(' ')
            }else {
                let vals = filtered[i]['field_tokens']
                for (let j = 0; j < vals?.length; j++) {
                    if (filtered[i]['field_type'] == 'dynamic_select' || filtered[i]['field_type'] == 'dynamic_multi_select') {
                        transformedVals.push(vals[j])
                    }else{
                        transformedVals.push(vals[j]?.label)
                    }
                }
            }
            filtered[i]['field_values'] = transformedVals
        }    
        return filtered
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }
    
    // can use for info later when info is added on UI
    const onEditMap = (value, index, key) => {
        rows[index][key] = {...rows[index][key], ...value}
        setRows([...rows])
    }

    const onEditSingleValue = (value, index, key) => {
        rows[index][key] = [value]
        setRows([...rows])
    }

    const onEditOneValue = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }

    const verifyQuery = (value, index) => {
        onEditOneValue(true, index, 'show_verify_loader')
        value["show_verification_message"] = null
        if(value?.['field_values']?.[0]){
            verifyDynamicQuery(value?.['source_database'], value?.['field_values']?.[0], function onSuccess(response){
                let j_response = JSON.parse(response?.data?.verifyDynamicQuery) || null
                let showVerificationMessage = null
                if(j_response?.length && j_response?.[0]){
                    if(Object.keys(j_response[0]).length > 1){
                        showVerificationMessage = {"message":"More than one column returned in the response", "status": "error"}
                    }else{
                        showVerificationMessage = {"message":" ", "status": "success"}
                    }
                }else{
                    showVerificationMessage = {"message":"Null response", "status": "error"}
                }
                onEditOneValue(false, index, 'show_verify_loader')
                onEditOneValue(showVerificationMessage, index, 'show_verification_message')
            },function onFailure(response){
                console.log(response?.errors?.[0]?.message)
                let showVerificationMessage = {"message":response?.errors?.[0]?.message, "status": "error"}
                onEditOneValue(false, index, 'show_verify_loader')
                onEditOneValue(showVerificationMessage, index, 'show_verification_message')
            })
        }
    }
    const getInputItem = (value, index) => {
        if (props?.hideLabelPrefix && value.hasOwnProperty('label') && value['label']?.startsWith(props?.hideLabelPrefix)) {
            return <></>
        } else {
            return <Container >
                <div key={index}>

                    <Grid
                        gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                    >
                        <SpaceBetween direction="vertical" size="s">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Select
                                    selectedOption={ value?.field_type? { label: value?.field_type, value: value?.field_type } : null}
                                    onChange={({ detail }) => {
                                        onEdit(detail.selectedOption.value, index, 'field_type')
                                    }
                                    }
                                    options={[
                                        { label: "select", value: "select" },
                                        { label: "multi_select", value: "multi_select" },
                                        { label: "input", value: "input" },
                                        { label: "fixed_inputs", value: "fixed_inputs" },
                                        { label: "dynamic_select", value: "dynamic_select", disabled: true },
                                        { label: "dynamic_multi_select", value: "dynamic_multi_select", disabled: true }
                                    ]}
                                    disabled={!canDelete(index)}
                                />
                                <Input
                                    onChange={({ detail }) => {
                                        let val = detail.value
                                        if (props?.postProcess) {
                                            val = props.postProcess(detail.value)
                                        }
                                        onEdit(val, index, 'label')
                                    }}
                                    placeholder="label"
                                    value={value.hasOwnProperty('label') ? value['label'] : ''}
                                    disabled={!canDelete(index)}
                                />
                                {
                                    value?.field_type != 'dynamic_select' && value?.field_type != 'dynamic_multi_select' ?
                                     <SpaceBetween direction="vertical" size="m">
                                        <div className="param_input">
                                            <Input
                                                onChange={({ detail }) => {
                                                    let val = detail.value
                                                    onEdit({ label: val.trim(), dismissLabel: val.trim() }, index, 'typed_value')

                                                }}
                                                onKeyDown={({ detail }) => {
                                                    if (detail.key == 'Enter') {
                                                        if (value['typed_value'] == undefined || value['typed_value']?.label == undefined || value['typed_value']?.label == '') {

                                                        } else {
                                                            value['field_tokens'].push(value['typed_value'])
                                                            // resetting the typed value for new values. Existing value is already added to token list
                                                            onEdit(undefined, index, 'typed_value')
                                                        }
                                                    }
                                                }}
                                                placeholder="values"
                                                value={value?.typed_value?.label}
                                                disabled={value?.field_type == 'input' ? true : (value.limit && value['field_tokens']?.length >= value.limit ? true : false)}
                                            />
                                            {
                                                value?.typed_value?.label ? <span className="hintMessage">Press Enter for the values to be considered</span> : ''
                                            }
                                        </div>


                                        <TokenGroup
                                            onDismiss={({ detail: { itemIndex } }) => {
                                                let arr = value?.field_tokens
                                                arr.splice(itemIndex, 1)
                                                onEdit(arr, index, 'field_tokens')
                                            }}
                                            items={value?.field_tokens}
                                        />
                                    </SpaceBetween> : <SpaceBetween direction="horizontal" size="s">
                                                    <Select
                                                        selectedOption={value?.source_database? { label: value?.source_database, value: value?.source_database } : null}
                                                        onChange={({ detail }) => {
                                                            onEdit(detail.selectedOption.value, index, 'source_database')
                                                        }
                                                        }
                                                        options={[
                                                            { label: "FAST", value: "FAST" }
                                                        ]}
                                                        disabled={!canDelete(index)}
                                                        placeholder="Source data"
                                                    />
                                                </SpaceBetween>
                                }
                            </SpaceBetween>
                            {
                                    value?.field_type == 'dynamic_select' || value?.field_type == 'dynamic_multi_select' ?
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <SpaceBetween direction="vertical" size="s">
                                                <div className="param_input_dynamic">

                                                    <CodeEditor
                                                        ace={ace}
                                                        language="sql"
                                                        value={value?.field_tokens?.[0] || ''}
                                                        onChange={({ detail }) => {
                                                            onEditSingleValue(detail.value, index, 'field_tokens')
                                                        }}
                                                        preferences={value?.preferences || undefined}
                                                        onPreferencesChange={e => onEdit(e.detail, index, 'preferences')}
                                                        loading={ceLoading}
                                                        i18nStrings={cei18nStrings}
                                                        editorContentHeight={200}
                                                        themes={{
                                                            light: ["cloud_editor"],
                                                            dark: ["cloud_editor_dark"]
                                                        }}
                                                    />

                                                </div>
                                                <Box><span className="small_grey_text_italics"> The data will be synced to a cache database on a daily cadence</span></Box>
                                            </SpaceBetween>
                                            <SpaceBetween direction="vertical">
                                            <Button  variant="inline-link" onClick={()=>{verifyQuery(value, index)}}>Verify</Button>
                                            {
                                                value?.show_verify_loader ? <Box><Spinner>Verifying..</Spinner></Box> : ''
                                            }
                                            {
                                                value?.show_verification_message?.message ? <StatusIndicator type={value?.show_verification_message?.status}>{value?.show_verification_message?.message}</StatusIndicator>: ''
                                            }
                                            </SpaceBetween>
                                        </SpaceBetween> 
                                     : 
                                     <></>
                                }
                        </SpaceBetween>
                        {
                            index != null && index != undefined && canDelete(index) ? <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                                deleteRow(index)
                            }} /></div> : ''
                        }

                    </Grid>
                </div>
            </Container>
        }
    }
    const canDelete = (index) => {
        let canDelete = true
        for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
            if (props.mandatoryParameters[i].label == rows[index].label && props.mandatoryParameters[i].field_type == rows[index].field_type) {
                canDelete = false
                break
            }
        }
        return canDelete
    }
    const deleteRow = (index) => {
        if (canDelete(index)) {
            rows.splice(index, 1)
            setRows([...rows])
        }

    }
    const addRow = () => {
        var newRow = {}
        newRow['label'] = ''
        newRow['field_tokens'] = []
        newRow['field_type'] = []
        setRows([...rows, newRow])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field, null)

        // mandating parameters
        if (values) {
            for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
                let found = false
                for (let j = 0; j < values?.length; j++) {
                    if (values[j]?.label == props.mandatoryParameters[i].label) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    values.push({ 'field_type': props.mandatoryParameters[i].field_type, 'label': props.mandatoryParameters[i].label, 'limit': props.mandatoryParameters[i].limit, 'field_values': '', 'field_tokens': [] })
                }
            }

        } else {
            values = []
            for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
                values.push({ 'field_type': props.mandatoryParameters[i].field_type, 'label': props.mandatoryParameters[i].label, 'limit': props.mandatoryParameters[i].limit, 'field_values': '', 'field_tokens': [] })
            }
        }

        if (values) {
            for (let i = 0; i < values?.length; i++) {
                if (values[i].hasOwnProperty('field_values') && !values[i].hasOwnProperty('field_tokens')) {
                    let tokens = []
                    for (let j = 0; j < values[i]['field_values']?.length; j++) {
                        let val = values[i]['field_values'][j]
                        if(values[i].field_type != 'dynamic_select' && values[i].field_type != 'dynamic_multi_select'){
                            tokens.push({ label: val, dismissLabel: val })
                        }else{
                            tokens.push(val)
                        } 
                    }
                    values[i]['field_tokens'] = tokens
                }
                if (values[i].label == 'contico_job' && values[i].field_type == 'fixed_inputs' && !values[i].hasOwnProperty('limit')) {
                    values[i]['limit'] = 1
                }
                if (values[i].hasOwnProperty('info')){
                    if(typeof(values[i]['info']) == 'string'){
                        values[i]['info'] = JSON.parse(values[i]['info'])
                    }
                }
            }
            setRows(JSON.parse(JSON.stringify(values)))
        } else {
            setRows([])
        }
    }

    return (
        <Box>
            {props.sectionLabel ? <span className="info">{props.sectionLabel}</span> : ''}
            <br></br>
            {errorMessage ? <span className="error"> {errorMessage} </span> : ''}
            <br></br>
            <SpaceBetween direction="vertical" size="xs">
            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}
            </SpaceBetween>
            <br></br>
            {
                props?.allowAddition ?
                    getAddButton() : <></>
            }
        </Box>
    )
}

export default CustomMultiValuedParameter;