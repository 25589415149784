import React, { useState, useEffect } from "react";

import {
    Container,
    Header,
    Box,
    SpaceBetween,
    ExpandableSection,
    Table,
    Badge,
    KeyValuePairs,
    Link,
    CopyToClipboard
} from "@amzn/awsui-components-react";

export default (props) => {

    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [data, setData] = useState(props.data)
    const [quickSightData, setQuickSightData] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        loadData();
    }, [data]);


    const loadData = async () => {
        setShowLoadingIcon(true);

        const quicksightData = data?.process_details?.qs?.map((quicksightItem) => {
            return formatQSDataFromMeta(quicksightItem)
        })

        setQuickSightData(quicksightData)

        setShowLoadingIcon(false);
    };


    const formatQSDataFromMeta = (quicksightItem) => {
        return (
            <Container>
                <KeyValuePairs
                    columns={3}
                    items={[
                        {
                            type: "group",
                            title: "Names",
                            items: [
                                {
                                    label: "Dashboard name",
                                    value: quicksightItem.hasOwnProperty("dashboard_name") ? quicksightItem.dashboard_name : "-",
                                },
                                {
                                    label: "Analysis name",
                                    value: quicksightItem.hasOwnProperty("analysis_name") ? quicksightItem.analysis_name : "-",
                                }
                            ]
                        }, 
                        {
                            type: "group",
                            title: "URLs",
                            items: [
                                {
                                    label: "Dashboard url",
                                    value: quicksightItem.hasOwnProperty("dashboard_url") ? <Link
                                    external={true}
                                    href={quicksightItem.dashboard_url}
                                    ariaLabel="Dashboard url"
                                  >{quicksightItem.dashboard_url} </Link>: "-",
                                },
                                
                                
                                {
                                    label: "Analysis url",
                                    value: quicksightItem.hasOwnProperty("analysis_url") ? <Link
                                    external={true}
                                    href={quicksightItem.analysis_url}
                                    ariaLabel="Analysis url"
                                  >{quicksightItem.analysis_url} </Link> : "-",
                                }
                            ]
                        },
                        {
                            type: "group",
                            title: "ID/ARNs",
                            items: [
                                {
                                    label: "Dashboard id",
                                    value: quicksightItem.hasOwnProperty("dashboard_id") ? <CopyToClipboard
                                    copyButtonAriaLabel="Copy ID"
                                    copyErrorText="ID failed to copy"
                                    copySuccessText="ID copied"
                                    textToCopy={quicksightItem.dashboard_id}
                                    variant="inline"
                                  /> : "-",
                                },
                                {
                                    label: "Dashboard arn",
                                    value: quicksightItem.hasOwnProperty("dashboard_arn") ? <CopyToClipboard
                                    copyButtonAriaLabel="Copy ARN"
                                    copyErrorText="ARN failed to copy"
                                    copySuccessText="ARN copied"
                                    textToCopy={quicksightItem.dashboard_arn}
                                    variant="inline"
                                  />  : "-",
                                },
                                {
                                    label: "Analysis arn",
                                    value: quicksightItem.hasOwnProperty("analysis_arn") ? <CopyToClipboard
                                    copyButtonAriaLabel="Copy ARN"
                                    copyErrorText="ARN failed to copy"
                                    copySuccessText="ARN copied"
                                    textToCopy={quicksightItem.analysis_arn}
                                    variant="inline"
                                  />  : "-",
                                }
                            ]
                        }
                    ]}
                />
                {(quicksightItem.hasOwnProperty("dependencies") && quicksightItem?.dependencies?.length >= 1) && (
                    <>
                        <br></br>
                        <br></br>
                        <Table
                            renderAriaLive={({
                                firstIndex,
                                lastIndex,
                                totalItemsCount
                            }) =>
                                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                            }
                            columnDefinitions={[
                                {
                                    id: "table_name",
                                    header: "Table name",
                                    cell: e => e.table_name,
                                    width: 170,
                                    minWidth: 165,
                                    sortingField: "table_name"
                                },
                                {
                                    id: "datasource_name",
                                    header: "Datasource Name",
                                    cell: e => <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>{e.datasource_name?.map((name) => <li>{name}</li>)}</ul>,
                                    width: 170,
                                    minWidth: 165,
                                    sortingField: "datasource_name",
                                    isRowHeader: true
                                },
                                {
                                    id: "dataset_arn",
                                    header: "Dataset arn",
                                    cell: e => <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>{e.dataset_arn?.map((arn) => <li>
                                        <CopyToClipboard
                                    copyButtonAriaLabel="Copy ARN"
                                    copyErrorText="ARN failed to copy"
                                    copySuccessText="ARN copied"
                                    textToCopy={arn}
                                    variant="inline"
                                  /></li>)}</ul>,
                                    width: 270,
                                    minWidth: 165,
                                    sortingField: "dataset_arn",
                                    isRowHeader: true
                                },
                                {
                                    id: "dataset_url",
                                    header: "Dataset url",
                                    cell: e => <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>{e?.dataset_url?.map((url) => <li><Link
                                        external={true}
                                        href={url}
                                        ariaLabel="Dataset url"
                                      >{url} </Link></li>)}</ul>,
                                    width: 170,
                                    minWidth: 165,
                                    sortingField: "dataset_url",
                                    isRowHeader: true
                                }

                            ]}
                            enableKeyboardNavigation
                            items={quicksightItem?.dependencies}
                            loadingText="Loading dependencies"
                            resizableColumns
                            empty={
                                <Box
                                    margin={{ vertical: "xs" }}
                                    textAlign="center"
                                    color="inherit"
                                >
                                    No Dependencies
                                </Box>
                            }

                            header={
                                <Header>Dependencies</Header>
                            }

                        />
                    </>
                )}
            </Container>
        )

    }

    return (
        <ExpandableSection
            variant="container"
            headerText="Meta"
            headerInfo={<Badge color="blue">beta</Badge>}
        >
            {data?.process_details?.qs?.length >= 1 && (
                <ExpandableSection headerText="Quicksight">

                    <ul style={{ listStyleType: "none", paddingLeft: "0px" }}><SpaceBetween direction="vertical" size="m">
                        {quickSightData?.map((item) => (
                            <li>{item}</li>
                        ))}
                    </SpaceBetween> </ul>
                </ExpandableSection>
            )}

            {data?.process_details?.python?.length >= 1 && (
                <ExpandableSection headerText="Python">
                    Python data
                </ExpandableSection>
            )}
        </ExpandableSection>
    );
};
