import React, { useState, useEffect } from "react";
import "../../index.scss";
import { useHistory, useParams } from "react-router-dom";
import AuditLoggingMain from './AuditLoggingMain';
import { getLogsById, getLogsByType } from "./AuditLogsAPIs";
import {
    Tabs
} from "@amzn/awsui-components-react";

export default (props) => {

    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [logsData, setLogsData] = useState();

    const [headerHref, setHeaderHref] = useState()
    const [sideNavBack, setSideNavBack] = useState()
    const [sideHeader, setSideHeader] = useState("All App Logs")
    const [activeTabId, setActiveTabId] = useState("app_logs")

    useEffect(() => {
        loadData('APP#');
        window.scrollTo(0, 0)
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        if (activeTabId === 'app_logs') {
            setSideHeader("All App Logs")
            loadData('APP#');
        } else if (activeTabId === 'frisco_process_logs') {
            setSideHeader("All FRISCO Process Logs")
            loadData('FRISCO#');
        } else if (activeTabId === 'frisco_file_logs') {
            setSideHeader("All FRISCO File Logs")
            loadData('FRISCO_FILE#');
        }
    },[activeTabId])


    const loadData = async (type) => {
        setShowLoadingIcon(true);
        setHeaderHref(`/AllAuditLogs`);
        setSideNavBack(`/`);
        const pk = type
        const logs = await getLogsByType(pk)
        setLogsData(logs)
        setShowLoadingIcon(false);
    };

    const content = (
        <AuditLoggingMain logsData={logsData} headerHref={headerHref} sideNavBack={sideNavBack} sideHeader={sideHeader} />
    )

    return (
        <Tabs
        onChange={({ detail }) => {
            setActiveTabId(detail.activeTabId)
            setLogsData()    
        }
       
      }
      activeTabId={activeTabId}
      tabs={[
        {
          label: "App Logs",
          id: "app_logs",
          content: content
        },
        {
          label: "Frisco Process Logs",
          id: "frisco_process_logs",
          content: content
        },
        {
          label: "Frisco File Logs",
          id: "frisco_file_logs",
          content: content,
        }
      ]}
    />
    );
};

