import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import './style.scss'

const CustomMultiHybridInputAndSelect = (props) => {
    var [rows, setRows] = useState(props.getValues(props.field, null) || []);
    var [errorMessage, setErrorMessage] = useState('');
    var [duplicateIndexes, setDuplicateIndexes] = useState({});
    const [loginOptions, setLoginOptions] = useState(props?.loginOptions)
    const [aliasesLoading, setAliasesLoading] = useState(props?.aliasesLoading)


    useEffect(() => {
        setLoginOptions(props?.loginOptions)
    }, [props?.loginOptions])

    useEffect(() =>{
        setAliasesLoading(props?.aliasesLoading)
      },[props?.aliasesLoading])

    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        if(isInValid()){
            showErrorMessage()
        } else if (props?.duplicatesNotAllowed) {
            let tempMap = {}
            let duplicateFound = false
            for (let i = 0; i < rows.length; i++) {
                let hashKey = `${rows[i][props.inputKey]}_${rows[i][props.selectKey]}`
                if (tempMap.hasOwnProperty(hashKey)) {
                    duplicateFound = true
                    duplicateIndexes[i]=true
                    setDuplicateIndexes(duplicateIndexes)
                } else {
                    tempMap[hashKey] = 1
                    duplicateIndexes[i]=false
                }
            }

            let msg = ''
            if(duplicateFound){
                msg = 'Duplicates found please remove'
            }
            showErrorMessage(msg)
        } else{
            showErrorMessage('')
        }
        if(props.hasOwnProperty("updateValue")){
            props.updateValue(props.field, getMergedInputValues())
        }
        if(props.hasOwnProperty("updateValueWithFilterCriteria")){
            props.updateValueWithFilterCriteria(props.field, getMergedInputValues(), null, props.filterCriteria)
        }
    }, [rows])

    const makeSelectObject = (value) => {
        // let value = props.getValue(props.field, props.subKey)
        if (value === null || value === '') {
            return null
        } else {
            return { label: value, value: value }
        }
    }

    const showErrorMessage = (msg=null) => {
        if(msg==null && props?.errorMessage){
            msg = props?.errorMessage
        }

        setErrorMessage(msg)
    }
    const getMergedInputValues = () => {
        var filtered = rows?.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const isInValid = () => {
        return props.validator && !props.validator(getMergedInputValues())
    }
    const onEdit = (value, index, key) => {
        rows[index][key] = value
        if(key == props.indexKey){
            rows[index][key] = parseInt(value)
        }
        setRows([...rows])
    }

    const getLabel = (value) => {
        let label = value
        let options = props.getOptions(props.field, props.selectKey)
        for(let i=0;i<options?.length;i++){
            if(options[i]?.label && options[i]?.value == value){
                label = options[i].label
            }
        }
        return label
    }

    const getInputItem = (value, index) => {
        return <div className={duplicateIndexes.hasOwnProperty(index) && duplicateIndexes[index]?'rows duplicate':'rows'} key={index}>
            <SpaceBetween direction="horizontal" size="xs">
            <Select
                selectedOption={ value[props.selectKey] ? {value:value[props.selectKey], label:getLabel(value[props.selectKey])} : null}
                onChange={({ detail }) =>
                    onEdit(detail.selectedOption.value, index, props.selectKey)
                }
                options={props.getOptions(props.field, props.selectKey)}
                placeholder={props?.selectHint ? props.selectHint : "Choose options"}
                />
            {value[props.selectKey] === 'UPLOADER' || value[props.selectKey] === 'APPROVER' ?
            
            <Select
                    selectedOption={makeSelectObject(value?.alias)}
                    onChange={({ detail }) => {
                        onEdit(detail?.selectedOption?.value, index, props.inputKey)
                    }

                    }
                    placeholder="Select Alias"
                    loadingText="Loading aliases"
                    statusType={aliasesLoading ? "loading" : "finished"}
                    empty="No valid alias to recommend for entered text"
                    options={loginOptions}
                    filteringType="manual"
                    onLoadItems={({ detail }) => {

                        props?.getLoginOptions(detail?.filteringText)

                    }}
                />
            : 
            <Input
            onChange={({ detail }) => {
                let val=detail.value
                if(props?.postProcess){
                    val=props.postProcess(detail.value)
                }
                onEdit(val, index, props.inputKey)
            }}
            placeholder={props.placeholder}
            value={value.hasOwnProperty(props.inputKey) ? value[props.inputKey]: ''}
            />
            }
            
                {
                    props.needIndex ?
                        <Input
                            onChange={({ detail }) => {
                                let val = detail.value
                                onEdit(val, index, props.indexKey)
                            }}
                            placeholder={props.indexPlaceHolder}
                            value={value.hasOwnProperty(props.indexKey) ? value[props.indexKey] : props.hasOwnProperty('defIndex') ? props.defIndex:'0'}
                            inputMode="decimal"
                            type="number"
                        /> : ''
                }
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
    </SpaceBetween>
            
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        var newRow = {}
        newRow[props.selectKey] = null
        newRow[props.inputKey] = ''
        props.indexKey?newRow[props.indexKey] = props.hasOwnProperty('defIndex') ? props.defIndex:'0' : ''
        setRows([...rows,newRow ])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const filteredContent = (content, filterCriteria) => {
        if(filterCriteria){
            let filteredContent = []

            for(let i = 0; i< content?.length; i++){
                let filterStatus = true
                Object.keys(filterCriteria).forEach((key) => {
                    if(content[i].hasOwnProperty(key) && content[i][key] == filterCriteria[key]){
                        filterStatus = filterStatus && true
                    }else{
                        filterStatus = filterStatus && false
                    }
                })
                if(filterStatus){
                    filteredContent.push(content[i])
                }
            }
            return filteredContent
        }else{
            return content
        }
    }

    const loadRows = () => {
        let values = props.getValues(props.field, null)
        values = filteredContent(values, props.filterCriteria)
        if(values == null){
            return
        }
        setRows(JSON.parse(JSON.stringify(values)))
    }

    return (
        <Box>
            {props.info? <span className="info">{props.info}</span> : ''}
            <br></br>
            {errorMessage? <span className="error"> {errorMessage} </span> : ''}
            <br></br>
            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomMultiHybridInputAndSelect;