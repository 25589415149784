import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Table, SpaceBetween, Header, Button,
    Container, Spinner, Pagination, Alert,  Modal, Link
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess, { checkIfPortalAdmin, checkIfSupportAdmin } from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { setCurrentAppId } from '../../store/userAuthSlice';
import { getProcessById , queryFRISCOTransactionsByAppIdProcessIdIndex, getFriscoSTLLoadError, getFriscoTransaction} from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import moment from 'moment';
import { PROCESS_KEYS } from './Constants';
import { configureFlexicoAppSync, configureFriscoS3, configureGenericAppSync, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isApprover, isUploaderAllowed } from './AccessUtilities';
import { isCorpAdmin } from '../Templates/Utils/Permissions';
import appSettings from '../CorpReporting/app_settings';
import { getFlexicoTransaction } from 'src/utilities/FlexicoAPIs';


const FlexicoRedirect = (props) => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()
    const [error, setError] = useState(false);
   
    let { file_id } = useParams();

    useEffect(() => {

        redirect(file_id)
        return () => {
          // this now gets called when the component unmounts
        };
      }, []);

      const redirect = (file_id) => {
        configureFlexicoAppSync()
        getFlexicoTransaction(file_id, function onSuccess(result){
            let appId = result.data?.getFlexicoTransaction?.appId
            let flexId = result.data?.getFlexicoTransaction?.flexId
            if(appId && flexId){
                configureGenericAppSync()
                history.push(`/App/${appId}/flexico/${flexId}/history`)
            }else{
                setError(true)
                configureGenericAppSync()
            }
        }, function onFailure(response){
            setError(true)
            configureGenericAppSync()
        })
    }


    return (
        <Box textAlign="center">
            {
                error?
                <Box variant="h1" color="text-status-error">Invalid id</Box> :<Box variant="h1">Redirecting to the FlexiCo history page..</Box>
            }
        </Box>
    )
}



export default FlexicoRedirect

