import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Table, SpaceBetween, Header, Button,
    Container, Spinner, Pagination, Alert, Modal, Link,
    KeyValuePairs,
    CopyToClipboard,
    ExpandableSection
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess, { checkIfPortalAdmin, checkIfSupportAdmin } from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { setCurrentAppId } from '../../store/userAuthSlice';
import { getProcessById, queryFRISCOTransactionsByAppIdProcessIdIndex, getFriscoSTLLoadError, getFriscoTransaction, getApp } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import moment from 'moment';
import { PROCESS_KEYS } from './Constants';
import { configureFlexicoAppSync, configureFlexicoS3, configureFriscoS3, configureGenericAppSync, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isApprover, isUploaderAllowed } from './AccessUtilities';
import { isCorpAdmin } from '../Templates/Utils/Permissions';
import appSettings from '../CorpReporting/app_settings';
import { getFlexicoTransaction } from 'src/utilities/FlexicoAPIs';
import EmbedResults from 'src/generic-components/EmbedResults/EmbedResults';
import { CodeView } from '@amzn/awsui-code-view';
import Feedback from 'src/generic-components/UserFeedback/Feedback';


const FlexicoFileDetails = (props) => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()
    const [error, setError] = useState(false);
    const [fileInfo, setFileInfo] = useState({});
    const [appId, setAppId] = useState(null);
    const [flexId, setFlexId] = useState(null);

    let { file_id } = useParams();
    const userId = useSelector((globalState) => globalState.auth.userId);

    useEffect(() => {

        fetchFileInfo(file_id)
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const fetchFileInfo = (file_id) => {
        configureFlexicoAppSync()
        getFlexicoTransaction(file_id, function onSuccess(result) {
            setFileInfo(result.data.getFlexicoTransaction)

            setAppId(result?.data?.getFlexicoTransaction?.appId)
            setFlexId(result?.data?.getFlexicoTransaction?.flexId)
            configureGenericAppSync()
        }, function onFailure(response) {
            setError(true)
            configureGenericAppSync()
        })
    }

    const approvalStatusButton = (props) => {
        if (props?.approvalStatus == 'pending') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.approvalId}`}> <span className='orange_badge' >{props.approvalStatus}</span> </Link>
        } else if (props.approvalStatus == 'completed') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.approvalId}`}> <span className='green_badge' >{props.approvalStatus}</span> </Link>
        } else if (props.approvalStatus == 'rejected') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.approvalId}`}> <span className='red_badge' >{props.approvalStatus}</span> </Link>
        } else if (props.approvalStatus == 'cancelled') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.approvalId}`}> <span className='grey_badge' >{props.approvalStatus}</span> </Link>
        } else if (props.approvalStatus == 'NO APPROVAL') {
            return <span className='blue_badge' >{props.approvalStatus}</span>
        } else {
            if (props.approvalId) {
                return <Link external href={`/ApprovalsHome/Workflow/${props.approvalId}`}> <span className='blue_badge' >{props.approvalStatus}</span> </Link>
            } else {
                return <span className='blue_badge' >{props.approvalStatus}</span>
            }
        }
    }

    const fileDetails = (
        <SpaceBetween direction='vertical' size='s'>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Transaction details"
                    >
                        <SpaceBetween direction='horizontal' size='m'>
                            <Box variant="h2">Flexico trigger information </Box>
                            {appId?<Link href={`/App/${appId}`} external> App </Link>:'' }
                            {appId && flexId?<Link href={`/App/${appId}/flexico/${flexId}`} external> FlexiCo template </Link>:'' }                        </SpaceBetween>
                    </Header>
                }
            >
                <SpaceBetween direction='vertical' size='s'>
                <SpaceBetween direction='vertical'>
                    <KeyValuePairs
                        columns={4}
                        items={[
                            {
                                label: "File ID",
                                value: (
                                    <CopyToClipboard
                                        copyButtonAriaLabel="Copy File id"
                                        copyErrorText="Failed to copy"
                                        copySuccessText="File id copied"
                                        textToCopy={fileInfo.fileId|| '-'}
                                        variant="inline"
                                    />
                                )
                            },
                            {
                                label: "Status",
                                value: fileInfo.status|| '-'
                            },
                            {
                                label: "Uploaded by",
                                value: (
                                    <Box float="left">
                                        <img
                                            alt=""
                                            className="rounded-circle"
                                            src={
                                                "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
                                                fileInfo.uploadedBy
                                            }
                                            width={20}
                                            height={20}
                                        />
                                        &nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={"https://phonetool.amazon.com/users/" + fileInfo.uploadedBy}
                                        >
                                            {fileInfo.uploadedBy}
                                        </a>
                                    </Box>
                                )
                            },
                            {
                                label: "Uploaded at",
                                value: <Box>{moment(new Date(Number(fileInfo.uploadedTimestamp))).format()|| '-'}</Box>
                            },
                            {
                                label: "Last updated time",
                                value: <Box>{moment(new Date(Number(fileInfo.lastUpdatedTimestamp))).format()|| '-'}</Box>
                            }
                            ,
                            {
                                label: "Job trigger time",
                                value: <Box>{fileInfo.jobStartTime || '-'}</Box>
                            }
                            ,
                            {
                                label: "Approval",
                                value: approvalStatusButton(fileInfo)
                            }
                        ]}
                    />

                </SpaceBetween>
                
                <ExpandableSection headerText="Input parameters">
                                    <CodeView
                                        content={fileInfo?.parameters ? JSON.stringify(JSON.parse(fileInfo?.parameters), 0, 2) : ''}
                                    />
                                </ExpandableSection>
                </SpaceBetween>
            </Container>
            {
                fileInfo?.outputResultsS3 || fileInfo?.outputResults  ? <Container header={
                    <Header
                    variant="h2"
                    description="Output results generated by the contico job"
                >
                    Output
                </Header>
                }>
                    {
                        fileInfo ? <EmbedResults s3Bucket={fileInfo.bucketName} s3Reports={fileInfo.outputResultsS3} outputResults={fileInfo.outputResults}></EmbedResults>
                            : ''
                    }
                </Container>:''
            }

            {
                fileInfo && fileInfo.appId ? <Feedback appId={fileInfo.appId} pageId={`file_details_${file_id}`} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
                    : ''
            }

        </SpaceBetween>
    )

    const content = (
        <Box textAlign="center">
            {
                error ?
                    <Box variant="h1" color="text-status-error">Invalid id</Box> :
                    <Box> {fileDetails}</Box>
            }
        </Box>
    )

    return (
        <AppLayout
            content={content}
            headerSelector="#TopBar"
            navigationHide={true}
            maxContentWidth={Number.MAX_VALUE}
            headerVariant="high-contrast"
        ></AppLayout>
    );
}



export default FlexicoFileDetails

