


import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from "react-router-dom";

import './styles.scss';

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Alert, ExpandableSection, Tiles,
    Container, Header, ColumnLayout, Spinner, Link, Tabs, Button, Table, SpaceBetween, Modal, FileUpload, Flashbar,
    RadioGroup,
    FileInput,
    Grid
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import { useAuth } from "../../context/AuthContextProvider";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Amplify, { Storage } from 'aws-amplify';
import { getFriscoS3Config } from 'src/utilities/AWSServices';

import MJEmonitoringSampleFile from '../../images/MJEMonitoringSampleFile.png'
import { PORTAL } from "src/constants/AppConstants";
import { setCurrentAppId } from '../../store/userAuthSlice';
import * as XLSX from 'xlsx';
import { getMappingById, getProcessById, createFriscoTransactions, getNormalisedColumnName, getFRISCOPreValidationResults, friscoSubmit, getFRISCOPreValidationMatchingExcelSheetResults, onMatchResults, getNotification } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { FRISCO_NEGATIVE_STATUS_FOR_UPLOAD, FRISCO_POSITIVE_STATUS, FRISCO_STATUS, PROCESS_KEYS } from './Constants';
import { configureFriscoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isUploaderAllowed } from './AccessUtilities';
import {request as requestPlugin} from 'src/plugins/CorpReportingUploadAccess'
import {request as requestViewPlugin} from 'src/plugins/CorpReportingViewOptions'
import { getQuarterForUpload } from '../CorpReporting/Utility';
import { checkIfSupportAdmin } from 'src/checkPageAccess';
import { formNotificationItems } from './NotificationUtilities';
export default (props) => {
    const userId = useSelector((globalState) => globalState.auth.userId)
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    
    const history = useHistory();
    const userAuthenticationDetails = useAuth();
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    let { id } = useParams();
    let { pid } = useParams();
    const [processData, setProcessData] = useState({})
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const [mappingId, setMappingId] = useState('');
    const dispatch = useDispatch()
    const [activeTabId, setActiveTabId] = useState("first");
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);
    const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const corpReportingQuarter = useSelector((globalState)=>globalState.auth.corpReportingQuarter)
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [columnsList, setColumnsList] = useState({})
    const [items, setItems] = useState([])
    const [previewItems, setPreviewItems] = useState([])
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [missingFields, setMissingFields] = useState(new Set());
    const [colsNotInRequiredOrder, setColsNotInRequiredOrder] = useState(false);
    const [colCountMoreThanExpected, setColCountMoreThanExpected] = useState(false);
    const [inputFiles, setInputFiles] = useState([]);
    const [supportedFormats, setSupportedFormats] = useState('.csv');
    const [loadingMessage, setLoadingMessage] = useState('loading, please wait..');
    const [showUploadBtn, setShowUploadBtn] = useState(false)
    const [fileReference, setFileReference] = useState('');
    const [inputSheets, setInputSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [disableProceedButton, setDisableProceedButton] = useState(true)
    const [backUrl, setBackUrl] = useState(null)
    const [taskId, setTaskId] = useState(null)
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [uploadAccessForTheQuarter, setUploadAccessForTheQuarter] = useState(false);
    const [rawFile, setRawFile] = useState(null)
    const [rawFileData, setRawFileData] = useState(null)
    const stageSessionId = useRef()
    const stageSessionFileExt = useRef()
    // sometimes updating setSelectedSheet is not reflecting when making a validation request since that is async function also. hence using this copy variable
    const stageSessionSelectedSheet = useRef()
    const [providedCoulumns, setProvidedCoulumns] = useState([])
    const [comments, setComments] = useState(null)
    const [notificationBarItems, setNotificationBarItems] = useState([])
    const [soxAuditFailed, setSoxAuditFailed] = useState(false)
    const [wbType, setWbType] = useState(false)
    const [supportingDocs, setSupportingDocs] = useState([])
    const [shouldShowWbSelection, setShouldShowWbSelection] = useState(false)
    const [shouldShowPdfUpload, setShouldShowPdfUpload] = useState(false)
    
    useEffect(() => {
        configureFriscoS3()
        if (pid) {
            loadData();
        }
        if(id){
            dispatch(setCurrentAppId(id))
        }
        setBackUrl(getParam('back'))
        setTaskId(getParam('taskId'))
        return () => {
            // this now gets called when the component unmounts
            configureGenericS3()
        };
    }, []);



    useEffect(() => {
        configureFriscoS3()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [isCurrentAppAuthorised]);

    useEffect(() => {
        stageSessionSelectedSheet.current = selectedSheet
        if(selectedSheet){
            setDisableProceedButton(false)
        }else{
            setDisableProceedButton(true)
        }
        return () => {
            // this now gets called when the component unmounts
        };
    }, [selectedSheet]);

    useEffect(() => {
        if (isAuthorized === true || isAuthorized === false) {
            setIsCheckingAccess(!isCheckingAccess);
        }
    }, [isAuthorized]);

    
    const getParam= (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
      }
    const loadData = () => {
        setLoadingMessage("loading, please wait...")
        setShowLoadingIcon(true);
        setShowUploadBtn(false)
        // setProcessData()
        getNotification(pid, 'FRISCO_PROCESS', function onSuccess(response){
            formNotificationItems(response, setNotificationBarItems)
            JSON.parse(response.data.getNotification).forEach((notification, index) => {
                if(notification.notification_type == 'FRISCO_2PR_ALIAS_VALIDATION_ERROR'){
                    setSoxAuditFailed(true)
                    setErrorMessage("Minimum of 2 approvers required.")
                  }else if(notification.info?.includes("APPROVER")){
                    setSoxAuditFailed(true)
                    setErrorMessage(`The approver list has to be updated. Please contact process owner.`)
                }else if(notification.info?.includes(userId) && 
                (notification.notification_type == 'ALIAS_PATROL_COST_CENTER_MISMATCH' 
                    || notification.notification_type == 'ALIAS_PATROL_MANAGER_LOGIN_MISMATCH'
                || notification.notification_type == 'ALIAS_PATROL_JOB_STATUS_MISMATCH')){
                    setSoxAuditFailed(true)
                    setErrorMessage(`${notification.notification_message} Please contact process owner.`)
                }
            })

          }, function onFailure(response){
      
          })
        getProcessById(pid, async function onSuccess(response) {
            const r = response.data.getProcessByIDV2
            const sorted = r[PROCESS_KEYS.schema].sort((a,b) => a.column_index - b.column_index)
            setProcessData(r)
            setSupportedFormats(getSupportedFormats(r))

            if(FRISCO_NEGATIVE_STATUS_FOR_UPLOAD.includes(r[PROCESS_KEYS.status])){
                setShowLoadingIcon(false);
                setShowUploadBtn(false)
                setErrorMessage(`Cannot upload input file. The table creation status is : ${r[PROCESS_KEYS.status]}`)
                return
            }
            let isAllowed = await checkIfSupportAdmin(userId, currentStage)
            if(!isAllowed){
                isAllowed = await isUploaderAllowed(r, userId, currentStage)
            }
            if(!isAllowed){
                setShowLoadingIcon(false);
                setShowUploadBtn(false)
                setErrorMessage("Cannot upload input file. Please check with the owner of the process for access.")
            } else {
                getMappingById(id, pid, function onSuccess(response) {
                    setMappingId(response.data.getMappingIDV2.mapping_id)
                    setShowLoadingIcon(false);
                    setShowUploadBtn(true)
                },
                    function onFailure(response) {
                        // setErrorMessage("Could not get app mapping information")
                        setShowLoadingIcon(false);
                        setShowUploadBtn(true)
                    })
                    let quarter = getQuarterForUpload()
                requestPlugin({ appId: id, processId:pid, stage: currentStage, group: response.data.getProcessByIDV2[PROCESS_KEYS.group_id], quarter: quarter}, function onResponse(response) {
                    setUploadAccessForTheQuarter(response.result)
                    if (!response.result) {
                        setErrorMessage(` A process is either in-progress or complete. Current status is '${response.message}'`)
                    }
                    if (response.comments) {
                        setComments(response.comments)
                    }  
                })
                requestViewPlugin({appId: id, stage: currentStage}, function onResponse(response) {
                    setShouldShowWbSelection(response?.allowWBSelection)
                    setShouldShowPdfUpload(response?.allowSupportingDocUpload)
                })
            }
        }, function onFailure(response) {
            setErrorMessage("Could not load process information")
            setShowLoadingIcon(false);
            setShowUploadBtn(true)
        })
    }

    const getAllowedFileFormats = () => {
        if (processData && processData.hasOwnProperty(PROCESS_KEYS.file_types) && processData[PROCESS_KEYS.file_types]) {
            return processData[PROCESS_KEYS.file_types]
        }
        return []
    }
    const getMandatoryColumnNames = () => {
        let mandatoryColumns = new Set()

        if (processData && processData.hasOwnProperty(PROCESS_KEYS.schema)) {
            processData[PROCESS_KEYS.schema].forEach((item) => {
                mandatoryColumns.add(item['column_name'])
            })
        }
        return mandatoryColumns
    }

    const isAllowedFileFormat = (format, allowedFileFormats) => {
        let isAllowed = false
        // console.log(format)
        if (!format) {
            isAllowed = false
        }
        let supportedFormats = []
        for (let i = 0; i < allowedFileFormats.length; i++) {
            if (allowedFileFormats[i] == 'CSV') {
                supportedFormats.push('text/csv')
                supportedFormats.push('text/plain')
                supportedFormats.push('text/x-csv')
                supportedFormats.push('application/vnd.ms-excel')
                supportedFormats.push('application/csv')
                supportedFormats.push('application/x-csv')
                supportedFormats.push('text/comma-separated-values')
                supportedFormats.push('text/x-comma-separated-values')
                supportedFormats.push('text/tab-separated-values')
            } else if (allowedFileFormats[i] == 'Excel') {
                supportedFormats.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                supportedFormats.push('application/vnd.ms-excel.sheet.macroenabled.12')
            }
        }
        isAllowed = supportedFormats.some(item => format === item);
        return isAllowed
    }
    const validateInputFileFormat = (file) => {
        let allowedFileFormats = getAllowedFileFormats()
        let isAllowed = isAllowedFileFormat(file?.type, allowedFileFormats)
        if (!file?.type) {
            throw new Error("Please upload an input file")
        }
        if (!isAllowed) {
            throw new Error(`File format is not allowed : ${file?.type}`);
        }
        return isAllowed
    }

    const validateEmptyContents = (rowData) => {
        if (!rowData || rowData.length == 0) {
            throw new Error("The inputs rows are empty")
        }
    }

    const resetValidationErrorLogs = () => {
        setProvidedCoulumns([])
        setMissingFields(new Set())
        setColCountMoreThanExpected(false)
        setColsNotInRequiredOrder(false)
    }
    
    const postDataSelection = async() => {
        setLoadingMessage("validating the uploaded file, please wait...")
        setDisableProceedButton(true)
        setShowLoadingIcon(true)
        resetValidationErrorLogs()
        onMatchResults(stageSessionId.current, function onResult(result){
            let validationResponse = JSON.parse(result.value.data.onMatchResults.payload)
            setProvidedCoulumns(validationResponse.normalised_columns)
            setMissingFields(new Set(validationResponse.missing_fields))
            setColCountMoreThanExpected(validationResponse.column_count_more_than_expected)
            setColsNotInRequiredOrder(validationResponse.columns_not_in_required_order)

            if (validationResponse.missing_fields.size >= 1 || validationResponse.columns_not_in_required_order || validationResponse.column_count_more_than_expected) {
                setShowLoadingIcon(false)
                return
            }
            const definitions = validationResponse.normalised_columns.map(colName => ({
                id: colName,
                header: colName,
                cell: item => item[colName] || '-'
            }))
            setColumnsList(definitions)
            setPreviewItems(validationResponse.top_columns)
            setShowLoadingIcon(false)
            setDisableProceedButton(false)
            setActiveTabId('second')
        }, function onFailure(error){
            console.log(error)
        })

        await new Promise(resolve => setTimeout(resolve, 1000));
        getFRISCOPreValidationResults(stageSessionId.current, stageSessionFileExt.current, pid, stageSessionSelectedSheet.current, function onSuccess(response){

        }, function onFailure(error){

        })
        
        
    }

    const processExcelSheet = async () => {
        try {
            setErrorMessage('')
            const file = inputFiles[0]
            setRawFile(file)
            postDataSelection()
        } catch (error) {
            setErrorMessage(error?.message)
            setLoadingMessage("")
            setShowLoadingIcon(false)
        }
    }

    const validateFileSize = (fileDesc) => {
        if(fileDesc.size/1000000 >= 200){
            throw ({error: 'FILE MAX LIMIT', message:"Max file limit is 200MB"})
        }else{
            setErrorMessage()
        }
    }

    const changeHandler = async (inputFiles) => {
        try {
            setLoadingMessage("loading file, please wait...")
            setDisableProceedButton(true)
            setShowLoadingIcon(true);
            setErrorMessage('')
            setIsFileSelected(true)
            setShowFileNotSeleted(false)
            setSelectedSheet('')
            setWbType(null)
            setSupportingDocs([])
            stageSessionSelectedSheet.current = ''
            const file = inputFiles[0]
            validateFileSize(file)
            validateInputFileFormat(file)

            setUploadedFileName(file.name)

            let stageSession = uuidv4()
            stageSessionId.current = stageSession
            let fileExt = file.name.split('.').pop()
            stageSessionFileExt.current = fileExt
            let folder = `stage/${stageSession}/`
            let fileName = folder + stageSession + `.${fileExt}`
            let level = "public"
            let prefix = 'frisco/'
            configureFriscoS3()
            setLoadingMessage("uploading the file, please wait...")
            let putResp = await Storage.vault.put(fileName, file, {
                tagging: `userId=${userId}&app_id:${id}&process_id:${pid}&mappingId=${mappingId}&type=${file.type}`,
                level: level,
                customPrefix: { public: prefix },
                acl: 'bucket-owner-full-control'
            })
            resetValidationErrorLogs()
            if(putResp && putResp.hasOwnProperty('key')){
            if (stageSessionFileExt.current == 'csv') {
                postDataSelection()
            }else{
                setLoadingMessage("fetching matching sheets from the excel, please wait...")
                onMatchResults(stageSessionId.current, function onResult(result){
                    let response = JSON.parse(result.value.data.onMatchResults.payload)
                    let sheetsWithValidSchema = response?.validSheets?.map(colName => ({
                        label: colName,
                        value: colName
                    }))
                    setInputSheets(sheetsWithValidSchema)
                    setShowLoadingIcon(false);
                    if (sheetsWithValidSchema.length <= 0) {
                        setErrorMessage("There are no sheets that match the required schema. Please upload valid file.")
                    }else if(sheetsWithValidSchema.length == 1){
                        setSelectedSheet(sheetsWithValidSchema[0].value)
                    }
                }, function onFailure(error){
                    console.log(error)
                })

                await new Promise(resolve => setTimeout(resolve, 1000));

                // call excel API and get sheets
                getFRISCOPreValidationMatchingExcelSheetResults(stageSessionId.current, stageSessionFileExt.current, pid, function onSuccess(response){
                }, function onFailure(error){
                    setErrorMessage("Sheet validation failed", error?.message)
                    setShowLoadingIcon(false);
                })



            }
            }

        } catch (error) {
            setErrorMessage(error?.message)
            setLoadingMessage("")
            setShowLoadingIcon(false)
        }

    }

    const onSubmitClick = async () => {
        if (isFileSelected) {
            setSubmitDisabled(true)
            let quarter = null
            if (processData?.next_step == 'CORP_ADMIN_APPROVAL') {
                quarter = getQuarterForUpload()
            }
            let s3_config = getFriscoS3Config()
            let bucket_name = s3_config.S3.aws_user_files_s3_bucket
            setLoadingMessage("submitting..")
            setShowLoadingIcon(true)
            if(supportingDocs?.length > 0){
                setLoadingMessage("uploading supporting documents..")
                for(let i=0;i<supportingDocs?.length;i++){
                    const file = supportingDocs[i]
                    let fileExt = file.name.split('.').pop()
                    let folder = `stage/${stageSessionId.current}/supportingDocuments/`
                    let fileName = folder + file.name 
                    let level = "public"
                    let prefix = 'frisco/'
                    // upload to S3 path
                    let putResp = await Storage.vault.put(fileName, file, {
                        tagging: `userId=${userId}&app_id:${id}&process_id:${pid}&mappingId=${mappingId}&type=${file.type}`,
                        level: level,
                        customPrefix: { public: prefix },
                        acl: 'bucket-owner-full-control'
                    })
                }
            }
            setLoadingMessage("submitting the request..")
            friscoSubmit(stageSessionId.current, id, pid, FRISCO_STATUS.UPLOADED, bucket_name, uploadedFileName, stageSessionFileExt.current,
                Date.now(), userId, uploadedFileName, stageSessionSelectedSheet.current, quarter, taskId, null, null, wbType,
                function onSuccess(response) {
                    setFileReference(JSON.parse(response.data.friscoSubmit)?.fileId)
                    setShowSuccessModal(true)
                    setShowLoadingIcon(false)
                    setSubmitDisabled(false)
                    setWbType(null)
                    setSupportingDocs([])
                }, function onFailure(response) {
                    setMainErrorMessage(`File upload failed, fileId : ${sessionId}`)
                    setShowLoadingIcon(false)
                    setSubmitDisabled(false)
                })
        }

    }

    const prevButtonClick = () => {
        setActiveTabId('first')
        setShowSuccessModal(false)
        setDisableProceedButton(false)
    }

    const cancelButtonClick = () => {
        setActiveTabId('first')
        setShowSuccessModal(false)
        setIsFileSelected(false)
        setInputFiles([])
        setSelectedSheet('')
        setInputSheets([])
        setMainErrorMessage('')
        stageSessionFileExt.current = null
        stageSessionId.current = null
        setDisableProceedButton(false)
    }

    const next1Click = () => {

        setActiveTabId('third')

    }

    const ModalOkClick = () => {
        history.push(`/App/${id}/process/${pid}/history`)
    }


    const getSupportedFormats = (input = processData, defValue = '.csv') => {
        let returnValue = new Set()
        returnValue.add(defValue)
        if (input && input.hasOwnProperty(PROCESS_KEYS.file_types)) {
            input[PROCESS_KEYS.file_types].forEach((item) => {
                if (item == 'CSV') {
                    returnValue.add('.csv')
                } else if (item == 'Excel') {
                    returnValue.add('.xls')
                    returnValue.add('.xlsx')
                    // returnValue.add('.xlsb')
                    returnValue.add('.xlsm')
                    // returnValue.add('.xltx')
                    // returnValue.add('.xltm')
                } else {
                    returnValue.add(defValue)
                }
            })
        }
        return Array.from(returnValue).join(",")
    }
    const onFollowHandler = (ev) => {

        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    const content = (
        <div>
            <div>
                <SpaceBetween size='l'>
                    <Flashbar
                        items={notificationBarItems}
                        i18nStrings={{
                            ariaLabel: "Notifications",
                            notificationBarAriaLabel:
                                "View all notifications",
                            notificationBarText: "Notifications",
                            errorIconAriaLabel: "Error",
                            warningIconAriaLabel: "Warning",
                            successIconAriaLabel: "Success",
                            infoIconAriaLabel: "Info",
                            inProgressIconAriaLabel: "In progress"
                        }}
                        stackItems
                    />
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant='normal' onClick={() => { history.push(`/App/${id}/process/${pid}/history`) }}>
                                            View Upload History
                                        </Button>
                                    </SpaceBetween>
                                }
                                description=""
                            > Upload file history
                            </Header>
                        }
                    >
                        {
                            processData?.hasOwnProperty(PROCESS_KEYS.team) && processData?.hasOwnProperty(PROCESS_KEYS.business)
                                && processData?.hasOwnProperty(PROCESS_KEYS.dataset_name) ?
                                <SpaceBetween size='s' direction='horizontal'>
                                    <Box variant="h4"> Load table : </Box>
                                    {
                                        processData[PROCESS_KEYS.redshift_table_name] ?
                                            <Box>{processData[PROCESS_KEYS.redshift_table_name]}</Box> :
                                            <Box variant="p"> {processData[PROCESS_KEYS.team]}_{processData[PROCESS_KEYS.business]}_{processData[PROCESS_KEYS.dataset_name]}</Box>
                                    }                            </SpaceBetween> : <></>
                        }
                    </Container>


                    {showLoadingIcon ? (
                        <Box>
                            <Spinner size="normal"></Spinner>
                            {loadingMessage}
                        </Box>) : ''}

                    <Tabs
                        activeTabId={activeTabId}
                        variant='container'
                        tabs={[
                            {
                                activeTabId: { activeTabId },
                                label: "Step 1 : Upload File",
                                id: "first",
                                content: (
                                    <div>
                                        <br></br>
                                        <ColumnLayout borders="vertical" columns={2} >
                                            <SpaceBetween direction='vertical' size='m'>
                                                {showFileNotSeleted ? <Alert type='error'>File Not Selected</Alert> : ''}
                                                {
                                                     showUploadBtn  ? <FileUpload
                                                        onChange={({ detail }) => { setInputFiles(detail.value);
                                                             setSelectedSheet(''); setInputSheets([]); 
                                                             changeHandler(detail.value) 
                                                            }}
                                                        value={inputFiles}
                                                        i18nStrings={{
                                                            uploadButtonText: e =>
                                                                e ? "Choose files" : "Choose file",
                                                            dropzoneText: e =>
                                                                e
                                                                    ? "Drop files to generate"
                                                                    : "Drop file to generate",
                                                            removeFileAriaLabel: e =>
                                                                `Remove file ${e + 1}`,
                                                            limitShowFewer: "Show fewer files",
                                                            limitShowMore: "Show more files",
                                                            errorIconAriaLabel: "Error"
                                                        }}
                                                        showFileLastModified
                                                        showFileSize
                                                        showFileThumbnail
                                                        tokenLimit={1}
                                                        accept={supportedFormats}
                                                        constraintText="upload input file"
                                                    /> : <Box></Box>
                                                }
                                                {inputSheets && inputSheets.length > 0 ?
                                                    <SpaceBetween size='s'>
                                                        <Box variant='h5'> Please choose a sheet from the uploaded file for processing, and click proceed</Box>
                                                        <Tiles
                                                            onChange={({ detail }) => {
                                                                setSelectedSheet(detail.value)
                                                            }}
                                                            value={selectedSheet}
                                                            items={inputSheets}
                                                        />
                                                        <Box float="right">
                                                            <Button variant="primary" disabled={disableProceedButton} onClick={processExcelSheet} >Proceed</Button>
                                                        </Box>
                                                    </SpaceBetween> : <></>}

                                                {
                                                    comments ? <Alert
                                                        statusIconAriaLabel="Info"
                                                        header="Dates"
                                                    >
                                                        {comments?.split("\n")?.map((t, key) => {
                                                            return <p key={key}>{t}</p>;
                                                        })}


                                                    </Alert> : ''
                                                }

                                                {errorMessage? <Alert statusIconAriaLabel="Error"
                                                type="error"
                                                header="Upload restricted"> 
                                                {errorMessage} </Alert>:''}

                                                { (missingFields?.size >= 1 || colCountMoreThanExpected || colsNotInRequiredOrder) ? (
                                                    <>
                                                    <Box>
                                                    <h4>Validation checks:</h4>
                                                    <ul>
                                                        <li>
                                                        All required fields available? {missingFields.size >= 1 ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                        <li>
                                                        Provided column(s) count is less than or equal to expected column count? {colCountMoreThanExpected ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                        <li>
                                                        Column order as expected? (Please refer required column order on the right) {colsNotInRequiredOrder ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                    </ul>
                                                    </Box>
                                                {
                                                    (missingFields.size >= 1 && 
                                                    
                                                    <Alert
                                                        statusIconAriaLabel="Error"
                                                        type="error"
                                                        header="Required fields are missing"
                                                    >
                                                    <SpaceBetween>
                                                     <Box><h4>Missing columns:</h4>
                                                     <ul>
                                                        {Array.from(missingFields).map((field) => (
                                                            <li key={field}>{field}</li>
                                                        ))}
                                                     </ul>
                                                     </Box>  
                                                     <Box><h4>Provided columns:</h4>
                                                     <ul>
                                                     {providedCoulumns.map((field) => (
                                                            <li key={field}>{field}</li>
                                                        ))}
                                                     </ul>
                                                     </Box>
                                                     </SpaceBetween>
                                                    </Alert>
                                                    )
                                                }
                                                {
                                                    providedCoulumns.length > 0 ? 
                                                    <SpaceBetween>

                                                    </SpaceBetween>
                                                    : <></>
                                                }
                                                

                                                </>
                                                ):<></>

                                                }
                                                
                                            </SpaceBetween>


                                


                                            <Box>
                                                <ExpandableSection header="The file should contain the following columns in below order." 
                                                defaultExpanded>

                                                <ColumnLayout columns={2}>
                                                <SpaceBetween direction='vertical'>
                                                <Box variant="awsui-key-label" > &nbsp;</Box>
                                                <ul>
                                                        {
                                                            Array.from(getMandatoryColumnNames()).map(
                                                                column => <li
                                                                    value={column}
                                                                    key={column}>
                                                                    <span>{column}</span>
                                                                    <span className='missing'> {(missingFields && missingFields.size > 0 && missingFields.has(column)) ? ' - required' : ''} </span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </SpaceBetween>

                                                    {
                                                        providedCoulumns.length > 0?
                                                        <SpaceBetween direction='vertical'>
                                                        <Box variant="awsui-key-label" >Provided columns</Box>
                                                    <ul>
                                                        {
                                                            Array.from(providedCoulumns).map(
                                                                column => <li
                                                                    value={column}
                                                                    key={column}>
                                                                    <span>{column}</span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                    </SpaceBetween>:<></>
                                                    }
                                                    </ColumnLayout>           
                                                    
                                                </ExpandableSection>

                                            </Box>


                                        </ColumnLayout>

                                    </div>

                                )
                            },
                            {
                                label: "Step 2 : Review File",
                                id: "second",
                                content: (
                                    <Box>
                                        <Header
                                            variant="h2"
                                            description="File preview - Read Only"
                                            actions={
                                                <SpaceBetween
                                                    direction="horizontal"
                                                    size="xs"
                                                >
                                                    <Button variant="normal" onClick={prevButtonClick}>Previous</Button>

                                                    <Button variant="primary" onClick={next1Click}>Next</Button>
                                                    <Box></Box>
                                                </SpaceBetween>
                                            }
                                        />
                                        <Table
                                            columnDefinitions={columnsList}
                                            items={previewItems}
                                            stickyHeader={true}
                                            resizableColumns={true}
                                        >

                                        </Table>
                                    </Box>
                                )
                            },
                            {
                                label: "Step 3 : Submit",
                                id: "third",
                                content: (<div>
                                    <Header
                                        variant="h2"
                                        description="File Details"
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                            <Button variant="normal" onClick={cancelButtonClick} disabled={submitDisabled}>Cancel</Button>
                                            <Button variant="primary" onClick={onSubmitClick} disabled={submitDisabled || (shouldShowWbSelection && !wbType) }>Submit</Button>
                                            </SpaceBetween>
                                        }
                                    />

                                    <SpaceBetween direction='vertical' size='l'>
                                    < Box>
                                        Filename: {uploadedFileName}
                                        {mainErrorMessage && (
                                            <div className="custom-new-upload__error-message"> {mainErrorMessage} </div>
                                        )}
                                    </Box>
                                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                                    {
                                            shouldShowWbSelection ?
                                                <RadioGroup
                                                    onChange={({ detail }) => setWbType(detail.value)}
                                                    value={wbType}
                                                    items={[
                                                        { value: "PreWB", label: "Pre WB" },
                                                        { value: "PostWB", label: "Post WB" },
                                                        { value: "NoPostWB", label: "No Post WB" }
                                                    ]}
                                                /> : <></>
                                        }
                                        {
                                            shouldShowPdfUpload ?
                                                <SpaceBetween size="s">
                                                    <FileInput
                                                        onChange={({ detail }) => setSupportingDocs(detail.value)}
                                                        value={supportingDocs}
                                                        multiple
                                                    >
                                                        Upload supporting documents
                                                    </FileInput>
                                                    <Table
                                                        columnDefinitions={[
                                                            {
                                                                id: "name",
                                                                header: "File name",
                                                                cell: file => file.name
                                                            },
                                                            {
                                                                id: "size",
                                                                header: "File size",
                                                                cell: file => file.size / 1000 + "KB"
                                                            }
                                                        ]}
                                                        items={supportingDocs}
                                                        empty="No files"
                                                    />
                                                </SpaceBetween> : <></>
                                        }

                                        

                                    </Grid>
                                    </SpaceBetween>
                                </div>),
                            }
                        ]}
                    />
                </SpaceBetween>
                <Modal
                    visible={showSuccessModal}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={ModalOkClick}>Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="File Upload Status"
                >
                    <Box>
                        <SpaceBetween size='m' direction='vertical'>
                            <p>
                                <Icon variant="success" size='medium' name='status-positive'></Icon>
                                &nbsp; &nbsp;
                                Your file has been uploaded for processing. <br></br>
                                File reference : {fileReference}
                            </p>

                        </SpaceBetween>

                    </Box>

                    <br></br>

                </Modal>
            </div>
        </div>
    )
    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/process/${pid}/upload`} id={id} pid={pid} process={processData} back={backUrl} />}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    );
}
