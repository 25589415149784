import React, {useState, useEffect} from 'react';
import SideNav from './SideNav';
import appSettings from './app_settings';

import {
  AppLayout,  Button,  ColumnLayout,  Tabs,  Icon,  Textarea,
  Container,  Header,  Box,  Grid,  SpaceBetween, Link, ExpandableSection
} from '@amzn/awsui-components-react';

import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'


import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import favLogo from '../../images/star-regular-brown-for-home.svg'
import favLogoSolid from '../../images/star-solid-brown-for-home.svg'
import { checkIsFavorite, favorite } from '../../utilities/Favorites'
import Feedback from "src/generic-components/UserFeedback/Feedback";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  const history = useHistory()
  const [NavOpen, setNavOpen] = useState(true);

  const [, setShowLoadingIcon] = useState(false);
  
  const [, setInputsContent] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const [favDisabled, setFavDisabled] = useState(false);
  const userId = useSelector((globalState) => globalState.auth.userId);

  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }

  const getStarted = () =>{
    history.push('/BoffinCompute')
  }
  const viewComputes = () =>{
   
  }
  
  const Content =  (
  
    <div>
      <Box padding="s" margin={{ bottom: 'l' }}>
          <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Box padding={{ vertical: 'xxxl' }}>
              <Grid
                gridDefinition={[
                  { offset: { l: '2', xxs: '1' }, colspan: { l: '5', xxs: '10' } },
                  { colspan: { xxs: 10, s: 6, l: 6, xl: 6 }, offset: { xxs: 1, l: 2 } },
                  { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
                ]}
              >
                <Box fontWeight="heavy" padding={{ top: 'xs' }}>
                  <span className="custom-home__category">Project Boffin</span>
                  <img className="favorite_icon" src={isFavorite? favLogoSolid : favLogo} onClick={() => markFavorite(appSettings.appId)} disabled={favDisabled} ></img>
                </Box>
  
                <div className="custom-home__header-title">
                  <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                    Fixed Assets Reclass & Depreciation Accruals
                  </Box>
                  <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                    Journal Entry Management Tool
                  </Box>
                  <Box variant="p" fontWeight="light">
                    <span className="custom-home__header-sub-title">
                          
                    The primary goal of Fixed Assets Project Boffin, is to automate the analysis of the 16960 / 16999 
                    balances at a given period-end and to automate the MJE to reclass 
                    the balances to natural accounts.
                            
                            
                    </span>
                  </Box>
                </div>
  
                <Container header={<Box variant="h2" padding="n">
                      Launch Compute Wizzard
                    </Box>}>
                  <SpaceBetween size="xl">
                    
                    <Box>
                      Ready to compute new Journal Entry! 
                      <br></br>
                      click on the below button
                      to initate a new compute.

                    </Box>
                    <Button  variant="primary" onClick={()=>{getStarted()}}>
                      Get Started  
                    </Button>
                  </SpaceBetween>
                </Container>
              </Grid>
            </Box>
            <br></br>
          </Grid>
  
          <Box padding={{ top: 'xxxl' }}>
            <Grid
              gridDefinition={[
                { colspan: { xxs: 10, s: 6, l: 8, xl: 6 }, offset: { xxs: 1, l: 2 } },
                { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
              ]}
            >
              <div>
              <Tabs
                variant='container'
                tabs={[
                  {
                    label: "Inputs",
                    id: "1",
                    content: (
                      <Box variant="div" fontSize="body-m">
                        <ExpandableSection headerText="Automated Inputs">
                          <ol>
                              <li>Queue - Mass Additions</li>
                              <li>OPO</li>
                              <li>IOH</li>
                              <li>Cash In Transit</li>
                              <li>Payee Central</li>
                              <li>Unaccounted Invoices</li>
                              <li>Buysell CF True Up Cost Accrual</li>
                              <li>Buysell CF Purchased Asset Accrual</li>
                              <li>Tririga</li>
                              <li>Catergory - Account Mapping</li>
                              <li>Category Mapping</li>
                              <li>Supplier Categorization Model</li>
                          </ol>
                          </ExpandableSection>
                          <ExpandableSection headerText="Manual Inputs">
                          <ol>
                              <li>CIP Tracker</li>
                              <li>Additional-DataSet</li>
                              <li>Process - Company Mapping</li>
                              <li>Queue - Manual</li>
                          </ol>
                          </ExpandableSection>

                         
                          
                      </Box>
                    )
                  },
                  {
                    label: "Processing",
                    id: "2",
                    content: (
                      <Box>
                        <ol>
                          <li>Compute Reclass  / Depreciation Accruals based on rules</li>
                          <li>Override JE per user provided information</li>
                        </ol>
                         
                      </Box>
                    )
                  },
                  {
                    label: "Outputs",
                    id: "3",
                    content: (
                      <Box >
                         <ol>
                          <li>Review & Post JE</li>
                          <li>View JE posting status</li>
                        </ol>
                          
                      </Box>
                    )
                  },
                  {
                    label: "Accounting POC",
                    id: "4",
                    content: (
                      <Box>
                        <ol>
                          <li>Obeng, Elliott (ellobe@)</li>
                          <li>Kehoe, Rowan Alexander (akehoe@amazon.de)</li>
                        </ol>
                        
                      </Box>
                      
                      
                    )
                  },
                  {
                    label: "Access / Issues Request",
                    id: "5",
                    content: (
                      <Box>
                        <ul className="custom-list-separator">
                          <li>
                            <span>
                              <Icon variant='subtle' name='user-profile'></Icon> &nbsp;
                              <Link href="https://t.corp.amazon.com/create/templates/fdf0e4a0-ea05-49b5-8e90-b7a1c6bd5e3c" external>
                                Access Request
                              </Link>
                            </span>
                          </li>
                          
                          <li>
                            <span>
                              <Icon variant='subtle' name='envelope'></Icon> &nbsp;
                              <Link href="https://t.corp.amazon.com/create/templates/9c41d7b9-b693-433e-950a-ec29fcfce9f5" external>
                                Oncall Support
                              </Link>
                            </span>
                          </li>

                          <li>
                          <Box>
                            
                            Additional comments to On-call Engineer
                            <p></p>
                            <br></br>
                            How to grant user access?
                            <br></br>
                            
                            1. Go to this <a href='https://permissions.amazon.com/a/team/amzn1.abacus.team.lfib2cdk3haxq5kayvoa'>link</a>
                            <br></br>
                            2. Reach out to Primary or Secondary owner on chime, and ask them to add the user under Additional Members Tab.
                            
                          </Box>
                          
                          
                          
                          </li>
                        </ul>
                      </Box>
                    )
                  },
                  {
                    label: "How it works",
                    id: "6",
                    content: (
                      <Box>
                        <Box>
                          Process FLow Diagram
                        </Box>
                        <br></br>
                        <ul className="custom-list-separator">
                          <li>
                            <span>
                              <Icon variant='subtle' name='file-open'></Icon> &nbsp;
                              <Link href="" external  target="_blank">
                                PDD
                              </Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon variant='subtle' name='file-open'></Icon> &nbsp;
                              <Link href="https://quip-amazon.com/rxaxAjdqZrZ7/Boffin-TDD" external  target="_blank">
                                TDD
                              </Link>
                            </span>
                          </li>
                        
                        </ul>
                    </Box>
                    )
                  },
                ]}
              />
                
              </div>
            
              <div className="custom-home__sidebar">
                <SpaceBetween size="xxl">
                  
                  
                  
                </SpaceBetween>
              </div>
            </Grid>
          </Box>
      </Box> 
      <br/><br/><br/><br/><br/><br/>
      <SpaceBetween size="l" > 
          <Feedback appId={appSettings.appId} pageId={appSettings.appId} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
        </SpaceBetween>

    </div>
  );

  const loadData = async () =>{
    setShowLoadingIcon(true)
    var mQry = `query MyQuery {
      getUI4BI_App_Settings(appid: "BI108") {
        appName
        appDesc
        url
        adminUsers
        developedBy
        bindleLockId
        ticketLink
        processOwner
        showInUI4BI
      }
    }
    
`
  
    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getUI4BI_App_Settings
    // console.log(qResultJSON)
    setInputsContent(qResultJSON.ticketLink)
   
    setShowLoadingIcon(false)    
       
}

  useEffect(() => {
    
    loadData()
    checkFavorite(appSettings.appId)
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  
  
  return (
    <AppLayout
      disableContentPaddings={true}
      navigation={<SideNav activeHref="/BoffinHome" />} 
      content={Content}
      contentType="default"
      toolsHide={true} 
      headerSelector="#TopNav"
      navigationOpen={NavOpen}
      onNavigationChange={()=> {setNavOpen(!NavOpen)}}
      headerVariant="high-contrast"

    />
  );
};


