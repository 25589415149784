import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Box, ButtonDropdown, Container, Grid, SpaceBetween, Spinner, Tabs } from "@amzn/awsui-components-react";
import { v4 as uuidv4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Storage } from 'aws-amplify';
import * as d3 from "d3";
import { configureFlexicoS3, configureGenericS3 } from 'src/context/AuthContextUtility';
import { getQSDashboardEmbedLinksSync } from 'src/Ateam-BI/Templates/Utils/AppActivityUtils';
const EmbedResults = (props) => {
  const [activeReportTabId, setActiveReportTabId] = useState()
  const [reports, setReports] = useState([])
  const [reportsMap, setReportsMap] = useState({})

  const [qsEmbedUrl, setQSEmbedUrl] = useState()
  const [embedUrlLoadingMessage, setEmbedUrlLoadingMessage] = useState(null)
  //qs
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState()
  const [columnsList, setColumnsList] = useState([])
  const [gridInfo, setGridInfo] = useState({ rowCount: 0, totalRows: 0 });
  const [aggregationValues, setAggregationValues] = useState(null)

  const defaultProjectColDef = {
    resizable: true,
    sortable: true,
    autoHeaderHeight: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerClass: 'header-center',
    editable: true,
    // singleClickEdit: true,
    enableCellChangeFlash: true,
    enableRowGroup: false,
    enableCellChangeFlash: true,
    suppressHeaderMenuButton: true,
    enableHeaderHighlight: true,
    cellSelection: 'single', // or 'multiple'
    suppressCellSelection: false,
    suppressMovable: true,
    cellClassRules: {
      'edited-row': (params) => { return params?.data?.isEdited || params?.data?.isNew },
      'new-row': (params) => { return params?.data?.isNew },
    }
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }
    ],
    // Hide sidebar by default
    defaultToolPanel: '', // empty string means closed
    // or use 'false' to hide completely
    // defaultToolPanel: false,
  };

  useEffect(() => {
    let reportList = []
    let reportsMap = {}
    if (props.s3Reports) {
      if (checkType(props.s3Reports) == 'string') {
        let arr = props.s3Reports.split(",")
        for (let i = 0; i < arr?.length; i++) {
          let id = uuidv4()
          reportList.push({
            'id': id,
            'label': 'Report',
            'content':
              <div>
              </div>
          })
          reportsMap[id] = { 'type': 'S3', 'uri': arr[i], 'bucket': props.s3Bucket }
        }

      }
      if (checkType(props.s3Reports) == 'array') {
        for (let i = 0; i < props.s3Reports?.length; i++) {
          let id = uuidv4()
          reportList.push({
            'id': id,
            'label': props.s3Reports[i].label ? props.s3Reports[i].label : 'Report',
            'content':
              <div>
              </div>
          })
          reportsMap[id] = { 'type': 'S3', 'uri': props.s3Reports[i].uri, 'bucket': props.s3Reports[i].bucket }
        }
      }
    }
    if (props.outputResults) {
      for (let i = 0; i < props.outputResults?.length; i++) {
        let data = JSON.parse(props.outputResults[i])
        let id = uuidv4()
        reportList.push({
          'id': id,
          'label': data.label ? data.label : 'Report',
          'content':
            <div>
            </div>
        })
        reportsMap[id] = data
      }
    }
    setReports(reportList)
    setReportsMap({ ...reportsMap })
    if (reportList?.length > 0) {
      setActiveReportTabId(reportList[0].id)
    }
  }, [props])

  useEffect(() => {
    if (activeReportTabId) {
      showReport(activeReportTabId)
    }
  }, [activeReportTabId])

  const setQSEmbeddingDetails = async() => {
      if(activeReportTabId == undefined){
        return
      }
      setEmbedUrlLoadingMessage("Loading report..")
      
      let dashboardId = reportsMap?.[activeReportTabId]['dashboardId']
        let region = reportsMap?.[activeReportTabId]['region'] || 'us-east-1'
        let aws_account = reportsMap?.[activeReportTabId]['awsAccount'] || 'FAST'
        if( dashboardId.includes("http")){
          // its a url, fetch region and id
          let urlSplit =  dashboardId?.replace(/\/$/, '').split("/")
          dashboardId = urlSplit[urlSplit?.length-1]
          if(urlSplit[2].includes("quicksight")){
            region = urlSplit[2].split(".")[0]
          }
        }
        setQSEmbedUrl(null)
      let response = await getQSDashboardEmbedLinksSync(aws_account,region,dashboardId)
      setQSEmbedUrl(response.url)
      setEmbedUrlLoadingMessage(null)
    }

    const setS3EmbeddingDetails = async() => {
      if(activeReportTabId == undefined){
        return
      }
      setEmbedUrlLoadingMessage("Loading report..")
      configureFlexicoS3()
      let filefn = reportsMap[activeReportTabId]?.uri.replace('flexico/', '')
      let prefix = 'flexico/'
      let fileURL = await Storage.get(filefn, { level: 'public', customPrefix: { public: prefix }, expires: 60 });
      let csvdata = await d3.csv(fileURL).then((result) => {
        setDsInfo(result);
        if (result?.columns) {
          const definitions = []
          for (let i = 0; i < result?.columns?.length; i++) {
            let colName = result?.columns[i]
            let def = {
              field: colName, headerName: colName, resizable: true, sortable: true, enableRowGroup: true, editable: false, cellSelection: 'enabled'
            }
            if (i == 0) {
              def['checkboxSelection'] = true
              def['headerCheckboxSelection'] = true
            }
            definitions.push(def)
          }
          setColumnsList(definitions)
        }
        setEmbedUrlLoadingMessage(null)
        configureGenericS3()
      }).catch((response) => {
        setEmbedUrlLoadingMessage('Loading failed')
        configureGenericS3()
      });
    }

  const showReport = async (activeReportTabId) => {
    setDsInfo(null)
    setColumnsList(null)
    setAggregationValues(null)
    resetGridView()
    if (reportsMap[activeReportTabId]?.type == 'S3') {
      setS3EmbeddingDetails()
    }else if (reportsMap[activeReportTabId]?.type == 'QUICKSIGHT') {
      setQSEmbeddingDetails()
    }
  }

  const resetGridView = () => {
    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;

      // Clear Filters
      gridApi?.setFilterModel(null);

      // Clear Sorting
      columnApi?.applyColumnState({
        defaultState: { sort: null },
      });

      // Reset Columns
      columnApi?.resetColumnState();

      // Reset Pinned Columns
      columnApi?.setPivotMode(false);

      // // Resize to fit
      // gridApi?.sizeColumnsToFit();

      // Redraw the grid
      gridApi?.redrawRows();
    }
  };

  const checkType = (variable) => {
    if (typeof variable === 'string') return 'string';
    if (Array.isArray(variable)) return 'array';
    return 'other';
  }

  const exportAnnotated = (args = {}) => {
    let fileName = 'output.csv'
    let report = reportsMap?.[activeReportTabId]
    if(report.type == 'S3'){
        let arr = report.uri.split('/')
        fileName = arr[arr.length - 1]
    }
    let csv = gridRef.current?.api.exportDataAsCsv({
      'fileName': fileName,
      'exportedRows': args.filtered ? 'filteredAndSorted' : 'all',
      'onlySelected': args.selectedOnly,
      processCellCallback: (params) => {
        // Access raw data directly from node.data
        const rawValue = params.node.data[params.column.colDef.field];
        return rawValue;
      }
    });
    // gridRef.current?.api.exportDataAsExcel()
  }

  const getContextMenuItems = (params) => {
    return [
      'copy',
      'copyWithHeaders',
      // 'paste',
      'separator',
      'export'
    ];
  }

  const onFilterChanged = () => {
    updateGridInfo();
  };

  const onViewportChanged = () => {
    updateGridInfo();
  };

  const updateGridInfo = () => {
    if (gridRef?.current?.api) {
      const api = gridRef.current.api;
      setGridInfo({
        rowCount: api?.getDisplayedRowCount(),
        totalRows: dsInfo?.length,
      });
    }
  };

  const onGridReady = useCallback((params) => {
    // gridRef?.current?.api.sizeColumnsToFit();
    const allColumnIds = [];
    gridRef?.current?.api?.getColumns()?.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.api?.autoSizeColumns(allColumnIds);
    updateGridInfo()
  }, []);

  const s3ResultGrid = (
    <SpaceBetween direction="vertical">
      <Grid
        gridDefinition={[
          { colspan: { default: 6, xxs: 6 } },
          { colspan: { default: 6, xxs: 6 } }
        ]}
      >
        <Box>
        {
      embedUrlLoadingMessage ?
        <Box><br></br> <Spinner></Spinner>  {embedUrlLoadingMessage} <br></br></Box> : <></>
    }
        </Box>
        <Box float="right" padding="m">
          <SpaceBetween direction="horizontal" size="xl">
            <ButtonDropdown
              items={[
                {
                  text: "Export to excel",
                  items: [
                    { text: "Current view", id: "current_view", disabled: false },
                    { text: "Selected rows", id: "selected_rows", disabled: false },
                    { text: "Original", id: "original", disabled: false }
                  ]
                }
              ]}
              onItemClick={(event) => {
                if (event.detail.id == 'current_view') {
                  exportAnnotated({ 'filtered': true })
                } else if (event.detail.id == 'selected_rows') {
                  exportAnnotated({ 'selectedOnly': true })
                }
                else if (event.detail.id == 'original') {
                  exportAnnotated()
                }
              }}
            >
              Actions
            </ButtonDropdown>
          </SpaceBetween>
        </Box>
      </Grid>
      <div style={{
        width: '100%',
        height: 'calc(100vh - 200px)',
      }}>
        <div
          className="ag-theme-alpine"
          style={{
            width: '100%',
            height: '100%',
            minHeight: '500px'
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={dsInfo}
            defaultColDef={defaultProjectColDef}
            columnDefs={columnsList}
            rowSelection='multiple'
            suppressRowClickSelection={true}
            rowMultiSelectWithClick={true}
            rowNumbers={true}
            animateRows={true}
            rowHeight={30}
            enableCellTextSelection={false}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={onGridReady}
            rowGroupPanelShow="always"
            sideBar={sideBar}
            // groupTotalRow="bottom"
            groupAggFiltering={false}
            pivotMode={false}
            suppressAggFuncInHeader={false}
            enableCellSelection={true}
            enableRangeSelection={true}
            getContextMenuItems={getContextMenuItems}
            enableContextMenu={true}
            onFilterChanged={onFilterChanged}
            onViewportChanged={onViewportChanged}
          />
          {
            aggregationValues && aggregationValues?.count > 1 ?
              <Box float="right">
                <SpaceBetween direction="horizontal" size="s">
                  <Box><span className="agg-text bold-text">Average:</span> <span className="agg-text">{aggregationValues.average}</span></Box>
                  <Box><span className="agg-text bold-text">Count:</span> <span className="agg-text">{aggregationValues.count}</span></Box>
                  <Box><span className="agg-text bold-text">Min:</span> <span className="agg-text">{aggregationValues.min}</span></Box>
                  <Box><span className="agg-text bold-text">Max:</span> <span className="agg-text">{aggregationValues.max}</span></Box>
                  <Box><span className="agg-text bold-text">Sum:</span> <span className="agg-text">{aggregationValues.sum}</span></Box>
                </SpaceBetween>
              </Box>
              : ''
          }
          {
            gridInfo ? <Box float="left">
              <SpaceBetween direction="horizontal" size="s">
                <Box><span className="agg-text bold-text">Filtered rows:</span> <span className="agg-text">{gridInfo.rowCount}</span></Box>
                <Box><span className="agg-text bold-text">Total rows:</span> <span className="agg-text">{gridInfo.totalRows}</span></Box>
              </SpaceBetween>
            </Box> : ''
          }
        </div>
      </div>
    </SpaceBetween>
  )
  const qsResultGrid = (
    <Box>
      {
      embedUrlLoadingMessage ?
        <Box><br></br> <Spinner></Spinner>  {embedUrlLoadingMessage} <br></br></Box> : <></>
    }
      {
        qsEmbedUrl ?
          <div>
            <iframe
              className="qs_dash"
              src={qsEmbedUrl}>
            </iframe>
          </div> : <></>
      }
    </Box>
  )

  return (<SpaceBetween direction="vertical">
    <Tabs
      onChange={({ detail }) =>
        setActiveReportTabId(detail.activeTabId)
      }
      activeTabId={activeReportTabId}
      tabs={reports}
      disableContentPaddings={true}
    />
    
    {
      reportsMap[activeReportTabId]?.type == "S3" ? <Box> {s3ResultGrid}</Box> : ''
    }
    {
      reportsMap[activeReportTabId]?.type == "QUICKSIGHT" ? <Box>{qsResultGrid}</Box> : ''
    }

  </SpaceBetween>)

}

export default EmbedResults;
