import React, {useState, useEffect} from 'react';
import SideNav from './SideNav';
import appSettings from './app_settings';

import {
  AppLayout,  Button,  ColumnLayout,  Tabs,  Icon,  Textarea,
  Container,  Header,  Box,  Grid,  SpaceBetween, Link, ExpandableSection
} from '@amzn/awsui-components-react';

import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'


import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES,TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';


// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  const history = useHistory()
  
  const [renderJson, setRenderJson] = useState({})

  const sideNavitems = [
    {
        "type": "link",
        "text": "Introduction",
        "href": "/TEDHome"
    },

    {
        "type": "section",
        "text": "ETB Triggers",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": (<div>Manual ETB trigger</div>),
                "href": "/TriggerEventLogs"
            },
            {
                "type": "link",
                "text": (<div>History</div>),
                "href": "/TriggerHistory"
            }
        ]

    },

    {
        "type": "section",
        "text": "Trigger Config",
        "defaultExpanded": true,
        "items": [

            {
                "type": "link",
                "text": "Region Trigger Mapping",
                "href": "/RegionTriggerMapping"
            },
            {
                "type": "link",
                "text": "Entity Region Mapping",
                "href": "/EntityRegionMapping"
            },
            {
                "type": "link",
                "text": "Email Subscription",
                "href": "/EmailSubscription"
            },
        ]
    },




    { "type": "divider" },

    
    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },


    ];

  useEffect(() => {
    loadRenderJson()
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const loadRenderJson = async() => {
    var mQry = `
    query MyQuery {
      getAppsV2(ids: ["${appSettings.appId}"]) {
        ${TEMPLATE_APP.APP_DESCRIPTION}
          ${TEMPLATE_APP.APP_NAME}
          ${TEMPLATE_APP.APP_ID}
          ${TEMPLATE_APP.TEMPLATE_ID}
          ${TEMPLATE_APP.URL}
          ${TEMPLATE_APP.PROCESS_OWNER}
          ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
          ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
          ${TEMPLATE_APP.TDD}
          ${TEMPLATE_APP.STATUS}
          ${TEMPLATE_APP.REGION}
          ${TEMPLATE_APP.CADENCE}
          ${TEMPLATE_APP.PROCESS_MJE_COUNT}
          ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
          ${TEMPLATE_APP.PDD}
          ${TEMPLATE_APP.LEDGER_INFO}
          ${TEMPLATE_APP.LAUNCH_MCM}
          ${TEMPLATE_APP.LAUNCH_QQ_YY}
          ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
          ${TEMPLATE_APP.AUTOMATION_TOOLS}
          ${TEMPLATE_APP.ARD}
          ${TEMPLATE_APP.DATASOURCE}
          ${TEMPLATE_APP.ORGANIZATION}
          ${TEMPLATE_APP.VP}
          ${TEMPLATE_APP.CREATE_TIME_STAMP}
          ${TEMPLATE_APP.CREATED_BY}
          ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
          ${TEMPLATE_APP.LAST_UPDATED_BY}
          ${TEMPLATE_APP.SIM_TICKET_URL}
          ${TEMPLATE_APP.SIM_TICKET_LABEL}
          ${TEMPLATE_APP.USE_CASE}
          ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
          }
          ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
          }
          ${TEMPLATE_APP.PERMISSION}{
            ${TEMPLATE_APP_PERMISSION.LINK}
            ${TEMPLATE_APP_PERMISSION.NAME}
            ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
          }
          ${TEMPLATE_APP.DEVELOPER_TEAM}{
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
          ${TEMPLATE_APP.OUTPUT}{
            ${TEMPLATE_APP_OUTPUT.TYPE}
            ${TEMPLATE_APP_OUTPUT.VALUE}
            ${TEMPLATE_APP_OUTPUT.LABEL}
            ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
          }
          ${TEMPLATE_APP.ACCOUNTING_TEAM} {
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
      }
    }   
`;
    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.getAppsV2[0];
      setRenderJson(qResultJSON)
    }, function (error) {
      // no JSON in DB. Looks to be invalid appID. Go home
      history.push('/')
    });
  }
  
  return (
    <TemplateMaple id={appSettings.appId} renderJson={renderJson} customSideNavitems={sideNavitems} redirectOnUpdate="/TEDHome"/>
  );
};


